<template>
    <!-- <v-container fluid fill-height class="view-today-story-container"> -->
    <div>
        <v-row wrap no-gutters align="center" justify="center">
            <v-col
                xs="12"
                sm="10"
                md="6"
                lg="4"
                xl="4"
                class="view-today-story-col"
            >
                <v-list>
                    <v-list-item-title> TODAY'S STORY </v-list-item-title>

                    <v-list-item-subtitle>
                        Health Problems
                    </v-list-item-subtitle>
                    <v-list-item
                        v-for="(problem, index) in getterProblemProfileSorted"
                        :key="problem.SYM_Codebook_vID"
                    >
                        <v-list-item-subtitle>
                            <span class="list-text">
                                {{ problem.label_short }}
                            </span>
                        </v-list-item-subtitle>
                        <v-spacer></v-spacer>
                        <v-chip-group
                            v-model="problemLogARR3[index]['sevLevel']"
                            active-class="deep-purple darken-4 white--text"
                        >
                            <v-chip
                                v-for="sevLevel in problem.severityARR"
                                :key="sevLevel.level"
                                x-small
                                pill
                                :value="sevLevel.level"
                                class="short"
                            >
                                <span>
                                    {{ sevLevel.level }}
                                </span>
                            </v-chip>
                        </v-chip-group>
                    </v-list-item>
                    <v-btn
                        dark
                        color="deep-purple darken-4"
                        @click="postTGproblemLog"
                    >
                        SUBMIT
                    </v-btn>
                    <!--   <router-link :to="{ path: 'view-today-drugs' }">                
                                                                                    <v-btn color="red">
                                                                                        DRUGS
                                                                                    </v-btn>
                                                                                </router-link>  -->
                </v-list>
            </v-col>
        </v-row>
        <v-row wrap no-gutters align="center" justify="center">
            <v-col
                xs="12"
                sm="12"
                md="6"
                lg="4"
                xl="4"
                class="view-today-story-col"
            >
                <div v-if="showDialog_PostProblemLogConfirmation">
                    <v-dialog
                        v-model="showDialog_PostProblemLogConfirmation"
                        transition="dialog-top-transition"
                        max-width="400"
                    >
                        <v-card justify-space-between align-center pa-2 ma-2>
                            <v-toolbar
                                dark
                                color="blue darken-1"
                                justify-end
                                align-center
                                pa-2
                                ma-2
                            >
                                <v-spacer></v-spacer>
                                <span class="toolbar-text"> SUCCESS! </span>

                                <v-spacer></v-spacer>
                                <v-btn
                                    icon
                                    fab
                                    x-small
                                    depressed
                                    dark
                                    color="white"
                                    @click="toggleConfirmationDialog"
                                >
                                    <v-icon> mdi-close-circle </v-icon>
                                </v-btn>
                            </v-toolbar>

                            <v-card-subtitle>
                                <br />
                                <span class="micro-text">
                                    You Just Updated Your Storyboard
                                    <br />
                                    On {{ problemLogConfirmationDateTime }}
                                    <br />
                                    <br />
                                    Well Done!
                                    <br />
                                    See You Tomorrow
                                </span>
                            </v-card-subtitle>
                        </v-card>
                    </v-dialog>
                </div>

                <div v-if="showDialog_PostProblemLogERROR">
                    <v-dialog
                        v-model="showDialog_PostProblemLogERROR"
                        transition="dialog-top-transition"
                        max-width="400"
                    >
                        <v-card justify-space-between align-center pa-2 ma-2>
                            <v-toolbar
                                dark
                                color="blue darken-1"
                                justify-end
                                align-center
                                pa-2
                                ma-2
                            >
                                <v-spacer></v-spacer>
                                <span class="toolbar-text"> SUCCESS! </span>
                                <v-spacer></v-spacer>
                                <v-btn
                                    icon
                                    fab
                                    x-small
                                    depressed
                                    dark
                                    color="white"
                                    @click="
                                        showDialog_PostProblemLogERROR = false
                                    "
                                >
                                    <v-icon> mdi-close-circle </v-icon>
                                </v-btn>
                            </v-toolbar>

                            <v-card-subtitle>
                                <br />
                                <span strong> Sorry :( </span>
                                <br />
                                Unfortunately there was
                                <br />
                                a server error
                                <br />
                                please try again later
                            </v-card-subtitle>
                        </v-card>
                    </v-dialog>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
//  ViewTodayProblems mounts by using Vuex actions to fetch the member's profileItems including SYMPTOMS and also fetches the symptomCodebook
//  The TodayProblems symptom display is created by sorting the member's profileItems array in a computed property
//  The member selects the symptom severity level for today and the values are captured in the computed property problemLogARR3
//  The severity array is controlled by the template line  v-model="problemLogARR3[index]['sevLevel']"


import { mapState, mapGetters, mapActions } from 'vuex';
import { DateTime } from 'luxon';
// eslint-disable-next-line
import * as calendar from '@/store/modules/calendar'
import api from '@/api';
const tone4 = require('../assets/tone4.mp3');
const tone4OBJ = new Audio(tone4);
const probLogDateLuxonObject= DateTime.now();
const probLogDateLocalISO   = probLogDateLuxonObject.toISO();
const probLogDateYEAR       = probLogDateLuxonObject.year;
const probLogDateMONTH      = probLogDateLuxonObject.toFormat('LL');  
const probLogDateDAY        = probLogDateLuxonObject.toFormat('dd');       //DateTime.fromISO('2014-08-06T13:07:04.054').toFormat('ff'); //=> 'Aug 6, 2014, 1:07 PM'
const probLogDateLocalMillis= probLogDateLuxonObject.toMillis();


export default {
    name: 'ViewTodayProblems',

    data() {
        return {
            interval: {},
            spinnerVal: 0,
            problemLogResponse: {},
            showDialog_PostProblemLogConfirmation: false,
            showDialog_PostProblemLogERROR: false,
            problemLogSeverityARR: [],
            problemLogConfirmationDateTime: ""
        }
    },

    computed: {
        ...mapState('home', {
            user: (state) => state.user,
            memberProfile: (state) => state.memberProfile,
            validAccessToken: (state) => state.validAccessToken,
            loading: (state) => state.loading,
        }),

        ...mapState('calendar', {
            todaysDateLuxonObject: (state) => state.todaysDateLuxonObject,
            problemLogArray: (state) => state.problemLogArray,
            memberProblemProfile: (state) => state.memberProblemProfile,
        }),

        ...mapGetters('calendar', [
            'getterTodaysMonth',
            'getterTodaysYear',
            'getterTimeFrameEndMonthLuxonObject',
            'getterTimeFrameStartMonthLuxonObject',
            'getterFilteredProblemLogVertices',
            'getterProblemProfileSorted',
        ]),

        selectedSevARR() {
            let sSA = []
            for (let i = 0; i < (this.getterProblemProfileSorted).length; i += 1) {
                sSA.push(0)
            }
            return sSA;
        },

        problemLogARR3() {
            let Member_vID = this.memberProfile.Member_vID;
         
            return this.getterProblemProfileSorted.map((el, index) => {
                
                let bucket = {};

                bucket.Member_vID       = Member_vID,
                bucket.probLogDateISO   = probLogDateLocalISO,
                bucket.probLogDateYEAR  = probLogDateYEAR,
                bucket.probLogDateMONTH = probLogDateMONTH,
                bucket.probLogDateDAY   = probLogDateDAY,
                bucket.probLogDateMILLIS= probLogDateLocalMillis,
                bucket.SYMcodebook_vID  = el.SYM_Codebook_vID,
                bucket.sevLevel         = this.selectedSevARR[index],
                bucket.SEVcodebook_vID  = el.SYM_Codebook_vID,
                bucket.probLog_vID      = Member_vID + '_' + probLogDateLocalISO
                // bucket.transDateUTC     = transactionDateUTC
                
                return bucket;
            })
        },

        memberProblemArrayTodayOnly() {
            let todayARR = this.problemLogArray.map((item) => {
                let tempARR = item.pLVArray
                let tempOBJ = tempARR[tempARR.length - 1]
                                                                                    // console.log('from ViewTodayProblems index is below')
                                                                                    // console.log(index)
                                                                                    // console.log('from ViewTodayProblems ARR is below')
                                                                                    // console.log(ARR)
                                                                                    // let lengthARR = ARR.length
                                                                                    // console.log('from ViewTodayProblems   lengthARR is below')
                                                                                    // console.log(lengthARR)
                let PL_Object = {}
                PL_Object.uploadDateLocal = tempOBJ.uploadDateLocal
                PL_Object.SYM_Codebook_vID = tempOBJ.SYM_Codebook_vID
                PL_Object.sevLevel = tempOBJ.sevLevel
                                                                                    // console.log('from ViewTodayProblems uploadDateLocal is below')
                                                                                    // console.log( PL_Object.uploadDateLocal)
                                                                                    // console.log('from ViewTodayProblems SYM_Codebook_vID is below')
                                                                                    // console.log( PL_Object.SYM_Codebook_vID)
                                                                                    // console.log('from ViewTodayProblems sevLevel is below')
                                                                                    // console.log( PL_Object.sevLevel)
                return PL_Object
            })

            return todayARR
        },

        calendarDisplayConfig() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs':
                    return { total: 3, history: -2, hidden: 2 }
                case 'sm':
                    return { total: 8, history: -7, hidden: 4 }
                case 'md':
                    return { total: 8, history: -7, hidden: 4 }
                case 'lg':
                    return { total: 8, history: -7, hidden: 4 }
                case 'xl':
                    return { total: 8, history: -7, hidden: 4 }
                default:
                    return { total: 4, history: -3, hidden: 0 }
            }
        },
    },

    mounted() {
        this.interval = setInterval(() => {
            if (this.spinnerVal === 90) {
                clearInterval(this.interval)
            }
            this.spinnerVal += 10
        }, 1000)

        this.$nextTick(function () {
            this.actionUpdateCalDisplayConfig(this.calendarDisplayConfig)
        })

        this.$nextTick(function () {
            this.actionSetInitTimeFrameStartMonthLuxonObject()
        })

        this.$nextTick(function () {
            this.actionGetProbLogCodebook()
        })

        this.$nextTick(function () {
            this.actionGetProfile_SYM()
        })


    },

    methods: {
        ...mapActions('calendar', [
            'actionUpdateCalDisplayConfig',
            'actionGetProbLogCodebook',
            'actionSetInitTimeFrameStartMonthLuxonObject',
            'actionGetAllProblemLogVertices',
            'actionGetProblemLogArray',
            'actionGetProfile_SYM',
        ]),

        async postTGproblemLog() {
                                                                                    // console.log('STEP #1 OUTSIDE TRY CLIENTSIDE ViewTodayStory postTGproblemsLog')
            if (!this.memberProfile.Member_vID) {
                alert(
                    'Unfortunately there was a server error, please use the Help Desk to let the staff know'
                )
            } else {
                try {
                                                                                    // console.log( 'STEP #2 INSIDE TRY CLIENTSIDE ViewTodayStory the method is postTGproblemsLog')
                    let problemLogARR4 = [];
                    problemLogARR4 =  (this.problemLogARR3).map((item) => {
                        const temp = {};

                        temp.Member_vID         = item.Member_vID;
                        temp.pLDateISO          = item.probLogDateISO;
                        temp.pLDateYEAR         = item.probLogDateYEAR;
                        temp.pLDateMONTH        = item.probLogDateYEAR +'-'+ item.probLogDateMONTH;
                        temp.pLDateDAY          = item.probLogDateYEAR +'-'+ item.probLogDateMONTH + '-' + item.probLogDateDAY;
                        temp.pLDateMILLIS       = item.probLogDateMILLIS;
                        temp.SYMcodebook_vID    = item.SYMcodebook_vID;
                        temp.sevLevel           = item.sevLevel;
                        temp.transDateUTC       = item.transDateUTC;
                        temp.SEVcodebook_vID    = item.SEVcodebook_vID + '_' + 'SEV' + '_' + item.sevLevel;
                        temp.probLog_vID        = item.probLog_vID + '_' + item.SYMcodebook_vID + '_' + 'SEV' + '_' + item.sevLevel;
                        
                        return temp;                        
                    })
                                                                                    // console.log('problemLogARR4 is below')
                                                                                    // console.log( problemLogARR4 )
                    const res17 = await api.postTGProbLog17(problemLogARR4)
                                                                                    // console.log( 'typeof res17.data is below')
                                                                                    // console.log( typeof res17.data)

                                                                                    // console.log( 'res17.data full object is below')
                                                                                    // console.log( res17.data)

                                                                                    // console.log( 'res17.data.error is below')
                                                                                    // console.log( res17.data.error)

                                                                                    // console.log( 'res17.data.status is below')
                                                                                    // console.log( res17.data.status)
                    const { data } = res17
                                                                                    // console.log('type of data is below')
                                                                                    // console.log(typeof data)

                                                                                    // console.log('PLConfirmation full object is below')
                                                                                    // console.log(data)

                                                                                    // console.log('PLConfirmation.error is below')
                                                                                    // console.log(data.error)
                    if (data.error === true) {
                                                                                    // console.log('the postTGproblemsLog API error TG returned the error code, status code and message below')
                                                                                    // console.log('below is data.status returned by TigerGraph')
                                                                                    // console.log(data.status)
                                                                                    // console.log('below is data.error boolean where TRUE means the API call FAILED')
                                                                                    // console.log(data.error)
                                                                                    // console.log('below is data.TGmessage blank string means TigerGraph API returned no error message')
                                                                                    // console.log(data.TGmessage)
                        this.showDialog_PostProblemLogERROR
                    } else if (data.error === false) {
                                                                                    // console.log('below is data.status code where 200 means the API call was SUCCESSFUL')
                                                                                    // console.log(data.status)
                                                                                    // console.log('below is data.error code where FALSE means the API call was a SUCCESS')
                                                                                    // console.log(data.error)
                                                                                    // console.log('below is data.TGmessage where a blank string means the API call returned no warning message')
                                                                                    // console.log(data.TGmessage)
                                                                                    // console.log('below is data.results where the TigerGraph API call returns a single object with single property problemLog_vID of the last array item posted')
                                                                                    // console.log(data.results)
                        this.problemLogResponse = res17.data.results
                                                                                    // console.log('problemLogResponse')
                                                                                    // console.log(this.problemLogResponse)
                        const dateTimeNow = DateTime.now()
                        this.problemLogConfirmationDateTime =
                            dateTimeNow.toLocaleString(DateTime.DATETIME_FULL)
                        this.playBell()
                        this.showDialog_PostProblemLogConfirmation = true
                    } else {
                                                                                    // console.log('below is res.error TigerGraph returned error')
                                                                                    // console.log(res17.error)
                    }
                } catch (err) {
                                                                                    console.log('ERROR returns to ViewTodayStory after error')
                }
            }
        },

        toggleConfirmationDialog() {
            this.showDialog_PostProblemLogConfirmation = false
            this.$router.push({ path: '/home/view-health-problems' })
        },
        hideDialogTodayStuff() {
            this.showDialog_TodayStuff = false
        },
        playBell() {
            tone4OBJ.play()
        },
    },
}
</script>

<style lang="scss" scoped>
.view-today-story-container {
    background-color: #d1c4e9;
    padding-left: 0;
    margin-left: 0;
}
.view-today-story-row {
    background-color: '';
}
.view-today-story-col {
    background-color: '';
    align-content: center;
}
.rounded-card {
    background-color: #1e88e5;
    padding-bottom: 0.8em;
    margin-bottom: 0.8em;
    align-content: center;
    border-width: 8px;
    border-radius: 24px;
}
.toolbar-text {
    color: rgb(255, 255, 255);
    font-size: 1.5em;
    text-align: center;
    text-decoration: '';
    text-transform: '';
    font-weight: 'bold';
}
.confirmation-text {
    font-size: 0.8em;
    text-align: center;
    text-decoration: '';
    text-transform: '';
    font-weight: 'bold';
}
.micro-text {
    color: rgb(33, 33, 33);
    font-size: 1em;
    // text-align: center;
    text-decoration: '';
    text-transform: '';
    // font-weight: "bold"
}
.list-text {
    color: rgb(33, 33, 33);
    font-size: 0.8em;
    // text-align: center;
    text-decoration: '';
    text-transform: '';
    // font-weight: "bold"
}
.SYM_label_short {
    font-size: 0.7em;
    font-weight: bold;
    min-height: 34px;
}
.short {
    width: 28px;
}
.short span {
    white-space: nowrap;
    font-size: 0.9em;
    font-weight: bold;
    text-align: center;
    // overflow: hidden;
    text-overflow: ellipsis;
}
</style>
