import Vue from 'vue';

//  ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ OneSignal line below
import OneSignalVue from 'onesignal-vue';


import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import VCalendar from './plugins/vcalendar';
import '@vime/core/themes/default.css';
import '@vime/core/themes/light.css';


// eslint-disable-next-line no-unused-vars
import { Player, Video, DefaultUi, DefaultControls } from '@vime/vue';

//  ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ OneSignal line below
// eslint-disable-next-line no-unused-vars
import registerServiceWorker from './serviceWorkerRegistration';

//  ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ OneSignal line below
// registerServiceWorker();

Vue.config.productionTip = false

//  ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ OneSignal line below
Vue.use(OneSignalVue);

new Vue({
    router,
    store,
    vuetify,
    VCalendar,
    render: (h) => h(App),
    beforeMount() {  //  ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ OneSignal line below
        this.$OneSignal.init({
          appId: "172db674-d42f-47ee-80ee-a66295740527",  //// this is the APP ID for ms101 deployed on DigitalOcean not for local host testing
          // safari_web_id: "web.onesignal.auto.66c89079-ab76-4c24-84be-2fca07f56f6c",

        //   appId: "0d9d4bec-0054-4af6-bf02-94d21a4ab7dd",
          allowLocalhostAsSecureOrigin: true,
          
          // subdomainName: "dec04",
          // serviceWorkerParam: { scope: "/" },
          // serviceWorkerPath: "/",

          notifyButton: {
            enable: true,
          },
          promptOptions: {
            slidedown: {
              prompts: [{
                type: "smsAndEmail",
                autoPrompt: false,
                text: {
                  emailLabel: "Insert Email Address",
                  smsLabel: "Insert Phone Number",
                  acceptButton: "Submit",
                  cancelButton: "No Thanks",
                  actionMessage: "Receive the latest news, updates and offers as they happen.",
                  updateMessage: "Update your push notification subscription preferences.",
                  confirmMessage: "Thank You!",
                  positiveUpdateButton: "Save Preferences",
                  negativeUpdateButton: "Cancel",
                },
                delay: {
                  pageViews: 1,
                  timeDelay: 20
                },
              },
              {
                type: "category",
                autoPrompt: true,
                text: {
                  actionMessage: "We'd like to show you notifications for the latest news and updates.",
                  acceptButton: "Allow",
                  cancelButton: "Cancel",
      
                  /* CATEGORY SLIDEDOWN SPECIFIC TEXT */
                  negativeUpdateButton: "Cancel",
                  positiveUpdateButton: "Save Preferences",
                  updateMessage: "Update your push notification subscription preferences.",
                },
                delay: {
                  pageViews: 3,
                  timeDelay: 20
                },
                categories: [{
                  tag: "politics",
                  label: "Politics"
                },
                {
                  tag: "local_news",
                  label: "Local News"
                },
                {
                  tag: "world_news",
                  label: "World News",
                },
                {
                  tag: "culture",
                  label: "Culture"
                },
                ]
              }
              ]
            }
          
          }  
        }).then(()=>{
          console.log('promise fulfilled from async Zettakal OneSignal')
        });
      },
}).$mount('#app')


//  ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ OneSignal line below
// registerServiceWorker();