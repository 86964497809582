import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
// import ViewExercise from '../views/ViewExercise.vue'
import ViewHome from '../views/ViewHome.vue'
import ViewLogin from '../views/ViewLogin.vue'
// import ViewCreateMember from '../views/ViewCreateMember.vue';
// import ViewMedicalAppointments from '../views/ViewMedicalAppointments.vue'
// import ViewRegister from '../views/ViewRegister.vue'
import ViewHealthProblems from '../views/ViewHealthProblems.vue'
// import ViewNutritionDeals from '../views/ViewNutritionDeals.vue'
// import ViewStressFactors from '../views/ViewStressFactors.vue'
// import ViewOralMeds from '../views/ViewOralMeds.vue'
// import ViewInfusedMeds from '../views/ViewInfusedMeds.vue'
// import ViewInjectedMeds from '../views/ViewInjectedMeds.vue'
// import ViewVaccinations from '../views/ViewVaccinations.vue'
// import ViewHelpDesk from '../views/ViewHelpDesk.vue'
// import ViewSubscriptions from '../views/ViewSubscriptions.vue'
// import ViewMedEd from '../views/ViewMedEd.vue'
// import ViewSelfCare from '../views/ViewSelfCare.vue'
import ViewTodayProblems from '../views/ViewTodayProblems.vue'
// import ViewTodayDrugs from '../views/ViewTodayDrugs.vue'
// import ViewTellMyDoctor from '../views/ViewTellMyDoctor.vue'
// import ViewProfile from '../views/ViewProfile.vue'
import ViewNotFound from '../views/ViewNotFound.vue'
// import ViewHiddenStaffPortal from '../views/ViewHiddenStaffPortal.vue'
// import ViewFakeBirthday from '../views/ViewFakeBirthday.vue'

Vue.use(VueRouter)

function checkAccessTokenBool() {
    // console.log('router.index.js state.home.validAccessToken is below')
    // console.log(store.state.home.validAccessToken)
    return store.state.home.validAccessToken
}

const routes = [
    {
        path: '/',
        redirect: '/Login',
    },
    {
        path: '/register',
        name: 'Register',
        // component: ViewRegister,
  
        component: () =>
            import(
                /*webpackChunkName: "ViewRegister" */ '../views/ViewRegister'
            ),

        meta: {
            title: 'MS101.me - Register',
            requiresAuth: false,
            redirectAuth: '/home/view-symptoms',
        },
    },
    {
        path: '/login',
        name: 'Login',
        component: ViewLogin,
        meta: {
            title: 'Zettakal Login',
            requiresAuth: false,
            redirectAuth: 'Home',
        },
    },
    {
        path: '/home',
        redirect: '/home/view-today-problems',
        name: 'Home',
        component: ViewHome,
        meta: {
            title: 'Zettakal Home',
            requiresAuth: true,
        },
        children: [
            {
                path: 'view-medical-appointments',
                name: 'ViewMedicalAppointments',
                // component: ViewMedicalAppointments,

                component: () =>
                    import(
                        /*webpackChunkName: "ViewMedicalAppointments" */ '../views/ViewMedicalAppointments'
                    ),
                
                props: true,
                meta: {
                    title: 'Zettakal Medical Appointments',
                    requiresAuth: true,
                },
            },
            
            
            {
                path: 'view-today-problems',
                name: 'ViewTodayProblems',
                component: ViewTodayProblems,
                props: true,
                meta: {
                    title: 'Zettakal Todays Story - Problems',
                    requiresAuth: true,
                },
            },


            {
                path: 'view-today-drugs',
                name: 'ViewTodayDrugs',
                // component: ViewTodayDrugs,

                component: () =>
                    import(
                        /*webpackChunkName: "ViewTodayDrugs" */ '../views/ViewTodayDrugs'
                    ),

                props: true,
                meta: {
                    title: 'Zettakal Todays Story - Oral Medications',
                    requiresAuth: true,
                },
            },
            
            {
                path: 'view-health-problems',
                name: 'ViewHealthProblems',
                component: ViewHealthProblems,
                props: true,
                meta: {
                    title: 'Zettakal Health Problems',
                    requiresAuth: true,
                },
            },
            
            {
                path: 'view-nutrition-deals',
                name: 'ViewNutritionDeals',
                // component: ViewNutritionDeals,

                component: () =>
                    import(
                        /*webpackChunkName: "ViewNutritionDeals" */ '../views/ViewNutritionDeals'
                    ),

                props: true,
                meta: {
                    title: 'Zettakal Nutrition Deals',
                    requiresAuth: true,
                },
            },
            
            {
                path: 'view-stress',
                name: 'ViewStressFactors',
                // component: ViewStressFactors,

                component: () =>
                    import(
                        /*webpackChunkName: "ViewStressFactors" */ '../views/ViewStressFactors'
                    ),

                props: true,
                meta: {
                    title: 'Zettakal Stress Factors',
                    requiresAuth: true,
                },
            },
            
            {
                path: 'view-exercise',
                name: 'ViewExercise',
                // component: ViewExercise,

                component: () =>
                    import(
                        /* webpackChunkName: "ViewExercise" */ '../views/ViewExercise'
                    ),
                
                props: true,
                meta: {
                    title: 'Zettakal Exercise',
                    requiresAuth: true,
                },
            },
            
            {
                path: 'view-selfcare',
                name: 'ViewSelfCare',
                // component: ViewSelfCare,
                component: () =>
                    
                    import(
                    /* webpackChunkName: "ViewSelfCare" */ '../views/ViewSelfCare'
                    ),
                
                props: true,
                meta: {
                    title: 'Zettakal Self Care',
                    requiresAuth: true,
                },
            },
            
            {
                path: 'view-oral-meds',
                name: 'ViewOralMeds',
                // component: ViewOralMeds,

                component: () =>
                    import(
                        /* webpackChunkName: "ViewOralMeds" */ '../views/ViewOralMeds'
                    ),

                props: true,
                meta: {
                    title: 'Zettakal Oral Medications',
                    requiresAuth: true,
                },
            },
            
            {
                path: 'view-injected-meds',
                name: 'ViewInjectedMeds',
                // component: ViewInjectedMeds,

                component: () =>
                    import(
                        /* webpackChunkName: "ViewInjectedMeds" */ '../views/ViewInjectedMeds'
                    ),

                props: true,
                meta: {
                    title: 'Zettakal Injectable Medications',
                    requiresAuth: true,
                },
            },
            
            {
                path: 'view-infused-meds',
                name: 'ViewInfusedMeds',
                // component: ViewInfusedMeds,

                component: () =>
                    import(
                        /* webpackChunkName: "ViewInfusedMeds" */ '../views/ViewInfusedMeds'
                    ),

                props: true,
                meta: {
                    title: 'Zettakal Infused Medications',
                    requiresAuth: true,
                },
            },
            
            {
                path: 'view-vaccinations',
                name: 'ViewVaccinations',
                // component: ViewVaccinations,

                component: () =>
                    import(
                        /* webpackChunkName: "ViewVaccinations" */ '../views/ViewVaccinations'
                    ),

                props: true,
                meta: {
                    title: 'Zettakal Vaccinations',
                    requiresAuth: true,
                },
            },
            
            {
                path: 'view-tell-my-doctor',
                name: 'ViewTellMyDoctor',
                // component: ViewTellMyDoctor,

                    component: () =>
                        import(
                            /* webpackChunkName: "ViewTellMyDoctor" */ '../views/ViewTellMyDoctor'
                        ),
                
                props: true,
                meta: {
                    title: 'Zettakal Tell My Doctor',
                    requiresAuth: true,
                },
            },
            
            {
                path: 'view-subscriptions',
                name: 'ViewSubscriptions',
                //component: ViewSubscriptions,
                component: () =>
                    import(
                        /* webpackChunkName: 'ViewSubscriptions' */ '../views/ViewSubscriptions'
                    ),
                props: true,
                meta: {
                    title: 'Zettakal Subscriptions',
                    requiresAuth: true,
                },
            },
            
            {
                path: 'view-med-ed',
                name: 'ViewMedEd',
                // component: ViewMedEd,
                component: () =>
                    import(
                        /* webpackChunkName: "ViewMedEd" */ '../views/ViewMedEd'
                    ),
                props: true,
                meta: {
                    title: 'Zettakal MedEd',
                    requiresAuth: true,
                },
            },
            
            {
                path: 'view-help-desk',
                name: 'ViewHelpDesk',
                // component: ViewHelpDesk,
                component: () =>
                    import(
                        /* webpackChunkName: "ViewHelpDesk" */ '../views/ViewHelpDesk'
                    ),
                props: true,
                meta: {
                    title: 'Zettakal Help Desk',
                    requiresAuth: true,
                },
            },
            
            {
                path: 'view-profile',
                name: 'ViewProfile',
                // component: ViewProfile,

                component: () =>
                    import(
                        /* webpackChunkName: "ViewProfile" */ '../views/ViewProfile'
                    ),

                props: true,
                meta: {
                    title: 'Zettakal Profile',
                    requiresAuth: true,
                },
            },
            
            {
                path: 'view-hidden-staff-portal',
                name: 'ViewHiddenStaffPortal',
                // component: ViewHiddenStaffPortal,

                component: () =>
                    import(
                        /* webpackChunkName: "ViewHiddenStaffPortal" */ '../views/ViewHiddenStaffPortal'
                    ),

                props: true,
                meta: {
                    title: 'Zettakal Staff Portal',
                    requiresAuth: true,
                },
            },
            {
                path: 'view-hidden-tool',
                name: 'ViewHiddenTool',
                // component: ViewHiddenStaffPortal,

                component: () =>
                    import(
                        /* webpackChunkName: "ViewHiddenStaffPortal" */ '../views/ViewHiddenTool'
                    ),

                props: true,
                meta: {
                    title: 'Zettakal Hidden Tool',
                    requiresAuth: true,
                },
            },
            {
                path: 'view-fake-birthday',
                name: 'ViewFakeBirthday',
                // component: ViewHiddenStaffPortal,

                component: () =>
                    import(
                        /* webpackChunkName: "ViewHiddenStaffPortal" */ '../views/ViewFakeBirthday'
                    ),

                props: true,
                meta: {
                    title: 'Zettakal Fake Birthday',
                    requiresAuth: true,
                },
            },
        ],
    },
    {
        path: '*',
        name: 'view-not-found',
        component: ViewNotFound,
        meta: { title: 'MS101.me - Not Found' },
    },
]

const router = new VueRouter({
    mode: 'history',
    linkActiveClass: 'active',
    linkExactActiveClass: 'exact-active',
    // base: process.env.BASE_URL,
    routes,
})

router.beforeEach((to, from, next) => {
    if (to.meta.requiresAuth) {
        if (!checkAccessTokenBool() && to.name !== 'Login') {
            next({ name: 'Login' })
        } else {
            next()
        }
    } else {
        next()
    }
})

router.afterEach((to) => {
    document.title = to.meta.title
})

export default router
