<template>
    <v-navigation-drawer
        ref="ND"
        v-click-outside="{
            handler: onClickOutsideWithConditional,
            closeConditional,
            include: include,
        }"
        app
        :stateless="mobileDisplayBool"
        :temporary="mobileDisplayBool"
        :permanent="!mobileDisplayBool"
        :value="mobileDrawerOpen"
        dark
        clipped
        width="164px"
        color="blue darken-1"
    >
        <v-toolbar
            v-if="mobileDisplayBool"
            color="deep-purple darken-4"
            height="54"
            pa-1
        >
            <v-toolbar-title>
                <v-img
                    height="10"
                    contain
                    src="/img/zt9.png"
                    alt="Zettakal Logo"
                >
                </v-img>
            </v-toolbar-title>
            <v-spacer> </v-spacer>
            <v-icon @click="closeTempDrawer"> cancel </v-icon>
        </v-toolbar>

        <v-list nav dense>
            <v-list-item>
                <v-avatar size="32">
                    <v-img
                      
                        src="/img/square-red-rose.jpg"
                        alt="red-rose"
                    ></v-img>
                </v-avatar>
            </v-list-item>
            <v-divider> </v-divider>

            <v-list-item
                v-for="navLink in navLinksFiltered"
                :key="navLink.navItem"
                class="pa-2"
            >
                <router-link :to="navLink.navLinkTo" class="routerLink">
                    <v-badge
                        :content="navLink.badgeValue"
                        :value="navLink.badgeValue > 0"
                        color="red accent-4"
                        offset-x="12"
                        offset-y="6"
                    >
                        <v-avatar size="32">
                            <v-icon small :class="navLink.iconClass">
                                {{ navLink.navLinkIcon }}
                            </v-icon>
                        </v-avatar>
                    </v-badge>
                    <span class="navFont">
                        {{ navLink.navItem }}
                    </span>
                </router-link>
            </v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import calendarDefs from '@/data/calendars'

export default {
    name: 'NavDrawer',

    props: {
        mobileDisplayBool: { type: Boolean, required: false },
    },

    data() {
        return {
            navLinks: [
                {
                    navItem: 'Med Appointments',
                    navLinkTo: '/home/view-medical-appointments',
                    navLinkIcon: 'fa-calendar-day',
                    iconClass: 'deep-purple darken-4',
                    activeStatus: true,
                    badgeValue: 0,
                },
                {
                    navItem: "Today's Story",
                    navLinkTo: '/home/view-today-problems',
                    navLinkIcon: 'fa-comment-dots',
                    iconClass: 'deep-purple darken-4',
                    activeStatus: true,
                    badgeValue: null,
                },
                {
                    navItem: "Today's Oral Meds",
                    navLinkTo: '/home/view-today-drugs',
                    navLinkIcon: 'fa-comment-dots',
                    iconClass: 'deep-purple darken-4',
                    activeStatus: false,
                    badgeValue: null,
                },
                {
                    navItem: 'Nutrition Deals',
                    navLinkTo: '/home/view-nutrition-deals',
                    navLinkIcon: 'fa-cart-arrow-down',
                    iconClass: 'deep-purple darken-4',
                    activeStatus: false,
                    badgeValue: 4,
                },
                {
                    navItem: 'Health Problems',
                    navLinkTo: '/home/view-health-problems',
                    navLinkIcon: 'fa-tired',
                    iconClass: 'deep-purple darken-4',
                    activeStatus: true,
                    badgeValue: 0,
                },
                {
                    navItem: 'Stress',
                    navLinkTo: '/home/view-stress',
                    navLinkIcon: 'fa-exclamation-triangle',
                    iconClass: 'deep-purple darken-4',
                    activeStatus: false,
                    badgeValue: null,
                },
                {
                    navItem: 'Self Care',
                    navLinkTo: '/home/view-selfcare',
                    navLinkIcon: 'self_improvement',
                    iconClass: 'green darken-3',
                    activeStatus: false,
                    badgeValue: 2,
                },
                {
                    navItem: 'Exercise',
                    navLinkTo: '/home/view-exercise',
                    navLinkIcon: 'directions_run',
                    iconClass: 'deep-purple darken-4',
                    activeStatus: false,
                    badgeValue: null,
                },
                {
                    navItem: 'Oral Meds',
                    navLinkTo: '/home/view-oral-meds',
                    navLinkIcon: 'fa-pills',
                    iconClass: 'green darken-3',
                    activeStatus: false,
                    badgeValue: null,
                },
                {
                    navItem: 'Injected Meds',
                    navLinkTo: '/home/view-injected-meds',
                    navLinkIcon: 'fa-level-down-alt',
                    iconClass: 'green darken-3',
                    activeStatus: true,
                    badgeValue: null,
                },
                {
                    navItem: 'Infusions',
                    navLinkTo: '/home/view-infused-meds',
                    navLinkIcon: 'fa-sign-out-alt',
                    iconClass: 'green darken-3',
                    activeStatus: true,
                    badgeValue: null,
                },
                {
                    navItem: 'Vaccinations',
                    navLinkTo: '/home/view-vaccinations',
                    navLinkIcon: 'fa-arrows-alt',
                    iconClass: 'deep-purple darken-4',
                    activeStatus: false,
                    badgeValue: 0,
                },
                {
                    navItem: 'Subscriptions',
                    navLinkTo: '/home/view-subscriptions',
                    // navLinkIcon: 'fa-arrows-alt',
                    navLinkIcon: 'fa-people-arrows',
                    iconClass: 'deep-purple darken-4',
                    activeStatus: false,
                    badgeValue: 2,
                },
                {
                    navItem: 'Tell My Doctor',
                    navLinkTo: '/home/view-tell-my-doctor',
                    navLinkIcon: 'fa-user-md',
                    iconClass: 'deep-purple darken-4',
                    activeStatus: true,
                    badgeValue: 0,
                },
                {
                    navItem: 'Med Ed Invites',
                    navLinkTo: '/home/view-subscriptions',
                    navLinkIcon: 'fa-newspaper',
                    iconClass: 'deep-purple darken-4',
                    activeStatus: false,
                    badgeValue: null,
                },
                {
                    navItem: 'Med Ed Messages',
                    navLinkTo: '/home/view-med-ed',
                    navLinkIcon: 'fa-university',
                    iconClass: 'deep-purple darken-4',
                    activeStatus: false,
                    badgeValue: null,
                },
                {
                    navItem: 'Help Desk',
                    navLinkTo: '/home/view-help-desk',
                    navLinkIcon: 'fa-user-cog',
                    iconClass: 'grey darken-4',
                    activeStatus: true,
                    badgeValue: 0,
                },
                {
                    navItem: 'Profile',
                    navLinkTo: '/home/view-profile',
                    navLinkIcon: 'fa-gears',
                    iconClass: 'grey darken-4',
                    activeStatus: true,
                    badgeValue: 0,
                },
                {
                    navItem: 'FakeBirthday',
                    navLinkTo: '/home/view-fake-birthday',
                    navLinkIcon: 'fa-calendar-alt',
                    iconClass: 'grey darken-4',
                    activeStatus: false,
                    badgeValue: 0,
                },
            ],
            calendars: calendarDefs,
            value: true,
        }
    },
    computed: {
        ...mapState('home', {
            user: (state) => state.user,
            mobileDrawerOpen: (state) => state.tempDrawer,
        }),
        userLeapID() {
            return this.$store.state.home.user
        },

        navLinksFiltered() {
            return this.filterNavLinks(this.navLinks)
        },
    },

    methods: {
        ...mapActions('home', ['shutTempDrawer', 'openTempDrawer']),

        expandTempDrawer() {
            this.openTempDrawer()
        },
        closeTempDrawer() {
            this.shutTempDrawer()
        },
        /* 
          onClickOutside() {
                    if ( this.mobileDrawerOpen = true ) { this.shutTempDrawer()} 
          },  */

        onClickOutsideWithConditional() {
            this.mobileDrawerOpen = false
        },
        closeConditional() {
            return this.closeTempDrawer()
        },
        include() {
            return [document.querySelector('.included')]
        },

        logout() {
            this.$store.dispatch('logout', { router: this.$router })
        },

        selectstream: function (event) {
            this.$emit('selectcalendar', 'Oral Medications')
            if (event) {
                alert(event.target.tagName)
            }
        },

        filterNavLinks(insertedArray) {
            const fNL = insertedArray.filter(
                (item) => item.activeStatus == true
            )

            return fNL
        },
    },
}
</script>

<style lang="scss" scoped>
.nav-drawer-canvas {
    background-color: #1e88e5;
}
.routerLink {
    color: #ffffff;
    text-decoration: none;
    font-size: 0.8em;
    background-color: transparent;
}
.navFont {
    font-size: 1em;
}
.profileName {
    color: white;
}
</style>
