<template>
    <v-app>
        <v-main>
            <v-container fluid fill-height>
                <v-layout align-center justify-center>
                    <v-flex xs12 sm8 md6 lg4>
                        <v-card class="elevation-4" color="white">
                            <v-toolbar dark>
                                <v-toolbar-title class="v-toolbar__logo">
                                    <img src="/img/ms101.png" alt="MS101.me" />
                                </v-toolbar-title>
                            </v-toolbar>
                            <v-card-text class="text-xs-center py-3 px-4">
                                <p class="display-4 primary--text">404</p>
                                <p class="headline">
                                    The page you requested could not be found
                                </p>
                            </v-card-text>
                            <v-card-actions class="justify-center pb-3">
                                <v-btn color="primary" to="/home">
                                    Return to Dashboard
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
export default {
    name: 'NotFound',
}
</script>
