<template>
    <v-card outlined class="calendar" elevation="0">
        <p class="title">{{ dateString }}</p>
        <div class="weeks">
            <div
                v-for="(weekday, idx) of weekdays"
                :key="`weekday-${idx}`"
                class="purple--text text--darken-4 font-weight-bold"
            >
                {{ weekday }}
            </div>
            <CalDots
                v-for="(day, idx) of monthData"
                :id="day.num !== null ? day.num : null"
                :key="`grid-${idx}`"
                class="day"
                :style="day.num !== null ? getStyles(day.value) : null"
                :month="month"
                :year="year"
                :values="apptDotsObject"
                :color-map="colorMap"
                @pop-up-details="displayDetails($event)"
            >
            </CalDots>
            <div class="text-center">
                <v-dialog v-model="showListDialog" width="340">
                    <v-card color="white">
                        <v-system-bar color="blue darken-1">
                            <!-- <v-toolbar-title class= "text--white"> -->
                            <v-spacer></v-spacer>
                            APPOINTMENTS
                            <v-spacer></v-spacer>

                            <v-btn
                                depressed
                                x-small
                                color="transparent"
                                @click="toggleShowListDialog"
                            >
                                <v-icon dark> fa-times </v-icon>
                            </v-btn>
                            <!-- </v-toolbar-title> -->
                        </v-system-bar>
                        <!-- <v-card-subtitle>Appointments</v-card-subtitle> -->
                        <v-card-subtitle class="white">
                            {{ dateClickedDisplay }}
                        </v-card-subtitle>
                        <v-list dense>
                            <!-- <v-subheader>REPORTS</v-subheader> -->
                            <v-list-item-group
                                v-model="selectedAppointment"
                                color="blue darken-1"
                            >
                                <v-list-item
                                    v-for="appointment in getAppointmentsByDateClickedDisplay"
                                    :key="appointment.Med_Appt_vID"
                                >
                                    &nbsp;
                                    {{ appointment.ApptTime }}
                                    {{ appointment.ApptTimeZone }}:
                                    {{ appointment.HCPFirstName }}
                                    {{ appointment.HCPLastName }}
                                    <v-spacer></v-spacer>

                                    <!-- <v-list-item-icon> -->
                                    <!--                                     <v-btn
                                        x-small
                                        icon
                                        depressed
                                        width="14px"
                                        min-width="14px"
                                        href="https://drive.google.com/file/d/15lEiFn6OMSboQ2acggUtEVqtlLUu08Xu/view?usp=sharing"
                                        target="_blank"
                                    >
                                        <v-icon dense color="blue">
                                            fa-file-medical-alt
                                        </v-icon>
                                    </v-btn> -->
                                    <!-- </v-list-item-icon> -->
                                    <!-- <v-spacer></v-spacer> -->
                                    <!-- <v-list-item-icon> -->
                                    <!--     <v-btn
                                        x-small
                                        icon
                                        depressed
                                        width="14px"
                                        min-width="14px"
                                        href="https://ln3.sync.com/dl/643ba19a0#yw9ryd62-syh875if-8d57vymv-dhb2jwhy"
                                        target="_blank"
                                    >
                                        <v-icon dense color="pink">
                                            fa-file
                                        </v-icon>
                                    </v-btn> -->
                                    <!-- </v-list-item-icon> -->
                                    <!-- <v-spacer> </v-spacer> -->
                                    <!-- <v-list-item-icon>  -->
                                    <v-btn
                                        x-small
                                        icon
                                        depressed
                                        width="14px"
                                        min-width="14px"
                                        @click="toggleShowConfirmDialog"
                                    >
                                        <v-icon dense color="red">
                                            fa-trash-alt
                                        </v-icon>
                                    </v-btn>
                                    <v-icon x-small color="transparent">
                                        fa-circle
                                    </v-icon>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                        <v-expand-transition>
                            <div v-show="showConfirmDialog">
                                <v-divider></v-divider>
                                <v-card-actions>
                                    <v-btn
                                        small
                                        outlined
                                        rounded
                                        color="red"
                                        @click="toggleShowConfirmDialog"
                                    >
                                        Cancel
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        small
                                        dark
                                        rounded
                                        color="red"
                                        @click="deleteMedAppt"
                                    >
                                        Move to Trash
                                    </v-btn>
                                </v-card-actions>
                            </div>
                        </v-expand-transition>
                    </v-card>
                </v-dialog>
            </div>
        </div>
    </v-card>
</template>

<script>
import CalDots from '@/components/calendar/CalDots.vue'
import { mapState, mapActions } from 'vuex'
import { DateTime } from 'luxon'

export default {
    name: 'CalendarComponentHealthProblems',

    components: {
        CalDots,
    },

    props: {
        month: { type: Number, required: true },
        year: { type: Number, required: true },
        values: { type: Object, required: true },
        colorMap: { type: Object, required: true },
    },

    data() {
        return {
            maxWeeks: 6,
            weekdays: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],

            showListDialog: false,
            showDeleteDialog: false,
            showConfirmDialog: false,

            dateSelected: '',
            selectedAppointment: [0],
        }
    },

    computed: {
        date() {
            return new Date(this.year, this.month)
        },

        ...mapState({
            dateClicked: (state) => state.calendar.dateClicked,
        }),
        ...mapState({
            apptArray: (state) => state.appoint.apptArray,
            apptDotsObject: (state) => state.appoint.apptDotsObject,
        }),

        dateClickedDisplay() {
            const dt98 = DateTime.fromISO(this.dateClicked)
            return dt98.toLocaleString(DateTime.DATE_HUGE)
        },

        dateString() {
            return this.date.toLocaleDateString('en-US', {
                month: 'long',
                year: 'numeric',
            })
        },

        getAppointmentsByDateClicked() {
            return this.apptArray.filter(
                (appointments) =>
                    appointments.ApptDateISOLocal.substring(0, 10) ===
                    this.dateClicked
            )
        },
        getAppointmentsByDateClickedDisplay() {
            const array7 = this.getAppointmentsByDateClicked.map((el) => {
                const ApptDateLuxonObject = DateTime.fromISO(
                    DateTime.fromISO(el.ApptDateISOLocal)
                )
                const obj = {
                    ApptTime: ApptDateLuxonObject.toLocaleString(
                        DateTime.TIME_SIMPLE
                    ),
                    ApptTimeZone: ApptDateLuxonObject.toFormat('ZZZZ'),
                    HCPFirstName: el.HCPFirstName,
                    HCPLastName: el.HCPLastName,
                    Med_Appt_vID: el.Med_Appt_vID,
                }
                return obj
            })
            return array7
        },

        selectedAppointmentforDeletion() {
            return this.getAppointmentsByDateClicked[this.selectedAppointment]
                .Med_Appt_vID
        },

        monthData() {
            // the "days" is the core 42 element array for the month calendar grid
            const days = new Array(this.weekdays.length * this.maxWeeks)
            // the counter const is EPOCH milliseconds for the 1st day of the month the calendar is rendering
            const counter = new Date(this.date.getTime())
            // setDate() changes the day of the month of a given Date instance; returns EPOCH milliseconds
            // getDate() returns the day of the month the initial value of COUNTER is the 1st of the month
            // getDay() returns the day of the week SUN=0 MON=1 TUE=2 WED=3 THU=4 FRI=5 SAT=6
            counter.setDate(counter.getDate() - counter.getDay())
            // looping through the 42 element array
            for (let i = 0; i < days.length; i += 1) {
                // the conditional IF statement below will be FALSE for each loop and thus sets the NUM value to NULL because
                // subtracting the day of the week integer for the 1st of the month eg. 4=THUR APR 1 from the loop [1],[2],[3]
                // will return the PRIOR month eg if [i] =3 then 3-4 =-1 which is equivalent to MARCH 31 and the condtional is FALSE
                // the conditional won't return TRUE until [i] first reaches the increment where the subtraction does not return
                // the prior month but will return the current month

                if (counter.getMonth() === this.month) {
                    // "days" = 42 element array of objects with "num" and "val" as properties
                    // "num" is the correct date otherwise it's "null" if the 6x7 grid
                    // element doesn't exist on the current month's calendar
                    // "val" is the hex color for the div background derived from color map for that day's data
                    // "val" defaults to white if there are no data to be displayed for that date
                    const num = counter.getDate()
                    const val = this.getDataValue(counter)
                    days[i] = { num, ...val }
                } else {
                    days[i] = { num: null }
                }
                counter.setDate(counter.getDate() + 1)
            }
            return days
        },
    },

    methods: {
        ...mapActions('calendar', ['actionUpdateDateClicked']),
        ...mapActions('appoint', [
            'actionGetMedAppt77',
            'actionDeleteMedAppt79',
        ]),

        getDataValue(date) {
            const key = date.toISOString().slice(0, 10)
            const value = this.values[key]
            return value !== undefined ? value : null
        },

        getStyles(value) {
            const color = this.colorMap[value]
            if (color) {
                return {
                    backgroundColor: color,
                    cursor: 'pointer',
                }
            }
            return null
        },

        displayDetails(dateSelected) {
            this.actionUpdateDateClicked(dateSelected)
            this.toggleShowListDialog()
        },

        deleteMedAppt() {
            this.actionDeleteMedAppt79(this.selectedAppointmentforDeletion)
            this.toggleShowListDialog()
            this.toggleShowConfirmDialog()
        },

        toggleShowListDialog() {
            this.showListDialog = !this.showListDialog
        },
        toggleShowDeleteDialog() {
            this.showDeleteDialog = !this.showDeleteDialog
        },
        toggleShowConfirmDialog() {
            this.showConfirmDialog = !this.showConfirmDialog
        },
    },
}
</script>

<style lang="scss" scoped>
.calendar {
    padding: 0.4em;
    margin: 0.2em;
    border: solid #4a148c;
    border-radius: 2em;
    border-width: thick;
    text-align: center;
    background-color: white;

    .title {
        margin-bottom: 0.6em;
        margin-top: 0.6em;
        text-align: center;
    }

    .weeks {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        row-gap: 0.7em;
        column-gap: 0.7em;

        .day {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            justify-self: center;
            height: 1.8em;
            width: 1.8em;
            border-radius: 50%;

            &:first-child,
            &:nth-child(7n + 1) {
                justify-self: start;
            }

            &:nth-child(7n) {
                justify-self: end;
            }

            .audio-available {
                position: absolute;
                top: -5px;
                left: calc(100% - 9px);
            }
        }
    }
}
</style>
