const REMOVE_TIME = 4000

import api from '../../api'

export const types = {
    RESET: 'RESET',
    SET_TEMPDRAWER: 'SET_TEMPDRAWER',
    SET_LOADING: 'SET_LOADING',
    ADD_ERROR: 'ADD_ERROR',
    REMOVE_ERROR: 'REMOVE_ERROR',
    SET_USER: 'SET_USER',
    SET_MEMBER_PROFILE: 'SET_MEMBER_PROFILE',
    SET_MEMBER_PROFILE_ITEMS: 'SET_MEMBER_PROFILE_ITEMS',
    SET_VALID_ACCESS_TOKEN: 'SET_VALID_ACCESS_TOKEN',
    SET_MEMBER_TODAY_DRUG_DISPLAY: 'SET_MEMBER_TODAY_DRUG_DISPLAY',
    SET_DM_UNREAD_BADGE_VALUE: 'SET_DM_UNREAD_BADGE_VALUE',
    SET_HELPDESK_UNREAD_BADGE_VALUE: 'SET_HELPDESK_UNREAD_BADGE_VALUE',
    SET_FLAG_SESSION_HELLO_BOOL: 'SET_FLAG_SESSION_HELLO_BOOL',
    SET_DIALOG_MEDAPPT_LIST_SESSION_TRIGGERED_BOOL:
        'SET_DIALOG_MEDAPPT_LIST_SESSION_TRIGGERED_BOOL',
    SET_SELECTED_HDDM_FOR_READ_STATUS: 'SET_SELECTED_HDDM_FOR_READ_STATUS',
    SET_TARGET_MEMBER_PROFILE: 'SET_TARGET_MEMBER_PROFILE',
    SET_TARGET_MEMBER_PROFILE_ITEMS: 'SET_TARGET_MEMBER_PROFILE_ITEMS',
}

const initState = () => ({
    drawer: true,
    tempDrawer: true,
    loading: false,
    errorCount: 0,
    errors: [],
    user: {},
    memberProfile: {
        Caregiver_bool: false,
        Join_Date: '',
        LEAP_ID: '',
        Patient_bool: true,
        Member_vID: null,
    },
    memberProfileItemsArray: [],
    validAccessToken: false,
    memberTodayDrugDisplay: [],
    DM_Unread_Badge_Value: null,
    HelpDesk_Unread_Badge_Value: null,
    sessionFlagHelloBOOL: false,
    dialogMedApptListSessionTriggered: false,
    selectedHDDMforReadStatus: null,
    targetMemberProfile: {},
    targetMemberProfileItemsArray: [],
})

const resetState = () => ({
    drawer: true,
    loading: false,
    errorCount: 0,
    errors: [],
    tempDrawer: true,
    validAccessToken: false,
    memberProfile: {},
    memberProfileItemsArray: [],
    memberTodayDrugDisplay: [],
    DM_Unread_Badge_Value: null,
    HelpDesk_Unread_Badge_Value: null,
    sessionFlagHelloBOOL: false,
    dialogMedApptListSessionTriggered: false,
    selectedHDDMforReadStatus: null,
})

export const module = {
    namespaced: true,
    state: initState(),
    mutations: {
        [types.SET_TEMPDRAWER](state, TDO) {
            state.tempDrawer = TDO
        },
        [types.SET_LOADING](state, value) {
            state.loading = value
        },
        [types.ADD_ERROR](state, error) {
            state.errors.push(error)
            state.errorCount += 1
        },
        [types.REMOVE_ERROR](state, id) {
            state.errors = state.errors.filter((e) => e.id !== id)
        },
        [types.SET_USER](state, user) {
            state.user = user
        },
        [types.SET_MEMBER_PROFILE](state, value) {
            state.memberProfile = value
        },
        [types.SET_MEMBER_PROFILE_ITEMS](state, value) {
            state.memberProfileItemsArray = value
        },
        [types.SET_VALID_ACCESS_TOKEN](state, value) {
            state.validAccessToken = value
        },
        [types.SET_MEMBER_TODAY_DRUG_DISPLAY](state, value) {
            state.memberTodayDrugDisplay = value
        },

        [types.SET_DM_UNREAD_BADGE_VALUE](state, value) {
            state.DM_Unread_Badge_Value = value
        },
        [types.SET_HELPDESK_UNREAD_BADGE_VALUE](state, value) {
            state.HelpDesk_Unread_Badge_Value = value
        },
        [types.SET_FLAG_SESSION_HELLO_BOOL](state, value) {
            state.sessionFlagHelloBOOL = value
        },
        [types.SET_DIALOG_MEDAPPT_LIST_SESSION_TRIGGERED_BOOL](state, value) {
            state.dialogMedApptListSessionTriggered = value
        },

        [types.SET_SELECTED_HDDM_FOR_READ_STATUS](state, value) {
            state.selectedHDDMforReadStatus = value
        },

        [types.RESET](state) {
            const s = resetState()
            Object.keys(s).forEach((key) => {
                state[key] = s[key]
            })
        },
        [types.SET_TARGET_MEMBER_PROFILE](state, value) {
            state.targetMemberProfile = value
        },
        [types.SET_TARGET_MEMBER_PROFILE_ITEMS](state, value) {
            state.targetMemberProfileItemsArray = value
        },
    },

    getters: {
        getterMember_vID: (state) => {
            return state.memberProfile.Member_vID
        },
        getterMemberProfile_zDrugArray: (state) => {
            const memProfileARR = state.memberProfileItemsArray
            const profileZDrug = memProfileARR.filter(
                (item) => item['memProfileItemType'] == 'zDrug'
            )
            return profileZDrug
        },
        getterMemberProfile_problemSYMArray: (state) => {
            const memProfileARR = state.memberProfileItemsArray
            const profileProblemSYM = memProfileARR.filter(
                (item) => item['memProfileItemType'] == 'problemSYM'
            )
            return profileProblemSYM
        },
        getterTargetMemberProfile_problemSYMArray: (state) => {
            const targetMemProfileARR = state.targetMemberProfileItemsArray
            const profileProblemSYM = targetMemProfileARR.filter(
                (item) => item['memProfileItemType'] == 'problemSYM'
            )
            return profileProblemSYM
        },
    },

    actions: {
        addError({ state, commit }, message) {
            const id = state.errorCount
            commit(types.ADD_ERROR, { id, message })
            setTimeout(() => commit(types.REMOVE_ERROR, id), REMOVE_TIME)
        },
        /*     toggleTempDrawerOpen({ state, commit }) {  
                                                                                          console.log('clicked APP BAR icon')
                                                                                          const TDO = !state.tempDrawerOpen
                                                                                          commit(types.SET_TEMPDRAWEROPEN, TDO)
                                                                                        },

                                                                                        toggleNavDrawer({ state, commit }) {  
                                                                                          console.log('clicked nav icon')
                                                                                          const val = !state.drawer
                                                                                          commit(types.SET_DRAWER, val)
                                                                                        }, */
        shutTempDrawer({ commit }) {
            // shutTempDrawer({ state, commit }) {
            // console.log('shutTempDrawer action triggered outside logic test')
            const closeValue = false
            // console.log('shutTempDrawer action triggered inside logic test')
            commit(types.SET_TEMPDRAWER, closeValue)
        },

        openTempDrawer({ commit }) {
            // openTempDrawer({ state, commit }) {
            // console.log('openTempDrawer action triggered outside logic test')
            const openValue = true
            // console.log('openTempDrawer action triggered inside logic test')
            commit(types.SET_TEMPDRAWER, openValue)
        },

        actionSetDialogMedApptListSessionTriggered({ commit }) {
            const sessionTriggeredBOOL = true
            // console.log('VUEX sessionTriggeredBOOL is below')
            // console.log(sessionTriggeredBOOL)
            commit(
                types.SET_DIALOG_MEDAPPT_LIST_SESSION_TRIGGERED_BOOL,
                sessionTriggeredBOOL
            )
        },
        // actionGetDM_Unread_Badge_Value({ commit }, sbUserObject) {
        // actionSetUserFromSB({ state, commit }, sbUserObject) {
        // console.log('!!!!!!!!!!!! STEP# 7A VUEX home.js actionSetUser triggered')
        // console.log('!!!!!!!!!!!! STEP# 7B VUEX home.js sbUserObject is below')
        // console.log(sbUserObject)
        // commit(types.SET_DM_UNREAD_BADGE_VALUE, sbUserObject)
        // console.log('!!!! STEP# 7C VUEX home.js sbUserObject committed to Vuex USER')
        // commit(types.SET_VALID_ACCESS_TOKEN, true)
        // },
        actionSetUserFromSB({ commit }, sbUserObject) {
            // console.log('!!!!!!!!!!!! STEP# 7A VUEX home.js actionSetUser triggered')
            // console.log('!!!!!!!!!!!! STEP# 7B VUEX home.js sbUserObject is below')
            // console.log(sbUserObject)
            commit(types.SET_USER, sbUserObject)
            // console.log('!!!! STEP# 7C VUEX home.js sbUserObject committed to Vuex USER')
            commit(types.SET_VALID_ACCESS_TOKEN, true)
        },

        actionSetDM_UnreadBadgeValue({ commit }, unReadBadgeOBJ) {
            // console.log('!!!! STEP# 7A VUEX home.js actionSetDM_UnreadBadgeValue triggered')
            // console.log('!!!! STEP# 7B VUEX home.js unReadBadgeOBJ is below')
            // console.log(unReadBadgeOBJ)
            const DM_UnreadBadgeValue = unReadBadgeOBJ
            // console.log('!!!! STEP# 7C VUEX home.js DM_UnreadBadgeValue is below')
            // console.log(DM_UnreadBadgeValue)
            commit(types.SET_DM_UNREAD_BADGE_VALUE, DM_UnreadBadgeValue)
            // console.log('!!!! STEP# 7D VUEX home.js unReadBadgeOBJ committed')
        },

        actionSet_SessionFlagHelloBOOL({ commit }, sessionFlagHello) {
            commit(types.SET_FLAG_SESSION_HELLO_BOOL, sessionFlagHello)
        },

        async actionGetHDDM_UnreadBadgeValue23({ state, commit }) {
            const params = { member_vID: state.memberProfile.Member_vID }
            // console.log('STORE HOME.js params = Member_vID is below')
            // console.log(params)
            const response23 = await api.getHDDM_UnreadBadge23(params)
            // console.log('STORE HOME.js response23 is below')
            // console.log(response23)
            const HDDM_UnreadBadgeValue = response23.data
            // console.log('STORE HOME.js response23 is below')
            // console.log(response23.data)

            // console.log('STORE HOME.js response23 HDDM_UnreadBadgeValue is below')
            // console.log(HDDM_UnreadBadgeValue)
            commit(types.SET_DM_UNREAD_BADGE_VALUE, HDDM_UnreadBadgeValue)
        },

        async actionGetHelpDesk_UnreadBadgeValue21({ commit }) {
            // console.log('STORE HOME.js actionGetHelpDesk_UnreadBadgeValue21')
            const response21 = await api.getHelpDesk_UnreadBadge21()
            if (response21.status === 200) {
                // console.log('STORE HOME.js response21.status is below')
                // console.log(response21.status)
                const HelpDesk_UnreadBadgeValue = response21.data
                // console.log('STORE HOME.js response21.data is below')
                // console.log(response21.data)

                commit(
                    types.SET_HELPDESK_UNREAD_BADGE_VALUE,
                    HelpDesk_UnreadBadgeValue
                )
            }
        },
        // eslint-disable-next-line no-unused-vars
        // async actionUpdateHDDM_StatusReadToTrue37({ commit }, params) {

        async actionUpdateHDDM_StatusReadToTrue37({ commit }, updateHDDM) {
            // params =  { DM_vID: targetDM_vID }
            // console.log('STEP #222A from STORE HOME.js updateHDDM is below')
            // console.log( updateHDDM )
            const targetHDDM = updateHDDM.hddm_vid
            // console.log('STEP #222B from STORE HOME.js hddm_vid is below')
            // console.log( targetHDDM )
            commit(types.SET_SELECTED_HDDM_FOR_READ_STATUS, targetHDDM)

            try {
                const response37 = await api.updateHDDM_StatusReadToTrue37(
                    updateHDDM
                )
                // console.log('STEP #222C from STORE HOME.js response37.status is below')
                // console.log(response37.status)
                if (response37.status == 200) {
                    // console.log('STEP #222D from STORE HOME.js response37.data is below')
                    // console.log(response37.data[0])
                    // const response37Error = response37Data.error
                    // console.log('STEP #222D from STORE HOME.js response37Error is below')
                    // console.log(response37Error)
                    // console.log('STEP #222D from STORE HOME.js response37DataResults is below')
                    // console.log(response37DataResults)
                    // const response37TargetDM_StatusRead =
                    // response37TargetDMObject.attributes.statusRead
                    // console.log('STEP #222G from STORE HOME.js response37TargetDM_StatusRead is below')
                    // console.log(response37TargetDM_StatusRead)

                    // const targetDMObject = {
                    //     targetDM_vID: response37TargetDM_vID,
                    //     targetDM_StatusRead: response37TargetDM_StatusRead,
                    //     response37Error: response37Error,
                    // }
                    // dispatch('actionGetHDDM38')

                    return response37.data[0]
                    // return targetDMObject
                } else {
                    alert('Unfortunately there was a server error')
                }
            } catch (err) {
                alert('Apologies but the server is not currently online, please send a message to the Help Desk')
                const status = err.response ? err.response.status : 999
                return status
            }
        },

        /*         async actionGetHDDM38({ state }, ) {
            const paramsActionGetHDDM38 = {"member_vID" : state.memberProfile.Member_vID}
            try {
                let response38 = await api.getHDDM_MemberInbox91(paramsActionGetHDDM38)
                let response38Data = response38.data
                                                                                    // console.log ('VUEX home.js response38Data is below')
                                                                                    // console.log(response38Data)
                return response38Data
            } catch {
                const status = 999
                return status
            }
        
        
        }, */

        async actionSetTGMemberProfile({ state, commit }) {
            const userEmailSupabase = state.user.email
            // console.log ('!!!! STEP# 9A VUEX home.js actionGetMemberProfile state.user.email is below')
            // console.log (userEmailSupabase)
            const params = { LEAP_ID: userEmailSupabase }
            // console.log ('!!!! STEP# 9B VUEX home.js actionGetMemberProfile params is below')
            // console.log (params)
            try {
                const TGR = await api.getTGMemberProfile(params)
                // console.log ('!!!! STEP# 14A VUEX home.js actionGetMemberProfile response TGR.data is below')
                // console.log (TGR.data)
                // console.log ('!!!! STEP# 14B VUEX home.js actionGetMemberProfile response TGR.data.error is below')
                // console.log (TGR.data.error)
                if (TGR.data.error === false) {
                    const TGMemberProfileData =
                        TGR['data']['results'][0]['MemberProfile'][0][
                        'attributes'
                        ]
                    // console.log ('!!!!!!!!!!!! STEP# 14C VUEX home.js actionGetMemberProfile TGMemberProfileData is below')
                    // console.log (TGMemberProfileData)

                    // console.log ('STEP #890 getTGMemberProfile memberProfile.attributes  is below')
                    // console.log (  TGRdata.MemberProfile )

                    // console.log(TGR.data.results[0].MemberProfile[0])
                    // console.log(TGR.data.results[0].MemberProfile[0]["attributes"].LEAP_ID)
                    // console.log(TGR.data.results[0].MemberProfile[0]["attributes"])
                    // const MemberProfileObject = TGR["data"]["results"][0]["MemberProfile"][0]["attributes"]
                    // console.log('MemberProfileObject is below  -- TGR["data"]["results"][0]["MemberProfile"][0]["attributes"')
                    // console.log(MemberProfileObject)
                    // console.log(TGR.data.results.MemberProfile[0]["v_type"])
                    // console.log(resJSON.results.MemberProfile[0]["attributes"])
                    commit(types.SET_MEMBER_PROFILE, TGMemberProfileData)
                    // console.log('!!!!!!!!!!!! STEP# 14D VUEX home.js actionGetMemberProfile TGMemberProfileData  commmitted')
                } else {
                    alert(
                        'The data server is not currently available please send the Help Desk a message to let them know'
                    )
                    //console.log ('error from home.js getTGMember_Profile')
                }
            } catch (err) {
                alert(
                    'The data server is not currently available please send the Help Desk a message to let them know'
                )
                const status = err.response ? err.response.status : 401
                return status
            }
        },

        async actionSetTGMemberProfileItems15({ state, commit }) {
            // console.log ('STEP #15A VUEX HOME.js actionSetTGMemberProfileItems15')
            const memberVertexID = state.memberProfile.Member_vID
            // console.log ('STEP #15B VUEX HOME.js actionSetTGMemberProfileItems15 memberVertexID is below')
            // console.log(memberVertexID)

            const params = { Member_vID: memberVertexID }
            // console.log ('!!!! STEP# 19C VUEX HOME.js params is below')
            // console.log (params)
            try {
                const TGMPI = await api.getTGMemberProfileItems15(params)
                // console.log ('!!!! STEP# 24A VUEX HOME.js TGMPI.data is below')
                // console.log (TGMPI)
                // console.log ('STEP #15C VUEX HOME.js actionSetTGMemberProfileItems15 TGMPI.data.error is below')
                // console.log (TGMPI.data.error)
                if (TGMPI.data.error === false) {
                    let profileItemsOBJ = TGMPI.data.results[0];
                    // console.log('STEP #15D VUEX HOME.j actionSetTGMemberProfileItems15 profileItemsOBJ is below')
                    // console.log(profileItemsOBJ)
                    profileItemsOBJ = profileItemsOBJ.profileItems_NonSYM;
                    profileItemsOBJ = profileItemsOBJ[0];
                    // console.log('STEP #15E VUEX HOME.j actionSetTGMemberProfileItems15 profileItemsOBJ is below')
                    // console.log(profileItemsOBJ)
                    let FakeBirthdayString = profileItemsOBJ.attributes.item
                    // console.log('STEP #15F VUEX HOME.j actionSetTGMemberProfileItems15 FakeBirthdayString is below')
                    // console.log(FakeBirthdayString)
                    let memberProfileItemsARR = [{ item: FakeBirthdayString }];


                    commit(types.SET_MEMBER_PROFILE_ITEMS, memberProfileItemsARR)
                    // console.log('STEP #15F VUEX home.js actionSetTGMemberProfileItems15 profileItemsNonSymARR commmitted')
                } else {
                    alert(
                        'The data server is not currently available please send the Help Desk a message to let them know'
                    )
                    //console.log ('error from home.js getTGMember_Profile')
                }
            } catch (err) {
                alert(
                    'The data server is not currently available please send the Help Desk a message to let them know'
                )
                const status = err.response ? err.response.status : 401
                return status
            }
        },

        async actionSetMemberDrugDisplayFromTG({ state, commit }) {
            // console.log ('!!!! STEP# 99A VUEX HOME.js actionSetMemberDrugDisplayFromTG ')
            const memberVertexID = state.memberProfile.Member_vID
            // console.log ('!!!! STEP# 99B VUEX HOME.js actionSetMemberDrugDisplayFromTG memberVertexID is below')
            // console.log(memberVertexID)
            const params = { Member_vID: memberVertexID }
            // console.log ('!!!! STEP# 99C VUEX HOME.js actionSetMemberDrugDisplayFromTG params is below')
            // console.log (params)
            try {
                const TGDD = await api.getTGProfileDrugDisplay(params)
                // console.log ('!!!! STEP# 99D VUEX HOME.js actionSetMemberDrugDisplayFromTG TGDD is below')
                // console.log (TGDD)
                // console.log ('!!!! STEP# 24B VUEX HOME.js actionSetMemberDrugDisplayFromTG TGDD.TGerror is below')
                // console.log (TGDD.data.TGerror)
                if (TGDD.data.TGerror === false) {
                    const memberTodayDrugDisplayArray =
                        TGDD.data.memberTodayDrugDisplay
                    // console.log ('!!!! STEP# 99E VUEX HOME.js actionSetMemberDrugDisplayFromTG memberTodayDrugDisplayArray is below')
                    // console.log (memberTodayDrugDisplayArray)

                    /*  const drugDisplayOuterLoop = getters.getterMemberProfile_zDrugArray.map( element => {
                                                                                const outerBucketOBJ = {};
                                                                                const innerBucketOBJ = {};
                                                                                const adminChips = [];
                                                                                console.log('*********************************                OUTER LOOP element.memProfileItem is below')
                                                                                console.log(element.memProfileItem)
                                                                                memberDrugDisplayRawData.forEach(item => {
                                                                                console.log('$$$$$$$$$$$$$$$$$$$$$$$$$$$$ INNER LOOP item.zDrug_Codebook_vID is below')
                                                                                console.log(item.zDrug_Codebook_vID)
                                                                                if (element.memProfileItem == item.zDrug_Codebook_vID ) {
                                                                                console.log('CCC item.zDrug_Codebook_vID is below')
                                                                                console.log(item.zDrug_Codebook_vID)
                                                                                innerBucketOBJ.drugAdminCode_long = item.drugAdminCode_long;
                                                                                console.log('item.drugAdminCode_long is below')
                                                                                console.log(item.drugAdminCode_long)
                                                                                console.log('innerBucketOBJ.drugAdminCode_long is below')
                                                                                console.log(innerBucketOBJ.drugAdminCode_long)

                                                                                innerBucketOBJ.drugAdminCode_short = item.drugAdminCode_short;
                                                                                console.log( 'item.drugAdminCode_short is below')
                                                                                console.log( item.drugAdminCode_short)
                                                                                console.log('innerBucketOBJ.drugAdminCode_short is below')
                                                                                console.log(innerBucketOBJ.drugAdminCode_short)

                                                                                innerBucketOBJ.drugAdmin_Codebook_vID = item.drugAdmin_Codebook_vID;
                                                                                console.log( 'innerBucketOBJ.drugAdmin_Codebook_vID is below')
                                                                                console.log(  innerBucketOBJ.drugAdmin_Codebook_vID )


                                                                                console.log( 'innerBucketOBJ.drugAdminCode_long is below')
                                                                                console.log( innerBucketOBJ.drugAdminCode_long )
                                                                                adminChips.push(innerBucketOBJ)
                                                                                console.log( '++++++++++++ adminChips[0][drugAdminCode_short] array is below')
                                                                                console.log( adminChips[0]['drugAdminCode_short'] )
                                                                                console.log( '++++++++++++ adminChips array LENGTH is below')
                                                                                console.log( adminChips.length )                                                 
                                                                                }
                                                                                console.log( 'LAST LOG BEFORE LEAVING INNER LOOP')             
                                                                                })   

                                                                                console.log( 'BBB             adminChips[0][drugAdminCode_short] array is below')
                                                                                console.log( adminChips[0]['drugAdminCode_short'] )
                                                                                console.log('element.zDrug_Codebook_vID is below')
                                                                                console.log( element.zDrug_Codebook_vID ) */

                    // outerBucketOBJ.zDrug_Codebook_vID = item.zDrug_Codebook_vID;

                    // console.log('outerBucketOBJ.zDrug_Codebook_vID  is below')
                    // console.log( outerBucketOBJ.zDrug_Codebook_vID  )
                    // outerBucketOBJ.drugName_long = item.drugName_long;
                    // outerBucketOBJ.drugName_short = item.drugName_short;
                    // console.log('outerBucketOBJ.drugName_short is below')
                    // console.log( outerBucketOBJ.drugName_short  )
                    /*                       outerBucketOBJ.adminOptions = adminChips;
                                                                                                                                                                        console.log( 'ZZZZZZZZZZZZ   outerBucketOBJ.adminOptions is below')
                                                                                                                                                                        console.log(  outerBucketOBJ.adminOptions )
                                                                                                                                                                        console.log( 'LAST LOG BEFORE EXITING OUTER LOOP')
                                                                                                    
                                                                                                        return outerBucketOBJ */
                    commit(
                        types.SET_MEMBER_TODAY_DRUG_DISPLAY,
                        memberTodayDrugDisplayArray
                    )
                    // console.log('!!!! STEP# 99F VUEX home.js actionSetMemberDrugDisplayFromTG drugDisplayOuterLoop commmitted')
                } else {
                    alert(
                        'The data server is not currently available please send the Help Desk a message to let them know'
                    )
                    //console.log ('error from home.js getTGMember_Profile')
                }
            } catch (err) {
                alert(
                    'The data server is not currently available please send the Help Desk a message to let them know'
                )
                const status = err.response ? err.response.status : 401
                return status
            }
        },

        /*         actionSetMemberTodayDrugArrayWithAdminChoices({ state, commit, getters }) {  
                                                                                                                                                            // console.log ('!!!! STEP# 19B VUEX HOME.js actionSetMemberProfileDrugDisplayFromTG memberVertexID is below')
                                                                                                                                                            // console.log(memberVertexID)
                                                                                const drugArrayOuterLoop = getters.getterMemberProfile_zDrugArray.map((element) => {
                                                                                    const bucket = {};                                        
                                                                                        (this.memberTodayDrugDisplay).forEach((item) => {
                                                                                                if (element.memProfileItem ==  item.zDrug_Codebook_vID ) {
                                                                                                    tempChipsArray.push(item.drugAdminCode_short)
                                                                                                }       
                                                                                        })
                                                                                        
                                                                                        bucket.zDrug_Codebook_vID = element.memProfileItem;
                                                                                        bucket.adminChips = tempChipsArray;
                                                                                    
                                                                                    return bucket                

                                                                                    return drugArrayWithAdminChips




                                                                                    
                                                                                                                                                            // console.log ('!!!! STEP# 19C VUEX HOME.js actionSetMemberProfileDrugDisplayFromTG params is below')
                                                                                                                                                            // console.log (params)

                                                                                                                                                            // console.log ('!!!! STEP# 24A VUEX HOME.js actionSetMemberProfileDrugDisplayFromTG TGDD is below')
                                                                                                                                                            // console.log (TGDD)
                                                                                                                                                            // console.log ('!!!! STEP# 24B VUEX HOME.js actionSetMemberProfileDrugDisplayFromTG TGDD.TGerror is below')
                                                                                                                                                            // console.log (TGDD.data.TGerror)
                                                                                
                                                 targetTGMemberProfileData                               

                                                                                
                                                                                    commit(types.SET_MEMBER_TODAY_DRUG_DISPLAY, TGMemberProfileDrugDisplay)
                                                                                                                                                            // console.log('!!!! STEP# 24D VUEX home.js actionSetMemberProfileDrugDisplayFromTG TGMemberProfileDrugDisplay commmitted')
                                                                                
                                                                                
                                                                                
                                                                                })   
                                                                            } */
    
        async actionSetTargetTGMemberProfile({ commit }, targetMember_LEAP_ID) {
                                                                                    // console.log('STEP# 9A VUEX home.js actionSetTargetTGMemberProfile targetMember_LEAP_ID is below')
                                                                                    // console.log(targetMember_LEAP_ID)
            const params = { LEAP_ID: targetMember_LEAP_ID }
                                                                                    // console.log('STEP# 9B VUEX home.js actionSetTargetTGMemberProfile params is below')
                                                                                    // console.log(params)
            try {
                const AGR = await api.getTGMemberProfile(params)
                                                                                    // console.log('STEP# 14A VUEX home.js actionSetTargetTGMemberProfile AGR.data is below')
                                                                                    // console.log(AGR.data)
                                                                                    // console.log('STEP# 14B VUEX home.js actionSetTargetTGMemberProfile AGR.data.error is below')
                                                                                    // console.log(AGR.data.error)
                if (AGR.data.error === false) {
                    const targetTGMemberProfileData =
                        AGR['data']['results'][0]['MemberProfile'][0]['attributes']
                                                                                    // console.log('STEP# 14C VUEX home.js actionSetTargetTGMemberProfile targetTGMemberProfileData is below')
                                                                                    // console.log(targetTGMemberProfileData)
                                                                                    // console.log(TGR.data.results[0].MemberProfile[0])
                                                                                    // console.log(TGR.data.results[0].MemberProfile[0]["attributes"].LEAP_ID)
                                                                                    // console.log(TGR.data.results[0].MemberProfile[0]["attributes"])
                                                                                    // const MemberProfileObject = TGR["data"]["results"][0]["MemberProfile"][0]["attributes"]
                                                                                    // console.log('MemberProfileObject is below  -- TGR["data"]["results"][0]["MemberProfile"][0]["attributes"')
                                                                                    // console.log(MemberProfileObject)
                                                                                    // console.log(TGR.data.results.MemberProfile[0]["v_type"])
                                                                                    // console.log(resJSON.results.MemberProfile[0]["attributes"])
                    commit(types.SET_TARGET_MEMBER_PROFILE, targetTGMemberProfileData)
                                                                                    // console.log(' STEP# 14D VUEX home.js actionGetMemberProfile TGMemberProfileData  commmitted')
                } else {
                    alert(
                        'The data server is not currently available please send the Help Desk a message to let them know'
                    )
                                                                                    console.log ('error from home.js getTGMember_Profile')
                }
            } catch (err) {
                alert(
                    'The data server is not currently available please send the Help Desk a message to let them know'
                )
                const status = err.response ? err.response.status : 401
                return status
            }
        },
        async actionSetTargetTGMemberProfileItems15({ state, commit }) {
                                                                                    // console.log ('STEP #15A VUEX HOME.js actionSetTGMemberProfileItems15')
            const targetMemberVertexID = state.targetMemberProfile.Member_vID
                                                                                    // console.log ('STEP #15B VUEX HOME.js actionSetTGMemberProfileItems15 memberVertexID is below')
                                                                                    // console.log(memberVertexID)

            const params = { Member_vID: targetMemberVertexID }
                                                                                    // console.log ('STEP# 19C VUEX HOME.js params is below')
                                                                                    // console.log (params)
            try {
                const TGMPI = await api.getTGMemberProfileItems15(params)
                                                                                    // console.log ('!!!! STEP# 24A VUEX HOME.js TGMPI.data is below')
                                                                                    // console.log (TGMPI)
                                                                                    // console.log ('STEP #15C VUEX HOME.js actionSetTGMemberProfileItems15 TGMPI.data.error is below')
                                                                                    // console.log (TGMPI.data.error)
                if (TGMPI.data.error === false) {
                    let profileItemsOBJ = TGMPI.data.results[0];
                                                                                    // console.log('STEP #15D VUEX HOME.j actionSetTGMemberProfileItems15 profileItemsOBJ is below')
                                                                                    // console.log(profileItemsOBJ)
                    profileItemsOBJ = profileItemsOBJ.profileItems_NonSYM;
                    profileItemsOBJ = profileItemsOBJ[0];
                                                                                    // console.log('STEP #15E VUEX HOME.j actionSetTGMemberProfileItems15 profileItemsOBJ is below')
                                                                                    // console.log(profileItemsOBJ)
                    let FakeBirthdayString = profileItemsOBJ.attributes.item
                                                                                    // console.log('STEP #15F VUEX HOME.j actionSetTGMemberProfileItems15 FakeBirthdayString is below')
                                                                                    // console.log(FakeBirthdayString)
                    let memberProfileItemsARR = [{ item: FakeBirthdayString }];


                    commit(types.SET_MEMBER_PROFILE_ITEMS, memberProfileItemsARR)
                                                                                    // console.log('STEP #15F VUEX home.js actionSetTGMemberProfileItems15 profileItemsNonSymARR commmitted')
                } else {
                    alert(
                        'The data server is not currently available please send the Help Desk a message to let them know'
                    )
                                                                                    //console.log ('error from home.js getTGMember_Profile')
                }
            } catch (err) {
                alert(
                    'The data server is not currently available please send the Help Desk a message to let them know'
                )
                const status = err.response ? err.response.status : 401
                return status
            }
        },
    }
}
