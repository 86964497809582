// import * as home from './home';
// import * as calendar from './calendar';
// eslint-disable-next-line no-unused-vars
// import { reject } from 'core-js/fn/promise'
import api from '../../api'
// import { DateTime } from 'luxon'
const { DateTime } = require('luxon')

export const types = {
    RESET: 'RESET',
    SET_APPT_ARRAY: 'SET_APPT_ARRAY',
    SET_APPT_DOTSOBJECT: 'SET_APPT_DOTSOBJECT',
    SET_HCP_OBJ: 'SET_HCP_OBJ',
}

const initState = () => ({
    apptArray: [],
    apptDotsObject: {},
    values21: {},
    HCP_obj: {},
})

const resetState = () => ({
    apptArray: null,
    apptDotsObject: {},
    HCP_obj: {},
})

export const module = {
    namespaced: true,
    state: initState(),

    mutations: {
        [types.SET_APPT_ARRAY](state, values21) {
            // console.log('inside the SET_APPT_ARRAY mutation')
            state.apptArray = values21
        },
        [types.SET_APPT_DOTSOBJECT](state, values4) {
            // console.log('inside the SET_APPT_DOTSOBJECT mutation')
            state.apptDotsObject = values4
        },
        [types.SET_HCP_OBJ](state, values66) {
            // console.log('inside the SET_HCP_OBJ mutation')
            state.HCP_obj = values66
        },
        [types.SET_LOADING](state, value) {
            state.loading = value
        },
        [types.ADD_ERROR](state, error) {
            state.errors.push(error)
            state.errorCount += 1
        },
        [types.REMOVE_ERROR](state, id) {
            state.errors = state.errors.filter((e) => e.id !== id)
        },
        [types.RESET](state) {
            const s = resetState()
            Object.keys(s).forEach((key) => {
                state[key] = s[key]
            })
        },
    },

    actions: {
        // actionSetHCP62 takes Supabase HCP record and inserts the data into TigerGraph HCP vertex and edge treated_by
        async actionSetHCP62({ commit }, postTG_HCP_OBJ) {
            // console.log('VUEX STEP #100 OUTSIDE TRY from appoint.js postTG_HCP_OBJ is below')
            // console.log(postTG_HCP_OBJ)
            // console.log('VUEX STEP #101 appoint.js actionSetHCP62')
            try {
                const nodeServerResponse62 = await api.postTGHCP_obj62(
                    postTG_HCP_OBJ
                )
                // console.log('VUEX STEP #102 appoint.js actionSetHCP62 nodeServerResponse62 is below')
                // console.log(nodeServerResponse62)
                // console.log('VUEX STEP #103 appoint.js actionSetHCP62')
                // console.log('nodeServerResponse62.status === 200')
                if (nodeServerResponse62.data.error === false) {
                    const HCPOBJ = nodeServerResponse62.data.results[0]
                    // console.log('VUEX STEP #104 HCP_OBJ is below')
                    // console.log(HCPOBJ)
                    commit(types.SET_HCP_OBJ, HCPOBJ)
                } else {
                    alert('ERROR nodeServerResponse62')
                    // console.log('code execution error from actionSetHCP62')
                    // console.log(err)
                    // const status = err.response ? err.response.status : 999;
                    // alert('ERROR actionSetHCP62')
                    // console.log(status)
                }
            } catch (err) {
                console.log('error actionSetHCP62')
                console.log(err)
            }
        },

        async actionDeleteMedAppt79({ dispatch }, Med_Appt_vID) {
            // { commit, state, rootState, dispatch },
            // { dispatch }, Med_Appt_vID
            // ) {
            // console.log('STEP #111 OUTSIDE TRY from appoint.js actionDeleteMedAppt79')
            const params = { Med_Appt_vID: Med_Appt_vID }
            // console.log(params)
            // console.log('STEP #10 VUEX appoint.js actionDeleteMedAppt')
            try {
                const nodeServerResponse79 = await api.deleteTGMedAppt79(params)
                // console.log('STEP #11 VUEX appoint.js actionDeleteMedAppt')
                if (nodeServerResponse79.status === 200) {
                    await dispatch('actionGetMedAppt77')
                }
            } catch (err) {
                console.log('code actionDeleteMedAppt79')
                console.log(err)
            }
        },

        async actionGetMedAppt77({ commit, rootState }) {
            // console.log('STEP #22 VUEX appoint.js actionGetAppointment77 below is rootState.home.memberProfile.Member_vID')
            // console.log(rootState.home.memberProfile.Member_vID)
            const params = {
                Member_vID: rootState.home.memberProfile.Member_vID,
            }
            // console.log(params)
            try {
                // console.log('STEP #23 VUEX appoint.js actionGetAppointment77')
                const response77Data = await api.getTGMedAppt77(params)

                let response77Results = response77Data.data.results[0]
                // console.log('STEP #24 actionGetAppointment77 response77Results is below')
                // console.log(response77Results)

                let appointmentsArray = Object.values(response77Results)[0]
                // console.log('STEP #25 actionGetAppointment77 appointmentsArray is below')
                // console.log(appointmentsArray)

                appointmentsArray = appointmentsArray.sort((a, b) =>
                    a.ApptMilliseconds > b.ApptMilliseconds ? 1 : -1
                )

                commit(types.SET_APPT_ARRAY, appointmentsArray)

                const arrayToObject = (array, keyField) =>
                    array.reduce((obj, item) => {
                        let P = DateTime.fromMillis(item[keyField]).toFormat(
                            'yyyy-MM-dd'
                        )
                        obj[P] = { value: item.ApptType }
                        return obj
                    }, {})

                const appointmentsObject = arrayToObject(
                    appointmentsArray,
                    'ApptMilliseconds'
                )
                commit(types.SET_APPT_DOTSOBJECT, appointmentsObject)
            } catch (err) {
                console.log('error actionGetMedAppt77')
                console.log(err)
            }
        },
    },
}
