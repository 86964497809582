export default [
    {
        text: 'Medical Visits',
        value: {
            dataType: 'appointment',
            colorMap: {},
            defaultFilter: null,
        },
    },

    {
        text: 'Symptoms',
        value: {
            dataType: 'symptom',
            colorMap: {
                0: '#37c871',
                1: '#00f3fa',
                2: '#2e8fff',
                3: '#fbf70b',
                4: '#FF6D00',
                5: '#D50000',
            },
            defaultFilter: {
                code: 'symMS_1020',
                severity: 0,
                consecutive: 0,
            },
            filterMap: {
                code: (code, state) => {
                    const symptom = state.symptoms.find((s) => s.code === code)
                    return symptom ? symptom.short : 'Unknown Symptom'
                },
                severity: (severity) => `&ge; ${severity} Severity`,
                consecutive: (consecutive) =>
                    `&ge; ${consecutive} Consecutive Days`,
            },
        },
    },

    {
        text: 'Oral Medications',
        value: {
            dataType: 'medication',
            colorMap: {
                0: '#D50000',
                1: '#37c871',
                2: '#00f3fa',
                3: '#2e8fff',
                '-1': '#BDBDBD',
            },
            keys: ['Miss', '1', '2', '3', 'NS'],
            defaultFilter: {
                code: '',
            },
            filterMap: {
                code: (code, state) => {
                    const medication = state.medicationCodes[code]
                    return medication || 'Unknown Medication'
                },
            },
        },
    },

    {
        text: 'Injectable Medications',
        value: {
            dataType: 'injectable',
            colorMap: {
                1: '#37c871',
                2: '#2e8fff',
                3: '#f2fa00',
                4: '#f95',
                5: '#f55',
            },
            defaultFilter: null,
        },
    },

    {
        text: 'Stress Factors',
        value: {
            dataType: 'stress',
            colorMap: {
                0: '#37c871',
                1: '#00f3fa',
                2: '#2e8fff',
                3: '#f2fa00',
                4: '#f95',
                5: '#f55',
            },
            defaultFilter: {
                code: '1',
                severity: 0,
                consecutive: 0,
            },
            filterMap: {
                code: (code, state) => {
                    const factor = state.stressFactors.find(
                        (f) => f.code === code
                    )
                    return factor ? factor.text : 'Unknown Stress Factor'
                },
                severity: (severity) => `&ge; ${severity} Severity`,
                consecutive: (consecutive) =>
                    `&ge; ${consecutive} Consecutive Days`,
            },
        },
    },

    {
        text: 'Self Care',
        value: {
            dataType: 'care',
            keys: ['Active'],
            colorMap: {
                true: '#37c871',
            },
            defaultFilter: {
                code: '-1',
            },
            filterMap: {
                code: (code, state) => {
                    const activity = state.selfCareCodes.find(
                        (val) => val.code === code
                    )
                    return activity ? activity.text : 'Unknown Activity'
                },
            },
        },
    },

    {
        text: 'Vitamins',
        value: {
            dataType: 'vitamin',
            colorMap: {},
            defaultFilter: null,
        },
    },

    {
        text: 'CareCredit',
        value: {
            dataType: 'ofx',
            keys: ['Transaction'],
            colorMap: {
                true: '#37c871',
            },
            defaultFilter: {
                type: 'all',
            },
            filterMap: {
                type: (type, state) => {
                    const transaction = state.careCreditTypes.find(
                        (val) => val.name === type
                    )
                    return transaction
                        ? `${transaction.text} Transactions`
                        : 'Unknown Transaction'
                },
            },
        },
    },

    {
        text: 'Med_Appt',
        value: {
            dataType: 'med_appt',
            colorMap: {
                mda_1030: '#37c871',
                mda_1040: '#00f3fa',
                mda_1050: '#2e8fff',
                mda_1070: '#f2fa00',
                mda_1080: '#FF6D00',
                mda_1090: '#D50000',
            },
            defaultFilter: {
                code: 'mda_1030',
                type: 'clinical visit',
                consecutive: 0,
            },
            filterMap: {
                code: (code, state) => {
                    const symptom = state.symptoms.find((s) => s.code === code)
                    return symptom ? symptom.short : 'Unknown Symptom'
                },
                severity: (severity) => `&ge; ${severity} Severity`,
                consecutive: (consecutive) =>
                    `&ge; ${consecutive} Consecutive Days`,
            },
        },
    },
]
