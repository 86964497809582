<template>
    <v-card flat color="transparent" pa-1 ma-1>
        <v-btn
            x-small
            fab
            dark
            depressed
            color="grey darken-1"
            @click.stop="decrementStartMonth"
        >
            <v-icon x-small>fa-chevron-left</v-icon>
        </v-btn>
        <v-dialog
            ref="dialog"
            v-model="modal"
            overlay-color="grey"
            width="290px"
            persistent
        >
            <v-card color="blue-rounded-card">
                <v-card-title class="title black--text justify-center">
                    Select Start Month And Year
                </v-card-title>
                <v-card-text class="pa-0">
                    <v-date-picker
                        v-model="pickerTimeFrameStartMonthISODate"
                        type="month"
                        show-current="true"
                        header-color="blue darken-1"
                        color="blue darken-1"
                        no-title="false"
                    >
                        <v-spacer></v-spacer>
                        <v-btn
                            dark
                            depressed
                            color="pink darken-2"
                            @click="modal = false"
                            >Cancel</v-btn
                        >
                        <v-btn
                            dark
                            depressed
                            color="green darken-3"
                            @click="onSaveModal()"
                            >OK</v-btn
                        >
                    </v-date-picker>
                </v-card-text>
            </v-card>
        </v-dialog>
        <span class="calendar-switcher" @click="onOpenModal()">
            <!-- Switch &nbsp; -->
            <v-avatar size="32" color="grey darken-1">
                <v-icon small color="white">mdi-repeat</v-icon>
            </v-avatar>

            <!-- &nbsp; Year -->
        </span>
        <v-btn
            x-small
            fab
            dark
            depressed
            color="grey darken-1"
            @click.stop="incrementStartMonth"
        >
            <v-icon x-small>fa-chevron-right</v-icon>
        </v-btn>
    </v-card>
</template>

<script>
import { DateTime } from 'luxon'
export default {
    name: 'CalendarSwitch',

    props: {
        getterTimeFrameStartMonthLuxonObject: { type: Object, required: true },
        calendarDisplayConfig: {
            type: Object,
            required: false,
            default() {
                return {}
            },
        },
        showCalendarsBool: { type: Boolean, required: false },
    },
    data() {
        return {
            date: null,
            pickerTimeFrameStartMonthISODate:
                this.getterTimeFrameStartMonthLuxonObject.toISODate(),
            modal: false,
        }
    },

    computed: {
        pickerTimeFrameStartMonthLuxonObject() {
            return DateTime.fromISO(this.pickerTimeFrameStartMonthISODate)
        },
        calendarSwitchTimeFrameStartMonthDisplay() {
            return this.getterTimeFrameStartMonthLuxonObject.toFormat(
                'LLL-yyyy'
            )
        },

        pickerMonthsShift() {
            const currentStartMonthObject =
                this.getterTimeFrameStartMonthLuxonObject
            const pickerStartMonthObject =
                this.pickerTimeFrameStartMonthLuxonObject
            const shiftMonthsCount = pickerStartMonthObject.diff(
                currentStartMonthObject,
                'months'
            )
            if (!this.showCalendarsBool) {
                // console.log('shiftMonthsCount.values.months is below')
                // console.log(shiftMonthsCount.values.months)
                // console.log('this.calendarDisplayConfig.hidden is below')
                // console.log(this.calendarDisplayConfig.hidden)
                return (
                    shiftMonthsCount.values.months -
                    this.calendarDisplayConfig.hidden
                )
            } else {
                return shiftMonthsCount.values.months
            }
        },
    },

    methods: {
        onOpenModal() {
            this.modal = true
        },
        onSaveModal() {
            this.changeStartMonth()
            this.modal = false
        },
        decrementStartMonth() {
            this.$emit('shiftStartMonth', -1)
        },
        incrementStartMonth() {
            this.$emit('shiftStartMonth', 1)
        },
        changeStartMonth() {
            // changeStartMonth(shiftMonthsCount) {
            this.$emit('shiftStartMonth', this.pickerMonthsShift)
        },
    },
}
</script>

<style lang="scss" scoped>
.calendar-switcher {
    margin-top: 2em;

    font-size: 1em;
    text-align: center;
    // text-decoration: underline;
    text-transform: capitalize;
    font-weight: bold;
    color: #ffffff;
}
</style>
