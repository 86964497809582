<template>
    <div class="view-home-canvas">
        <NavDrawer :mobile-display-bool="mobileDisplay"> </NavDrawer>
        <AppBar :mobile-display-bool="mobileDisplay"> </AppBar>
        <!--         <v-progress-linear
            v-if="loading"
            class="ma-0"
            :indeterminate="true"
            color="primary"
        >
        </v-progress-linear>
        <div v-if="loading" class="disable-content"></div> -->

        <v-main>
            <!--             <v-container fluid :class="{ errors: true, 'pa-0': !hasErrors }">
                                                                                <v-row align-content-center justify-center>
                                                                                <v-col v-for="error of errors" :key="`error-${error.id}`">
                                                                                <v-alert :value="true" type="error">
                                                                                {{ error.message }}>
                                                                                </v-alert>
                                                                                </v-col>
                                                                                 </v-row>
                                                                                </v-container> -->
            <DialogMedApptList> </DialogMedApptList>

            <v-container fluid class="v-main-container">
                <keep-alive>
                    <!-- <router-view :key="$route.fullPath">  -->
                    <router-view> </router-view>
                </keep-alive>
            </v-container>
        
        </v-main>
        <FooterBar> </FooterBar>
    </div>
</template>

<script>
import NavDrawer from '@/components/NavDrawer.vue'
import AppBar from '@/components/AppBar.vue'
import FooterBar from '@/components/FooterBar.vue'
import DialogMedApptList from '@/components/DialogMedApptList.vue'
import { mapState } from 'vuex'

// eslint-disable-next-line
// import OneSignal from "onesignal-vue";
// eslint-disable-next-line
import OneSignalVue from 'onesignal-vue';

export default {
    name: 'ViewHome',
    props: {
        msg: String
    },
    components: {
        NavDrawer,
        AppBar,
        FooterBar,
        DialogMedApptList,
    },

    computed: {
        ...mapState('home', {
            loading: (state) => state.loading,
            errors: (state) => state.errors,
            errorCount: (state) => state.errorCount,
            user: (state) => state.user,
            memberProfile: (state) => state.memberProfile,
            validAccessToken: (state) => state.validAccessToken,
        }),

        mobileDisplay() {
            return this.$vuetify.breakpoint.xsOnly ? true : false
        },

        hasErrors() {
            return this.errors.length > 0
        },
    },

    methods: {
        logout() {
            this.$store.dispatch('logout', { router: this.$router })
        },
    },


    //  ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ OneSignal line below
    mounted() {
        // this.$OneSignal.showSlidedownPrompt();
        this.$OneSignal.User.PushSubscription.optIn();


    },
//  ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ OneSignal line below
    // beforeCreate() {
        // this.$OneSignal.showSlidedownPrompt();
        // this.$OneSignal.User.PushSubscription.optIn();
    // },



}
</script>

<style lang="scss" scoped>
.disable-content {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: #d1c4e9;
    background: transparent;
}
.v-progress-linear {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 500;
}
.view-home-canvas {
    background-color: #d1c4e9;
    min-height: 100%;
    // padding-left: .5em;
    // margin-left: .5em;
}
.v-main-container {
    background-color: #d1c4e9;
    min-height: 100%;

    padding-bottom: 1em;
    margin-bottom: 1em;

    padding-top: 1em;
    margin-top: 1em;

    padding-left: 0;
    margin-left: 0;

    padding-right: 0;
    margin-right: 0;
}
</style>
