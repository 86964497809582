<template>
    <div>
        <v-btn
            :id="id"
            x-small
            fab
            depressed
            color="transparent"
            @click="$emit('pop-up-details', dateSelected)"
        >
            {{ id }}
        </v-btn>
        <!--                                                  <v-icon
                                                                v-if="day.hasAudio"
                                                                class="audio-available"
                                                                color="#212121"
                                                                small
                                                              >
                                                                music_note
                                                              </v-icon> -->
        <!-- <span><v-btn fab x-small depressed color="transparent" :id="`day-${idx}`"   @click="popupappt(baseball)" >{{ day.num }}</v-btn></span> -->
        <!--         <v-btn fab x-small color= "transparent" @click="alert('TEST')"> 
                    {{ day.num }}
                    </v-btn> -->
    </div>
</template>

<script>
import { DateTime } from 'luxon'
export default {
    name: 'CalDots',

    props: {
        month: { type: Number, required: true },
        year: { type: Number, required: true },
        values: { type: Object, required: true },
        colorMap: { type: Object, required: true },
        id: {
            type: String,
            required: false,
            default() {
                return ''
            },
        },
    },

    computed: {
        dateSelected() {
            const dt9 = DateTime.fromObject({
                year: this.year,
                month: this.month + 1,
                day: this.id,
            })
            return dt9.toISODate()
        },
    },

    methods: {
        getStyles(value) {
            const color = this.colorMap[value]
            if (color) {
                return {
                    backgroundColor: color,
                    cursor: 'pointer',
                }
            }
            return null
        },
    },
}
</script>

<style lang="scss" scoped>
.day {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-self: center;
    height: 1.8em;
    width: 1.8em;
    border-radius: 50%;

    &:first-child,
    &:nth-child(7n + 1) {
        justify-self: start;
    }

    &:nth-child(7n) {
        justify-self: end;
    }

    .audio-available {
        position: absolute;
        top: -5px;
        left: calc(100% - 9px);
    }
}
</style>
