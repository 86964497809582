<template>
    <div>
        <v-row no-wrap no-gutters align="end" justify="center">
            <v-col>
                <router-link 
                    to   = "/home/view-medical-appointments" 
                    style= "text-decoration: none; color: inherit"
                >
                                                                                <!-- <v-chip class="ma-1" color="blue darken-1"> -->
                                                                                <!-- <span class="white--text font-size: 24px"> -->
                                                                                    <!-- {{ countDownBar_Specs.red }} -->
                                                                                <!-- </span> -->
                    <v-chip :color= "barA_Zone1ChipColor" >
                        <span class="daysTextGray">
                            2d
                        </span>
                    &nbsp;
                        
                                                                                 <!--    <v-avatar :color="zone1BadgeColor" tile size="14">
                                                                                            <span class="white--text">
                                                                                                2
                                                                                            </span>
                                                                                        </v-avatar>

                                                                                        &nbsp; -->

                                                                                <!--  <v-avatar :color="zone2BadgeColor" tile size="14">
                                                                                            <span class="white--text">7</span>
                                                                                        </v-avatar>

                                                                                        &nbsp;

                                                                                        <v-avatar  :color="zone3BadgeColor"  tile size="14">
                                                                                            <span class="white--text">30</span>
                                                                                        </v-avatar>
                                                                                        
                                                                                        &nbsp; -->
                                                                                <!-- 
                                                                                        <span class="daysTextGray">
                                                                                            Days
                                                                                        </span> -->

                                                                                    <!-- </v-chip>  -->
                                                                                            <!-- &nbsp;   -->
                                                                                <!--                      <span class="text-button">
                                                                                Next 2/7/30 Days
                                                                                    </span> -->
                                                                                    <!--                       <span class="text-button">
                                                                                    &amp;
                                                                                    </span> -->
                                                                                <!--                     <span class="text-button">
                                                                                    7
                                                                                    </span>
                                                                                    <span class="text-button"> -->
                                                                                        <!-- Days -->
                                                                                    <!-- </span> -->
                                                                                            
                                                                                <!--  <span class="daysText">
                                                                                        {{ countDownBar_Specs.red }}
                                                                                    </span>
                                                                        
                                                                                <span class="daysText">
                                                                                        /
                                                                                    </span>
                                                                                    
                                                                                    <span class="daysText">
                                                                                        {{ countDownBar_Specs.yellow }}   
                                                                                    </span>
                                                                                    
                                                                                    <span class="daysText">
                                                                                        Days
                                                                                    </span>  -->
                        <v-badge
                            overlap
                            :content="barA_Zone1ItemCount"
                            :value="barA_ShowZone1Badge"
                            :color="barA_Zone1BadgeColor"
                            offset-x="2"
                            offset-y="8"
                        >                           
                            <v-icon x-small color="grey darken-1">
                                {{`${barA_Icon}`}}
                            </v-icon>
                        </v-badge>
                        &nbsp;

                                                                                <!-- &nbsp; -->
                                                                                <!--  <v-badge
                                                                                        overlap
                                                                                        :content="zone2ItemCount"
                                                                                        :value="zone1BadgeSHOW"
                                                                                        :color="zone2BadgeColor"
                                                                                        offset-x="-10"
                                                                                        offset-y="3"
                                                                                    >        
                                                                                        <v-icon> -->
                                                                                            <!-- fa-calendar-day -->
                                                                                <!--    </v-icon>
                                                                                    </v-badge>

                                                                                    <v-badge
                                                                                        overlap
                                                                                        :content="zone3ItemCount"
                                                                                        :value="zone1BadgeSHOW"
                                                                                        :color="zone3BadgeColor"
                                                                                        offset-x="-28"
                                                                                        offset-y="3"
                                                                                    > 
                                                                                        <v-icon> -->
                                                                                            <!-- fa-calendar-day -->
                                                                                        <!-- </v-icon> -->
                                                                                    <!-- </v-badge> -->
                    </v-chip> 

                   <v-chip :color=  "barA_Zone2ChipColor" >
                        <span class="daysTextGray">
                            7d
                        </span>
                        &nbsp;
                                                                                <!--   &nbsp;
                                                                                
                                                                                <v-avatar :color="zone1BadgeColor" tile size="14">
                                                                                    <span class="white--text">2</span>
                                                                                </v-avatar> -->

                                                                                <!--     <v-avatar :color="zone2BadgeColor" tile size="14">
                                                                                    <span class="white--text">7</span>
                                                                                </v-avatar>

                                                                                &nbsp; -->

                                                                                <!--       <v-avatar  :color="zone3BadgeColor"  tile size="14">
                                                                                <span class="white--text">30</span>
                                                                                </v-avatar>
                                                                            
                                                                                &nbsp; -->

                                                                                <!--          <span class="daysTextGray">
                                                                                    Days
                                                                                </span> -->
                    <v-badge
                        overlap
                        :content="barA_Zone2ItemCount"
                        :value="barA_ShowZone2Badge"
                        :color="barA_Zone2BadgeColor"
                        offset-x="2"
                        offset-y="8"
                    >                           
                     <v-icon x-small color="grey darken-1">
                           fa-user-md
                        </v-icon>
                    </v-badge>
                    &nbsp;
                    &nbsp;

                                                                                 <!-- &nbsp; -->

                                                                                <!--  <v-badge
                                                                                        overlap
                                                                                        :content="zone2ItemCount"
                                                                                        :value="zone1BadgeSHOW"
                                                                                        :color="zone2BadgeColor"
                                                                                        offset-x="-10"
                                                                                        offset-y="3"
                                                                                    >
                                                                                            
                                                                                        <v-icon> -->
                                                                                            <!-- fa-calendar-day -->
                                                                                <!--            </v-icon>
                                                                                    </v-badge>

                                                                                    <v-badge
                                                                                        overlap
                                                                                        :content="zone3ItemCount"
                                                                                        :value="zone1BadgeSHOW"
                                                                                        :color="zone3BadgeColor"
                                                                                        offset-x="-28"
                                                                                        offset-y="3"
                                                                                    > 
                                                                                        <v-icon> -->
                                                                                            <!-- fa-calendar-day -->
                                                                                        <!-- </v-icon> -->
                                                                                    <!-- </v-badge> -->
                    </v-chip> 

                   <v-chip :color= "barA_Zone3ChipColor" >
                        <span class="daysTextGray">
                            30d
                        </span>
                        &nbsp;
                                                                                <!--   &nbsp;
                                                                                
                                                                                <v-avatar :color="zone1BadgeColor" tile size="14">
                                                                                    <span class="white--text">2</span>
                                                                                </v-avatar> -->

                                                                                <!--     <v-avatar :color="zone2BadgeColor" tile size="14">
                                                                                    <span class="white--text">7</span>
                                                                                </v-avatar>

                                                                                &nbsp; -->

                                                                                <!--       <v-avatar  :color="zone3BadgeColor"  tile size="14">
                                                                                <span class="white--text">30</span>
                                                                                </v-avatar>
                                                                            
                                                                                &nbsp; -->

                                                                                <!--          <span class="daysTextGray">
                                                                                    Days
                                                                                </span> -->
                    <v-badge
                        overlap
                        :content="barA_Zone3ItemCount"
                        :value="barA_ShowZone3Badge"
                        :color="barA_Zone3BadgeColor"
                        offset-x="2"
                        offset-y="8"
                    >                           
                     <v-icon x-small color="grey darken-1">
                            fa-user-md
                        </v-icon>
                    </v-badge>
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    <v-badge
                        overlap
                        :content="barA_Zone3ItemCount"
                        :value="barA_ShowZone3Badge"
                        :color="barA_Zone3BadgeColor"
                        offset-x="2"
                        offset-y="8"
                    >                           
                        <v-icon x-small color="grey darken-1">
                            fa-arrows-alt
                        </v-icon>
                    </v-badge>
                    &nbsp;
                    &nbsp;
                </router-link>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    name: 'AppBarAlerts',
    props: {
        countDownBarA_Specs: {type: Object, required: false },
        countDownBarA_Zone1ItemCount: {type: Number, required: false},
        countDownBarA_Zone2ItemCount: {type: Number, required: false},
        countDownBarA_Zone3ItemCount: {type: Number, required: false},
    },

    computed: {
        barA_Icon()           {return this.countDownBarA_Specs.icon },
        barA_NavLinkTo()      {return this.countDownBarA_Specs.navLinkTo },
        
        barA_ShowZone1Badge() {return (this.countDownBarA_Zone1ItemCount) >0 },    
        barA_ShowZone2Badge() {return (this.countDownBarA_Zone2ItemCount) >0 }, 
        barA_ShowZone3Badge() {return (this.countDownBarA_Zone3ItemCount) >0 },

        barA_Zone1BadgeColor(){return this.countDownBarA_Specs.zone1BadgeColor},
        barA_Zone2BadgeColor(){return this.countDownBarA_Specs.zone2BadgeColor},
        barA_Zone3BadgeColor(){return this.countDownBarA_Specs.zone3BadgeColor},

        barA_Zone1ChipColor() {return this.countDownBarA_Specs.zone1ChipColor},
        barA_Zone2ChipColor() {return this.countDownBarA_Specs.zone2ChipColor},
        barA_Zone3ChipColor() {return this.countDownBarA_Specs.zone3ChipColor},

        barA_Zone1ItemCount() {return this.countDownBarA_Zone1ItemCount},
        barA_Zone2ItemCount() {return this.countDownBarA_Zone2ItemCount},
        barA_Zone3ItemCount() {return this.countDownBarA_Zone3ItemCount},
    }
}
</script>
<style lang="scss">

.daysTextWhite {
    // font-weight: bold;
    color: #ffffff;
    font-size: 1.0em;
}
.daysTextRed {
    // font-weight: bold;
    color: #D50000;
    font-size: 1.0em;
}

.daysTextGray {
    // font-weight: bold;
    color: #000000;
    font-size: 0.8em;
}
</style>