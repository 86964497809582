<template>
    <v-row>
        <v-col>
            <span class="calendar-show"> Show More Months</span>
            <!-- <v-avatar size="36" color="white"> -->
            <v-btn
                x-small
                fab
                depressed
                rounded
                color="grey darken-1"
                @click="toggleHiddenCalendars"
            >
                <v-icon color="white">
                    {{ showExtraCalendarsIcon }}
                </v-icon>
            </v-btn>
            <!-- <span class="calendar-show"> Months </span> -->
            <!-- </v-avatar>  -->
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: 'CalendarShowHide',
    data() {
        return {
            showExtraCalendars: false,
        }
    },

    computed: {
        showExtraCalendarsIcon() {
            if (this.showExtraCalendars) {
                return `fa-calendar-minus`
            } else {
                return `fa-calendar-plus`
            }
        },
    },

    methods: {
        toggleHiddenCalendars() {
            this.showExtraCalendars = !this.showExtraCalendars
            this.$emit('toggle-hidden-cals')
        },
    },
}
</script>
<style lang="scss" scoped>
.calendar-show {
    margin-top: 2em;
    font-size: 1em;
    text-align: center;
    // text-decoration: underline;
    text-transform: capitalize;
    font-weight: bold;
    color: #ffffff;
}
</style>
