<template>
    <!-- <v-container fluid fill-height class="view-symptoms-container"> -->
    <div v-if="loading">
        <v-row no-gutters align="start" justify="center">
            <v-col cols="12" xs="10" sm="8" md="4" lg="3" xl="3">
                <v-progress-circular
                    :rotate="90"
                    :size="100"
                    :width="15"
                    :value="spinnerVal"
                    color="red"
                >
                    {{ spinnerVal }}
                </v-progress-circular>
            </v-col>
        </v-row>
    </div>
    <div v-else>
        <v-row no-gutters align="start" justify="center">
            <v-col cols="12" xs="10" sm="8" md="4" lg="3" xl="3">
                <v-card elevation="0" pa-1 my-0 mx-0 class="blue-rounded-card">
                    <v-card-text class="text-h6 text-center white--text">
                        HEALTH PROBLEMS SETTINGS
                    </v-card-text>
                    <v-card flat color="transparent">
                        <v-row no-gutters align="center" justify="center">
                            <v-col>
                                <v-chip small dark color="deep-purple darken-4">
                                    <span> {{ problemShortNameDisplay }}</span>
                                </v-chip>
                            </v-col>
                        </v-row>

                        <v-row no-gutters align="center" justify="center">
                            <v-col>
                                <v-chip small dark color="deep-purple darken-4">
                                    <span> {{ minSeverity }}+ Severity </span>
                                </v-chip>
                            </v-col>
                        </v-row>

                        <v-row no-gutters align="center" justify="center">
                            <v-col>
                                <v-chip small dark color="deep-purple darken-4">
                                    <span
                                        >{{ minStreakDays }}+ Consecutive
                                        Days</span
                                    >
                                </v-chip>
                            </v-col>
                        </v-row>
                    </v-card>
                    <calendar-switch
                        :getter-time-frame-start-month-luxon-object="
                            getterTimeFrameStartMonthLuxonObject
                        "
                        @shiftStartMonth="onUpdateStartMonth($event)"
                    >
                    </calendar-switch>

                    <calendar-show-hide
                        @toggle-hidden-cals="toggleShowHideCalendars($event)"
                    >
                    </calendar-show-hide>
                </v-card>
            </v-col>
        </v-row>

        <v-row no-gutters align="center" justify="center">
            <v-col cols="12" xs="10" sm="8" md="4" lg="3" xl="3">
                <v-card elevation="0" pa-1 my-0 mx-0 class="blue-rounded-card">
                    <v-card-text class="text-h6 text-center white--text">
                        MY TRENDS - LAST {{ testPeriodWeeks }} WEEKS
                    </v-card-text>

                    <v-expansion-panels
                        v-if="sufficientDataForTrendDisplay.BOOL"
                        flat
                        dark
                        popout
                    >
                        <v-expansion-panel hide-actions>
                            <v-expansion-panel-header
                                color="deep-purple darken-4"
                            >
                                <v-row align="left" no-gutters>
                                    <v-col cols="12" sm="12" md="12">
                                        <v-avatar
                                            size="32px"
                                            :color="trend1Stats.color"
                                        >
                                            <v-icon
                                                color="deep-purple darken-4"
                                            >
                                                {{ trend1Stats.icon }}
                                            </v-icon>
                                        </v-avatar>
                                        &nbsp;
                                        <span
                                            class="text-subtitle-2 text-center white--text"
                                            >{{ problemShortNameDisplay }}</span
                                        >
                                        <span
                                            class="text-subtitle-2 text-center"
                                            :style="{
                                                color: trend1Stats.color,
                                            }"
                                            >{{ trend1HeaderDisplay }}</span
                                        >
                                    </v-col>
                                </v-row>
                                <template #actions>
                                    <v-icon color="grey darken-1">
                                        fa-angle-double-down
                                    </v-icon>
                                </template>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content
                                color="deep-purple darken-4"
                            >
                                <v-divider></v-divider>
                                <span
                                    class="text-subtitle-2 text-left white--text"
                                >
                                    {{ trend1DetailDisplay }}
                                </span>
                            </v-expansion-panel-content>
                            <v-expansion-panel-content color="grey darken-1">
                                <span
                                    class="text-subtitle-2 text-left white--text"
                                >
                                    {{ trend1NoteDisplay }}
                                </span>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                    <div v-else class="text-subtitle-1 text-center white--text">
                        <em>
                            We don't have enough data for the last
                            {{ controlPeriodPlusTestPeriodWeeks }} weeks to
                            calculate your 2 weeks trends.
                            <p>
                                Keep posting daily and your trends will display
                                automatically when available.
                            </p></em
                        >
                    </div>
                </v-card>
            </v-col>
        </v-row>

        <v-row no-gutters align="start" justify="center">
            <v-col cols="12" xs="10" sm="8" md="4" lg="3" xl="3">
                <v-card elevation="0" pa-0 my-0 mx-0 class="blue-rounded-card">
                    <v-card-text class="text-h6 text-center white--text">
                        SEVERITY LEVELS
                    </v-card-text>
                    <v-row no-gutters align="start" justify="center">
                        <v-col xs="10" sm="10" md="10" lg="9" xl="7">
                            <v-card elevation="0" pa-0 ma-0 color="white">
                                <calendar-legend
                                    :color-map="colorMap"
                                    :keys="keys"
                                    @toggle-hidden-cals="
                                        toggleShowHideCalendars($event)
                                    "
                                >
                                    <!-- <span slot="legend-text" class="text-subtitle-2"> Severity Level </span> -->
                                </calendar-legend>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>

        <v-row no-gutters align="center" justify="center">
            <v-col cols="12">
                <calendar-grp-health-problems
                    :start-month="startMonth"
                    :start-year="startYear"
                    :count="count"
                    :values-object1="valuesObject1"
                    :color-map="colorMap"
                    :is-large-display="isLargeDisplay"
                    :getter-todays-month="getterTodaysMonth"
                    :getter-todays-year="getterTodaysYear"
                    :time-frame-start-month-luxon-object="
                        timeFrameStartMonthLuxonObject
                    "
                    :dates="dates"
                    :display-calendars-hide-or-show-array="
                        displayCalendarsHideOrShowArray
                    "
                >
                </calendar-grp-health-problems>
            </v-col>
        </v-row>

        <v-row wrap align="center" justify="center">
            <v-col cols="12" xs="10" sm="8" md="6" lg="6" xl="6">
                <v-card ma-2 pa-2 elevation="0" color="blue darken-1">
                    <!--    below is the sequence for switching symptoms on the calendar
                                                                        when the symptoms view is created on start up it fetches the full set of symptom 
                                                                        codes from TigerGraph and updates Vuex the default symptom is "PAIN"
                                                                        so the code for "PAIN" is set as the default for the filter object which looks like
                                                                        this filter: {exists: false, visible: 0, data: {}, dataMap: {}, },
                                                                        the data property contains the code the severity level and consecutive days
                                                                        the default severity is "0" and the consecutive days is "0" 
                                                                        when the user clicks one of the symptom v-chips to view a different symptom 
                                                                        the child component "SymptomFilter" 
                                                                        emits the event "@filter-update" which triggers "onFilterUpdate"
                                                                        the event transmits an object payload of "{data: { code: symptom.code }"
                                                                        which calls the "updateFilter" ACTION located in calendar.js
                                                                        "updateFilter" commits the UPDATE_FILTER_DATA mutation
                                                                        receives the object containing the newly selected SYMPTOM 
                                                                        code which updates the Vuex state, then the ACTION 'updateCalendar' is called  
                                                                        which creates the params object containing the START and END months-years
                                                                        'updateCalendar' makes the API call to TigerGraph to fetch the calendar data -->
                    <SymptomFilter
                        :color-map="colorMap"
                        :keys="keys"
                        :filter="filter"
                        :problem-codebook="problemCodebook"
                        @problem-selected="onProblemSelected"
                        @severity-selected="onSeveritySelected"
                        @streak-days-selected="onStreakDaysSelected"
                    >
                    </SymptomFilter>
                </v-card>
            </v-col>
        </v-row>
    </div>
    <!-- </v-container> -->
</template>

<script>
import CalendarSwitch from '@/components/calendar/CalendarSwitch.vue'
import CalendarGrpHealthProblems from '@/components/calendar/CalendarGrpHealthProblems.vue'
import CalendarLegend from '@/components/calendar/CalendarLegend.vue'
import SymptomFilter from '@/components/calendar/filter/SymptomFilter.vue'
import CalendarShowHide from '@/components/calendar/CalendarShowHide.vue'
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import * as calendar from '@/store/modules/calendar'
import calendarDefs from '@/data/calendars'

// eslint-disable-next-line
import { DateTime, Duration, Info, Interval, Settings} from 'luxon';
import { permutationTest, mean } from 'simple-statistics'

export default {
    name: 'ViewHealthProblems',

    components: {
        CalendarSwitch,
        CalendarGrpHealthProblems,
        CalendarLegend,
        SymptomFilter,
        CalendarShowHide,
    },

    data() {
        return {
            // spinnerOn: true,
            interval: {},
            spinnerVal: 0,

            targetProblemCode: 'symMS_1020_2021A',
            targetProblemCodebookCode: 'symMS_1020_2021A',

            calendars: calendarDefs,
            userSelectedTimeFrameStartMonthISODate: '',
            minSeverity: 0,
            minStreakDays: 1,
            problemCodeOBJ: { code: 'symMS_1020', problemShortName: 'Pain' },
            controlPeriodWeeks: 4,
            testPeriodWeeks: 2,

            showCalendarsBool: false,
            trendArrayDateKeyField: 'uploadDateLocal',
            trendArrayValueField: 'sevLevel',
            timeFrameStartYearMonth: "",

            problemsArrayDateKeyField: 'uploadDateLocal',
            problemsArrayProblemCodeField: 'SYM_BaseCode',
            problemsArrayValueField: 'sevLevel',
            problemsArrayMiniIconField: 'hasAudio',
            problemsArrayProblemCodebookField: 'SYM_Codebook_vID',
            problemsArrayTransactionDateMillisField: 'transactionDateMillis',
        }
    },

    computed: {
        ...mapState('calendar', {
            dataType: (state) => state.dataType,
            dialog: (state) => state.dialog,
            filter: (state) => state.filter,
            colorMap: (state) => state.colorMap,
            keys: (state) => state.keys,
            todaysDateLuxonObject: (state) => state.todaysDateLuxonObject,
            calDisplayConfig: (state) => state.calDisplayConfig,
            problemLogArray: (state) => state.problemLogArray,
            problemCodebook: (state) => state.problemCodebook,
            initTimeFrameStartMonthLuxonObject: (state) =>
                state.initTimeFrameStartMonthLuxonObject,
        }),

        ...mapState('home', {
            user: (state) => state.user,
            memberProfile: (state) => state.memberProfile,
            validAccessToken: (state) => state.validAccessToken,
            loading: (state) => state.loading,
        }),

        ...mapGetters('calendar', [
            'getterTodaysMonth',
            'getterTodaysYear',
            'getterTimeFrameEndMonthLuxonObject',
            'getterTimeFrameStartMonthLuxonObject',
            'getterFilteredProblemLogVertices',
        ]),

        calendarDisplayConfig() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs':
                    return { total: 3, history: -2, hidden: 2 }
                case 'sm':
                    return { total: 8, history: -7, hidden: 4 }
                case 'md':
                    return { total: 8, history: -7, hidden: 4 }
                case 'lg':
                    return { total: 8, history: -7, hidden: 4 }
                case 'xl':
                    return { total: 8, history: -7, hidden: 4 }
                default:
                    return { total: 4, history: -3, hidden: 0 }
            }
        },

        timeFrameStartMonthDisplayTINY() {
            return this.getterTimeFrameStartMonthLuxonObject.toFormat(
                'MMMM-yyyy'
            )
        },
        timeFrameEndMonthDisplayTINY() {
            return this.getterTimeFrameEndMonthLuxonObject.toFormat('MMMM-yyyy')
        },

        defaultSymptomStream() {
            return calendarDefs[1]
        },

        Member_LEAP_ID() {
            return this.user.email
        },

        problemShortNameDisplay() {
            return this.problemCodeOBJ.problemShortName
        },

        controlPeriodPlusTestPeriodWeeks() {
            return this.controlPeriodWeeks + this.testPeriodWeeks
        },
        testPeriodDays() {
            return this.testPeriodWeeks * -7
        },
        controlPeriodDays() {
            return this.controlPeriodWeeks * -7
        },

        displayCalendarsHiddenArray() {
            let countTotal = this.calendarDisplayConfig.total
            let countHidden = this.calendarDisplayConfig.hidden
            let arrayJ = []
            for (let j = 0; j < countTotal; j++) {
                j < countHidden ? arrayJ.push(false) : arrayJ.push(true)
            }
            return arrayJ
        },

        displayCalendarsAllArray() {
            let countTotal = this.calendarDisplayConfig.total
            let arrayK = []
            for (let k = 0; k < countTotal; k++) {
                arrayK.push(true)
            }
            return arrayK
        },

        displayCalendarsHideOrShowArray() {
            return this.showCalendarsBool
                ? this.displayCalendarsAllArray
                : this.displayCalendarsHiddenArray
        },

        datesArrayIndexZeroYearMonth() {
            let dateZero = this.dates[0]
            dateZero = DateTime.fromJSDate(dateZero)
            return dateZero.year + '-' + dateZero.month 
        },
        yearMonthNotYetFetched(){
            let yearMonth = this.timeFrameStartYearMonth + '-' + '01'
            let yearMonthLuxonObject = DateTime.fromISO(yearMonth) 
            // let subtractionMonths = ((this.calendarDisplayConfig).total + 2);
            // yearMonthLuxonObject.plus({ months: -subtractionMonths })
            yearMonthLuxonObject = yearMonthLuxonObject.plus({ months: -1 })
            yearMonth = yearMonthLuxonObject.year + '-' + yearMonthLuxonObject.month
            return yearMonth
        },


        dates() {
            // the "dates" computed property returns an array derived from the start month = "getterTimeFrameStartMonthLuxonObject" Vuex store
            // the number of calendars displayed = "total" is configured in the computed property "calendarDisplayConfig"
            // total is always a positive integer and the current month should always be displayed
            // the "history" value is always negative and equals the count of calendars appearing BEFORE the current month
            // for some displays like appointments it makes sense to have both history negative value and future months displayed
            // to display all future months then use history = 0
            const tframe = []
            for (let i = 0; i < this.calendarDisplayConfig.total; i++) {
                let jsDate1 = this.getterTimeFrameStartMonthLuxonObject
                let jsDate2 = jsDate1.plus({ months: i })
                let jsDate3 = jsDate2.toJSDate()
                tframe.push(jsDate3)
            }
            return tframe
        },

        filteredProblemLogArray() {
            if (
                !(
                    this.problemLogArray === undefined ||
                    this.problemLogArray.length == 0
                )
            ) {
                // console.log('line 295 this.problemLogArray should be FALSE and returns an empty array which is below')
                // console.log(this.problemLogArray)
                // console.log('line 300 this.problemLogArray should be TRUE and the Vuex array below')
                // console.log(this.problemLogArray)
                return this.filterProblemLogArrayByProblemCodebookCode(
                    this.problemLogArray,
                    this.targetProblemCode,
                    this.problemsArrayProblemCodeField,
                    this.problemsArrayValueField,
                    this.problemsArrayDateKeyField,
                    this.problemsArrayMiniIconField,
                    this.problemsArrayProblemCodebookField,
                    this.problemsArrayTransactionDateMillisField
                )
            } else {
                return []
            }
        },

        valuesObject1() {
            if (this.filteredProblemLogArray) {
                return this.createValuesObject(
                    this.filteredProblemLogArray,
                    this.streakFilterON,
                    this.severityFilterON,
                    this.minSeverity,
                    this.minStreakDays
                )
            } else {
                return 'Trend Data Not Available'
            }
        },

        severityFilterON() {
            if (this.minSeverity >= 1) {
                return true
            } else {
                return false
            }
        },
        streakFilterON() {
            if (this.minStreakDays >= 2) {
                return true
            } else {
                return false
            }
        },

        valuesArrayStats() {
            if (this.filteredProblemLogArray) {
                return this.filteredProblemLogArray
            } else {
                return [
                    {
                        uploadDateLocal: '1900-01-01',
                        hasAudio: 'false',
                        sevLevel: '0',
                    },
                ]
            }
        },

        trendArraySlicedOBJ() {
            if (
                !(
                    this.problemLogArray === undefined ||
                    this.problemLogArray.length == 0
                )
            ) {
                return this.sliceTrendArray(
                    this.valuesArrayStats,
                    this.testPeriodDays,
                    this.controlPeriodDays,
                    this.trendArrayDateKeyField,
                    this.trendArrayValueField
                )
            } else {
                return []
            }
        },

        trendPermutationTestResults() {
            if (
                !(
                    this.problemLogArray === undefined ||
                    this.problemLogArray.length == 0
                )
            ) {
                return this.runStatsPermutationTest(
                    this.trendArraySlicedOBJ,
                    this.trendArrayDateKeyField,
                    this.trendArrayValueField
                )
            } else {
                return 'Trend Data Not Available'
            }
        },

        trend1Stats() {
            if (
                !(
                    this.problemLogArray === undefined ||
                    this.problemLogArray.length == 0
                )
            ) {
                return this.compilePermutationTest(
                    this.trendPermutationTestResults
                )
            } else {
                return { text: 'Trend Data Not Available' }
            }
        },

        trend1HeaderDisplay() {
            if (this.trend1Stats) {
                return ': ' + this.trend1Stats.text
            } else {
                return 'Trend Data Not Available'
            }
        },
        trend1DetailDisplay() {
            if (
                !(
                    this.problemLogArray === undefined ||
                    this.problemLogArray.length == 0
                )
            ) {
                const {
                    // text,
                    meanCP,
                    meanTP,
                    controlZero,
                    controlTerminal,
                    testZero,
                    testTerminal,
                    // icon,
                    // color,
                    // note,
                } = this.trend1Stats
                return (
                    'Your average severity went from ' +
                    meanCP.toFixed(1) +
                    ' to ' +
                    meanTP.toFixed(1) +
                    '.    From ' +
                    controlZero +
                    ' to ' +
                    controlTerminal +
                    ' it was ' +
                    meanCP.toFixed(1) +
                    ' compared to ' +
                    meanTP.toFixed(1) +
                    ' for ' +
                    testZero +
                    ' to ' +
                    testTerminal +
                    '.'
                )
            } else {
                return 'Trend Data Not Available'
            }
        },

        trend1NoteDisplay() {
            if (this.trend1Stats) {
                const { note } = this.trend1Stats
                return note
            } else {
                return 'Trend Data Not Available'
            }
        },

        sufficientDataForTrendDisplay() {
            if (
                !(
                    this.problemLogArray === undefined ||
                    this.problemLogArray.length == 0
                )
            ) {
                const countControlDaysReported = this.trendArraySlicedOBJ[
                    'timeSlicedTrendArray'
                ].filter(
                    (element) => element.control_test_omit === 'control'
                ).length

                const countTestDaysReported = this.trendArraySlicedOBJ[
                    'timeSlicedTrendArray'
                ].filter(
                    (element) => element.control_test_omit === 'test'
                ).length

                const sumControlTestDaysReported =
                    countControlDaysReported + countTestDaysReported

                let sufficientDataOBJ = {}

                if (countControlDaysReported > 6 && countTestDaysReported > 6) {
                    sufficientDataOBJ = {
                        countControlDays: countControlDaysReported,
                        countTestDays: countTestDaysReported,
                        sumControlTestDays: sumControlTestDaysReported,
                        BOOL: true,
                    }
                } else {
                    sufficientDataOBJ = {
                        countControlDays: countControlDaysReported,
                        countTestDays: countTestDaysReported,
                        sumControlTestDays: sumControlTestDaysReported,
                        BOOL: false,
                    }
                }
                return sufficientDataOBJ
            } else return { BOOL: false }
        },

        showTrend1Bool() {
            const trend1Available = !(
                this.trend1Stats.text == 'Trend Data Not Available'
            )
                ? true
                : false
                                                                                    // console.log('trend1Available is below')
                                                                                    // console.log(trend1Available)
            return trend1Available
        },
    },

    methods: {
        ...mapActions('calendar', [
            'actionShiftCalendar',
            'selectStream',
            'updateFilter',
            'updateCalendar',
            'actionUpdateProblemCode',
            'actionUpdateCalDisplayConfig',
            'actionSetInitTimeFrameStartMonthLuxonObject',
            'actionShiftStartMonthLuxonObject',
            'actionGetAllProblemLogVertices',
            'actionGetProblemLogArray',
            'actionGetExtraYearProblemLogArray'
        ]),

        ...mapMutations('calendar', {
            setFilterVisibility: calendar.types.SET_FILTER_VISIBILITY,
        }),
        // ...mapActions('home', [ 'actionSetMemberProfileFromTG' ]),

        toggleShowHideCalendars() {                    
                this.showCalendarsBool = !this.showCalendarsBool
        },

        setTimeFrameStartYearMonthMounted() {
            this.timeFrameStartYearMonth = (this.getterTimeFrameStartMonthLuxonObject).year + '-' + (this.getterTimeFrameStartMonthLuxonObject).month
        },

        onUpdateStartMonth(delta) {

                                                                                    console.log('onUpdateStartMonth')
            if (this.datesArrayIndexZeroYearMonth == this.yearMonthNotYetFetched) {
                                                                                    console.log('onUpdateStartMonth yearMonthNotYetFetched triggered')
                this.actionGetExtraYearProblemLogArray();
                this.actionGetProblemLogArray();
                this.actionShiftStartMonthLuxonObject(delta)
            } else {
                                                                                    // console.log('onUpdateStartMonth NO TRIGGER')
            this.actionShiftStartMonthLuxonObject(delta)
            }

        },

        onFilterUpdate({ data, update }) {
            this.updateFilter({ data, update })
        },

        onSeveritySelected(sevLevel) {
            this.minSeverity = sevLevel
        },

        onProblemSelected(problemCodeObject) {
            // console.log("problemCodeObject from Symptom Filter is below")
            // console.log(problemCodeObject)
            this.targetProblemCode = problemCodeObject.code
            this.problemCodeOBJ = problemCodeObject
            // this.actionUpdateProblemCode( { code: this.problemCodeOBJ.code } )
        },

        onStreakDaysSelected(streakDays) {
            this.minStreakDays = streakDays
        },

        filterProblemLogArrayByProblemCodebookCode(
            insertedArray,
            targetProblemCodebookCode,
            problemsArrayProblemCodeField,
            problemsArrayValueField,
            problemsArrayDateKeyField,
            problemsArrayMiniIconField,
            problemsArrayProblemCodebookField
            // problemsArrayTransactionDateMillisField
        ) {
            if (insertedArray) {
                const ppp = insertedArray.filter(
                    (item) =>
                        item[problemsArrayProblemCodebookField] ==
                        targetProblemCodebookCode
                )[0]
                // console.log ('ppp is below')
                // console.log(ppp)
                const { pLVArray } = ppp
                // console.log ('pLVArray[0] is below')
                // console.log(pLVArray[0]['uploadDateLocal'])
                // console.log(pLVArray[0]['sevLevel'])
                const valuesArray61 = pLVArray
                const valuesArray63 = valuesArray61.map((elt) => {
                    const tempBin = {}
                    tempBin[problemsArrayDateKeyField] =
                        elt[problemsArrayDateKeyField]
                    tempBin[problemsArrayValueField] =
                        elt[problemsArrayValueField]
                    tempBin[problemsArrayMiniIconField] =
                        elt[problemsArrayMiniIconField]
                    return tempBin
                    // console.log ('line 285 uploadDate Local is below')
                    // console.log(uploadDateLocal)
                    // console.log ('line 287 sevLevel is below')
                    // console.log(sevLevel)
                })
                return valuesArray63
            } else return []
        },

        sliceTrendArray(
            injectedArray,
            TF_TestPeriodDays,
            TF_ControlPeriodDays,
            dateKeyField,
            valueField
        ) {
                                                                        // console.log(injectedArray)
                                                                        // console.log(TF_TestPeriodDays)
                                                                        // console.log(TF_ControlPeriodDays)
                                                                        // console.log(dateKeyField)
                                                                        // console.log(valueField)
                                                                        // const arrayIndexZeroISODate = injectedArray[0][dateKeyField];
                                                                        // console.log(arrayIndexZeroISODate)
            const arrayIndexTerminalISODate =
                injectedArray[injectedArray.length - 1][dateKeyField]
            // console.log(arrayIndexTerminalISODate)
            const TF_TestPeriodTerminalDateLuxonObject = DateTime.fromISO(
                arrayIndexTerminalISODate
            )
            const TF_TestPeriodZeroDateLuxonObject =
                TF_TestPeriodTerminalDateLuxonObject.plus({
                    days: TF_TestPeriodDays + 1,
                })
            const TF_ControlPeriodTerminalDateLuxonObject =
                TF_TestPeriodZeroDateLuxonObject.plus({ days: -1 })
            const TF_ControlPeriodZeroDateLuxonObject =
                TF_ControlPeriodTerminalDateLuxonObject.plus({
                    days: TF_ControlPeriodDays + 1,
                })
            // console.log('TF_ControlPeriodZeroDateLuxonObject     = ' + TF_ControlPeriodZeroDateLuxonObject.toISO())
            // console.log('TF_ControlPeriodTerminalDateLuxonObject = ' + TF_ControlPeriodTerminalDateLuxonObject.toISO())
            // console.log('TF_TestPeriodZeroDateLuxonObject        = ' + TF_TestPeriodZeroDateLuxonObject.toISO())
            // console.log('TF_TestPeriodTerminalDateLuxonObject    = ' + TF_TestPeriodTerminalDateLuxonObject.toISO())
            const timeSlicedTrendArray = injectedArray.map((element) => {
                const temporaryLuxonObject = DateTime.fromISO(
                    element[dateKeyField]
                )
                //  console.log('temporaryLuxonObject = ' +  temporaryLuxonObject.toISO())
                const tempTrendOBJ = {}
                if (
                    TF_ControlPeriodZeroDateLuxonObject <=
                        temporaryLuxonObject &&
                    temporaryLuxonObject <=
                        TF_ControlPeriodTerminalDateLuxonObject
                ) {
                    tempTrendOBJ.date = element[dateKeyField]
                    tempTrendOBJ.sevLevel = element[valueField]
                    tempTrendOBJ.control_test_omit = 'control'
                    // console.log('CONTROL PERIOD dateKeyField =' + element[dateKeyField] )
                    // console.log(tempTrendOBJ)
                } else if (
                    TF_TestPeriodZeroDateLuxonObject <= temporaryLuxonObject &&
                    temporaryLuxonObject <= TF_TestPeriodTerminalDateLuxonObject
                ) {
                    tempTrendOBJ.date = element[dateKeyField]
                    tempTrendOBJ.sevLevel = element[valueField]
                    tempTrendOBJ.control_test_omit = 'test'
                    // console.log('TEST PERIOD dateKeyField = ' + element[dateKeyField] )
                    // console.log(tempTrendOBJ)
                } else {
                    tempTrendOBJ.date = element[dateKeyField]
                    tempTrendOBJ.sevLevel = element[valueField]
                    tempTrendOBJ.control_test_omit = 'omit'
                    // console.log('Omit dateKeyField = ' + element[dateKeyField] )
                    // console.log(tempTrendOBJ)
                }
                return tempTrendOBJ
            })

            const trendArraySlicedObject = {
                timeSlicedTrendArray: timeSlicedTrendArray,
                controlPeriodZeroDateLuxon: TF_ControlPeriodZeroDateLuxonObject,
                controlPeriodTerminalDateLuxon:
                    TF_ControlPeriodTerminalDateLuxonObject,
                testPeriodZeroDateLuxon: TF_TestPeriodZeroDateLuxonObject,
                testPeriodTerminalDateLuxon:
                    TF_TestPeriodTerminalDateLuxonObject,
            }
            // console.log(trendArraySlicedObject)
            return trendArraySlicedObject
        },

        runStatsPermutationTest(trendArraySlicedOBJ, dateKeyField, valueField) {
            const {
                timeSlicedTrendArray,
                controlPeriodZeroDateLuxon,
                controlPeriodTerminalDateLuxon,
                testPeriodZeroDateLuxon,
                testPeriodTerminalDateLuxon,
            } = trendArraySlicedOBJ
            // console.log('trendArraySlicedOBJ is below')
            // console.log (trendArraySlicedOBJ)
            const trendArrayControl = timeSlicedTrendArray.filter(
                (item) => item.control_test_omit === 'control'
            )
            const trendArrayTest = timeSlicedTrendArray.filter(
                (item) => item.control_test_omit === 'test'
            )
            // const trendArrayOmit = timeSlicedTrendArray.filter(
            // (item) => item.control_test_omit === 'omit'
            // );
            // console.log('trendArrayControl is below')
            // console.log (trendArrayControl);
            // console.log('trendArrayTest is below')
            // console.log (trendArrayTest);
            // console.log('trendArrayOmit is below')
            // console.log (trendArrayOmit);
            const controlPeriodValuesArray = trendArrayControl.map((item) => {
                let severity = item[valueField]
                return severity
            })
            // console.log(controlPeriodValuesArray)
            const testPeriodValuesArray = trendArrayTest.map((item) => {
                let severity = item[valueField]
                return severity
            })
            // console.log(testPeriodValuesArray)
            const meanControlPeriod = mean(controlPeriodValuesArray)
            const meanTestPeriod = mean(testPeriodValuesArray)

            const pValue = permutationTest(
                controlPeriodValuesArray,
                testPeriodValuesArray
            )
            // console.log ('controlPeriodValuesArray = ' + controlPeriodValuesArray)
            // console.log ('testPeriodValuesArray =  '   + testPeriodValuesArray);
            // console.log ('meanControlPeriod = '        + meanControlPeriod);
            // console.log ('meanTestPeriod = '           + meanTestPeriod);
            // console.log ('pValue = '                   + pValue);
            const permutationTestResultsObject = {
                meanControlPeriod: meanControlPeriod,
                meanTestPeriod: meanTestPeriod,
                controlPeriodZeroDateLuxon: controlPeriodZeroDateLuxon,
                controlPeriodTerminalDateLuxon: controlPeriodTerminalDateLuxon,
                testPeriodZeroDateLuxon: testPeriodZeroDateLuxon,
                testPeriodTerminalDateLuxon: testPeriodTerminalDateLuxon,
                pValue: pValue,
            }

            return permutationTestResultsObject
        },
        compilePermutationTest(permutationTestResultsObject) {
            const pValueSetting = 0.1
            const {
                meanControlPeriod,
                meanTestPeriod,
                controlPeriodZeroDateLuxon,
                controlPeriodTerminalDateLuxon,
                testPeriodZeroDateLuxon,
                testPeriodTerminalDateLuxon,
                pValue,
            } = permutationTestResultsObject

            const controlZero = controlPeriodZeroDateLuxon.toFormat('LLL-dd-yy')
            const controlTerminal =
                controlPeriodTerminalDateLuxon.toFormat('LLL-dd-yy')
            const testZero = testPeriodZeroDateLuxon.toFormat('LLL-dd-yy')
            const testTerminal =
                testPeriodTerminalDateLuxon.toFormat('LLL-dd-yy')
            // console.log( 'meanControlPeriod '+ meanControlPeriod);
            // console.log( 'meanTestPeriod ' + meanTestPeriod);
            // console.log( controlPeriodZeroDateLuxon);
            // console.log( controlPeriodTerminalDateLuxon);
            // console.log( testPeriodZeroDateLuxon);
            // console.log( testPeriodTerminalDateLuxon);
            // console.log( 'pValue ' + pValue);
            switch (true) {
                case pValue > pValueSetting:
                    return {
                        text: 'No Big Change',
                        meanCP: meanControlPeriod,
                        meanTP: meanTestPeriod,
                        controlZero: controlZero,
                        controlTerminal: controlTerminal,
                        testZero: testZero,
                        testTerminal: testTerminal,
                        icon: 'fa-arrows-alt-h',
                        color: 'white',
                        note:
                            "We didn't find a real change in your averages (nerd speak -- no statistical difference) for the last " +
                            this.testPeriodWeeks +
                            ' weeks compared to prior ' +
                            this.controlPeriodWeeks +
                            ' weeks ',
                    }

                case pValue <= pValueSetting:
                    if (meanControlPeriod < meanTestPeriod) {
                        return {
                            text: 'Worsening',
                            meanCP: meanControlPeriod,
                            meanTP: meanTestPeriod,
                            controlZero: controlZero,
                            controlTerminal: controlTerminal,
                            testZero: testZero,
                            testTerminal: testTerminal,
                            icon: 'flag_circle',
                            color: 'yellow',
                            note:
                                'Looks like you had an increase for the last ' +
                                this.testPeriodWeeks +
                                ' weeks compared to prior ' +
                                this.controlPeriodWeeks +
                                ' weeks (yes, the increase was statistically significant)',
                        }
                    } else if (meanControlPeriod > meanTestPeriod) {
                        return {
                            text: 'Improvement',
                            meanCP: meanControlPeriod,
                            meanTP: meanTestPeriod,
                            controlZero: controlZero,
                            controlTerminal: controlTerminal,
                            testZero: testZero,
                            testTerminal: testTerminal,
                            icon: 'fa-chart-line',
                            color: 'green accent-2',
                            note:
                                'For the last ' +
                                this.testPeriodWeeks +
                                ' weeks, we found improvement over the prior ' +
                                this.controlPeriodWeeks +
                                ' weeks (the decrease in average severity was statistically significant)',
                        }
                    }
                    break
                default:
                    return {
                        text: 'Error',
                        meanCP: meanControlPeriod,
                        meanTP: meanTestPeriod,
                        icon: 'fa-exclamation-triangle',
                        color: 'red',
                        note: 'There was an error retrieving your data',
                    }
            }
        },

        createValuesObject(
            injectedProbCodeArray,
            streakFilterBool,
            severityFilterBool,
            minSev,
            minStreakD
        ) {
                                                                                    // console.log ('inside method createValuesObject injectedProbCodeArray, streakFilterBool, severityFilterBool, minSev, minStreakD are below 1-5')
                                                                                    // console.log(injectedProbCodeArray)
                                                                                    // console.log(severityFilterBool)
                                                                                    // console.log(streakFilterBool)
                                                                                    // console.log(minSev)
                                                                                    // console.log(minStreakD)

            let TF_ZeroISODate = ''
            let valuesArray = []
            let resultsArray = []
            let valuesObject = {}
            let streakCounter = 1
            let streakBucket = ''
            let streakTerminator = true
            let newStreakFlag = true

            const arrayToObject = (array, keyField) =>
                array.reduce((obj, item) => {
                    // console.log('line 777 inside arrayToObject below is sevLevl and uploadDateLocal')
                    // console.log(item.sevLevel)
                    // console.log(item.uploadDateLocal)
                    obj[item[keyField]] = {
                        value: item.sevLevel,
                        hasAudio: false,
                    }

                    return obj
                }, {})

            const testDatesConsecutive = (
                currentISODate,
                nextISODate,
                timeFrameZeroISODate
            ) => {
                const currentLuxon = DateTime.fromISO(currentISODate)
                const nextLuxon = DateTime.fromISO(nextISODate)
                const TF_ZeroLuxon = DateTime.fromISO(timeFrameZeroISODate)

                const tauCurrent = currentLuxon
                    .diff(TF_ZeroLuxon, 'days')
                    .toObject().days
                const tauNext = nextLuxon
                    .diff(TF_ZeroLuxon, 'days')
                    .toObject().days
                const deltaTau = tauNext - tauCurrent

                if (deltaTau === 1) {
                    return true
                } else {
                    return false
                }
            }

            const pushStreakObject = (element, streakBucket) => {
                let tempObject = {
                    uploadDateLocal: element.uploadDateLocal,
                    sevLevel: element.sevLevel,
                    hasAudio: element.hasAudio,
                    streakBucket: streakBucket,
                }
                resultsArray.push(tempObject)
                tempObject = {}
            }

            switch (true) {
                case severityFilterBool == false && streakFilterBool == false:
                    valuesObject = arrayToObject(
                        injectedProbCodeArray,
                        'uploadDateLocal'
                    )
                    return valuesObject
                // break;
                case severityFilterBool == true && streakFilterBool == false:
                    valuesArray = injectedProbCodeArray.filter(
                        (item) => item.sevLevel >= minSev
                    )
                    valuesObject = arrayToObject(valuesArray, 'uploadDateLocal')
                    return valuesObject
                // break;
                default: {
                    valuesArray = injectedProbCodeArray.filter(
                        (item) => item.sevLevel >= minSev
                    )
                    valuesArray = valuesArray.map((element, index, array) => {
                        // loop through the array that has been pre-filtered for severity level >= minimum severity level
                        // console.log(element.uploadDateLocal  + '  810 START  NEW ELEMENT *******  current element is ' + element.uploadDateLocal + ' at index #' + index +  ' newStreakFlag = ' + newStreakFlag)
                        // console.log(element.uploadDateLocal  + '  812 START  NEW ELEMENT *******  streakCounter     = ' + streakCounter + ' TF_ZeroISODate  = ' + TF_ZeroISODate)
                        TF_ZeroISODate = array[0]['uploadDateLocal']
                        // TF_ZeroISODate is the time frame Zero date of the first element in the array it serves as the anchor date for testing each element for consecutive dates using the Luxon diff method
                        if (streakTerminator === true) {
                            newStreakFlag = true
                            // the streakCounter and streakTerminator variables are initialized as TRUE for main "array.map" loop
                            // streakTerminator is the variable that "carries over" from one array.map loop to the next array.map loop and is used to toggle the newStreakFlag to true or false depending on the state of the prior loop
                            // if streakTerminator = FALSE then prior loop has "looked ahead" and determined that the currentISODate array item is extending the streak
                        } else {
                            newStreakFlag = false
                        }
                        for (
                            let miniLoopIndex = index;
                            miniLoopIndex < array.length - 1;
                            miniLoopIndex++
                        ) {
                            // the mini "for loop" starts at the current array.map index value and  moves forward from that index
                            // it loops over each future date item ( miniLoopIndex + 1 ) and evaluates if the currentISODate belongs to a streak
                            // the streakCounter and streakTerminator variables are also evaluated with respect to the array[i] item AKA "currentISODate"
                            // on each loop pass the current date (in the property 'uploadDateLocal' and the next future date in the array to the function "testConsecutiveDates"
                            let currentISODate =
                                array[miniLoopIndex]['uploadDateLocal']
                            let nextISODate =
                                array[miniLoopIndex + 1]['uploadDateLocal']
                            // console.log(element.uploadDateLocal  + '  864 ~~~~~~~~~~~~   SUB LOOP BEFORE TEST      ADJACENCY FUNCTION INPUTS:     index value from map function  = ' + index)
                            // console.log(element.uploadDateLocal + '   865 ~~~~~~~~~~~~   SUB LOOP BEFORE TEST      ADJACENCY FUNCTION INPUTS:      currentISODate  vs. nextISODate: ' + currentISODate  + ' ===> ' + nextISODate)
                            // console.log(element.uploadDateLocal + '   866  !!!!!!!!!!!!  SUB LOOP  AFTER TEST      BREAK BREAK BREAK   NON ADJACENT DATES  streakTerminator set to: ' + streakTerminator)
                            // console.log(element.uploadDateLocal + '   868  !!!!!!!!!!!!  SUB LOOP  AFTER TEST      BREAK BREAK BREAK   NON ADJACENT DATES  dateInStreak  = ' + dateInStreak )
                            if (
                                testDatesConsecutive(
                                    currentISODate,
                                    nextISODate,
                                    TF_ZeroISODate
                                ) === true
                            ) {
                                // the function "testConsecutiveDates" looks forward one array item and returns TRUE if the dates are consecutive false if they are non consecutive
                                //  if testDatesConsecutive returns TRUE then increment the streakCounter by 1 because the array item equal to "currentISODate"  being evaluated is extending the existing streak
                                streakCounter += 1
                                if (streakCounter >= 2) {
                                    // if streakCounter>=2 then set streakTerminator = FALSE because we know the currentISODate is adjacent to the next future array index item, so the array item @ miniLoopIndex is not the end of the streak
                                    streakTerminator = false
                                }
                            } else {
                                // if testDatesConsecutive returns FALSE the dates are NOT consecutive then by definition the date referenced by the "nextISODate" array item is the start of a new streak
                                // by definition the date referenced by the "currentISODate" array item is the end of the current streak
                                if (streakCounter === 1) {
                                    // for the currentISOdate if the streakCounter=1 then by definition it is a solo calendar date so set streakTerminator set to true then BREAK out of the loop because we don't need to evaluate the current streak; it's terminated
                                    streakTerminator = true
                                }
                                //  if the streakCounter >1 then by definition the value of streakCounter represents length of the streak that the currentISODate is a member
                                break
                            }
                        }
                        // the "for loop" is exited either by (a) determining the currentISODate is the end of streak --streakTerminator = TRUE (b)it's the last array item and their is no future array item to test for consecutive dates
                        // console.log(element.uploadDateLocal + '   876  ((((((((     BACK AT  MAIN LOOP AFTER SUB LOOP     BEFORE pushStreakObject     PAYLOAD  element date = ' + element.uploadDateLocal +  '  PAYLOAD  streakCounter = ' + streakCounter)
                        // console.log(element.uploadDateLocal + '   877  ((((((((     BACK AT  MAIN LOOP AFTER SUB LOOP     BEFORE pushStreakObject     PAYLOAD  element sevLevel = ' + element.sevLevel)
                        // console.log(element.uploadDateLocal + '   878  ((((((((     BACK AT  MAIN LOOP AFTER SUB LOOP     BEFORE pushStreakObject     PAYLOAD  streakBucket = ' + streakBucket)
                        // console.log(element.uploadDateLocal + '   882  ((((((((     BACK AT  MAIN LOOP AFTER SUB LOOP     BEFORE pushStreakObject     PAYLOAD  streakCounter = ' + streakCounter)
                        if (newStreakFlag === true) {
                            //  after exiting the mini "for loop" if the newStreakFlag = TRUE then the currentISODate which is the array item @ index is part of a streak of length = streakCounter so assign currentISODate to the streakBucket of size = streakCounter
                            streakBucket = streakCounter
                        } else {
                            streakBucket === 1
                        }
                        // console.log(element.uploadDateLocal + '   885  )))))))))    BACK AT  MAIN LOOP AFTER SUB LOOP     BEFORE pushStreakObject BEFORE newStreakFlag  and streakBucket logic     PAYLOAD  streakBucket = ' + streakBucket)
                        pushStreakObject(element, streakBucket)
                        //  after the currentISODate is assigned to a streakBucket then this currentISODate is pushed to the resultsArray
                        //  need to reset the streakCounter to 1 before incrementing to the next array.map item
                        streakCounter = 1
                        // console.log(element.uploadDateLocal + '   886  ---------    BACK AT  MAIN LOOP AFTER SUB LOOP     AFTER pushStreakObject AFTER newStreakFlag  and streakBucket logic  after reset streakCounter = ' + streakCounter)
                        // console.log(element.uploadDateLocal + '   888               MAIN LOOP AFTER SUB LOOP  AFTER pushStreakObject   jump to next element')
                    })
                    //  after the main array.map loop completes the resultsArray contains exactly the same number of elements as the original valuesArray but a new prooerty called streakBucket has been added to each array item
                    const valuesArray2 = resultsArray.filter(
                        (item) => item.streakBucket >= minStreakD
                    )
                    // since the user has defined a minimum streak days length, the resultsArray is filtered for the computed property minStreakD
                    // the calendarGroup components require a specific format to work; os the arrayToObject function converts the valuesArray2 to the valuesObject
                    valuesObject = arrayToObject(
                        valuesArray2,
                        'uploadDateLocal'
                    )
                    return valuesObject
                    //  each case of the switch statement must return the valuesObject so that the CalendarGroup component can render the Calendar dots properly
                }
            }
        },
    },

    mounted() {
        this.interval = setInterval(() => {
            if (this.spinnerVal === 90) {
                clearInterval(this.interval)
            }
            this.spinnerVal += 10
        }, 1000)

        this.$nextTick(function () {
            this.actionUpdateCalDisplayConfig(this.calendarDisplayConfig)
        })

        this.$nextTick(function () {
            this.actionSetInitTimeFrameStartMonthLuxonObject()
            this.selectStream({
                stream: this.defaultSymptomStream.value,
                count: this.calendarDisplayConfig.total,
            })
        })

        this.$nextTick(function () {
            this.actionGetProblemLogArray()
        })

        this.$nextTick(function () {
            this.setTimeFrameStartYearMonthMounted()
        })

        
    },

    beforeDestroy() {
        clearInterval(this.interval)
    },

    /*     destroyed() {
        this.$store.dispatch('reset')
    }, */
}
</script>

<style lang="scss" scoped>
.view-symptoms-container {
    background-color: #d1c4e9;
    min-height: 100%;
}
.view-symptoms-row {
    align-content: center;
}
.view-symptoms-col {
    align-content: center;
    // justify-content: center;
}
.view-symptoms-card {
    background-color: #1e88e5;
    align-content: center;
    border-width: 10px;
}
.blue-rounded-card {
    background-color: #1e88e5;
    padding-bottom: 1em;
    margin-bottom: 0em;
    padding-top: 0em;
    margin-top: 1em;
    padding-left: 1em;
    padding-right: 1em;
    border-width: 10px;
    border-radius: 24px;
    min-height: 100%;
}

.white-rounded-card {
    background-color: #ffffff;
    padding-bottom: 1em;
    margin-bottom: 1em;
    padding-top: 0em;
    margin-top: 1em;
    padding-left: 1em;
    padding-right: 1em;
    border-width: 10px;
    border-radius: 24px;
    min-height: 100%;
}

.trendDisplay {
    font-weight: bold;
    color: #ffffff;
    font-size: 1em;
    padding-bottom: 1em;
    padding-top: 1em;
    padding-left: 1em;
    padding-right: 0em;
}

.purple-rounded-card {
    background-color: #311b92;
    padding-top: 1em;
    margin-top: 1em;
    padding-bottom: 1em;
    margin-bottom: 1em;
    align-content: center;
    border-width: 10px;
    border-radius: 24px;
}
.js-calendar-selector {
    background-color: '';
}
ul.filter-hints {
    margin: 8px;
    padding: 0px;
    list-style-type: none;
}
.cal-legend {
    background-color: #d1c4e9;
    margin-top: 1em;
    padding-bottom: 1em;
    margin-bottom: 1em;
    width: 8em;
    font-size: 0.8em;
    text-align: left;
    text-decoration: '';
    text-transform: '';
    font-weight: bold;
    cursor: pointer;
}
</style>
