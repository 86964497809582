import * as home from './home'
import api from '../../api'

const { DateTime } = require('luxon')

export const types = {
    SET_DATE_CLICKED: 'SET_DATE_CLICKED',
    SET_DATA_TYPE: 'SET_DATA_TYPE',
    SET_FILTER_EXISTS: 'SET_FILTER_EXISTS',
    SET_FILTER_VISIBILITY: 'SET_FILTER_VISIBILITY',
    SET_FILTER_DATA: 'SET_FILTER_DATA',
    SET_FILTER_DATA_MAP: 'SET_FILTER_DATA_MAP',
    UPDATE_FILTER_DATA: 'UPDATE_FILTER_DATA',
    SET_VALUES: 'SET_VALUES',
    SET_MEMBER_PROBLEM_PROFILE: 'SET_MEMBER_PROBLEM_PROFILE',
    SET_PROBLEM_CODEBOOK: 'SET_PROBLEM_CODEBOOK',
    SET_PROBLEM_CODE_QUERY_SPECS: 'SET_PROBLEM_CODE_QUERY_SPECS',
    SET_PROBLEM_LOG_ARRAY: 'SET_PROBLEM_LOG_ARRAY',
    SET_COLOR_MAP: 'SET_COLOR_MAP',
    SET_KEYS: 'SET_KEYS',
    SET_SYMPTOMS: 'SET_SYMPTOMS',
    SET_MEDICATION_CODES: 'SET_MEDICATION_CODES',
    SET_USER_MEDICATIONS: 'SET_USER_MEDICATIONS',
    SET_SELF_CARE_CODES: 'SET_SELF_CARE_CODES',
    SET_STRESS_FACTORS: 'SET_STRESS_FACTORS',
    RESET: 'RESET',
    SET_CAL_DISPLAY_CONFIG: 'SET_CAL_DISPLAY_CONFIG',
    SET_INIT_TIMEFRAME_START_MONTH_LUXON_OBJECT:
        'SET_INIT_TIMEFRAME_START_MONTH_LUXON_OBJECT',
    SET_TARGET_MEMBER_PROBLEM_PROFILE: 'SET_TARGET_MEMBER_PROBLEM_PROFILE',
    SET_TARGET_PROBLEM_CODEBOOK: 'SET_TARGET_PROBLEM_CODEBOOK',
}

const initState = () => ({
    todaysDateLuxonObject: DateTime.now(),
    initTimeFrameStartMonthLuxonObject: {},

    dataType: null,
    dateClicked: 'TBD',
    filter: {
        exists: false,
        visible: 0,
        data: {},
        dataMap: {},
    },
    dialog: {
        visible: false,
        data: {},
    },
    values: [],
    problemCodebook: [],

    memberProblemProfile: [],
    problemLogArray: [],
    problemCodeQuerySpecs: {
        problemCodeField: 'SYM_BaseCode',
        dateKeyField: 'uploadDateLocal',
        valueField: 'sevLevel',
        problemCodebookField: 'SYM_Codebook_vID',
    },
    colorMap: {},
    keys: [],
    symptoms: [],
    severityButtons: [],
    medications: [],
    medicationCodes: [],
    selfCareCodes: [],
    stressFactors: [],
    calDisplayConfig: { total: 2, history: -1 },
    targetMemberProblemProfile: [],
    targetProblemCodebook: [],
})

export const module = {
    namespaced: true,
    state: initState(),

    mutations: {
        [types.SET_DATE_CLICKED](state, dateClicked) {
            state.dateClicked = dateClicked
        },
        [types.SET_DATA_TYPE](state, type) {
            state.dataType = type
        },
        [types.SET_FILTER_EXISTS](state, exists) {
            state.filter = { ...state.filter, exists }
        },
        [types.SET_FILTER_VISIBILITY](state, visible) {
            state.filter = { ...state.filter, visible }
        },
        [types.SET_FILTER_DATA](state, data) {
            state.filter = { ...state.filter, data }
        },
        [types.UPDATE_FILTER_DATA](state, data) {
            state.filter = {
                ...state.filter,
                data: { ...state.filter.data, ...data },
            }
        },
        [types.SET_FILTER_DATA_MAP](state, map) {
            state.filter = { ...state.filter, dataMap: map }
        },
        [types.SET_VALUES](state, values) {
            state.values = values
        },

        [types.SET_MEMBER_PROBLEM_PROFILE](state, profileSYM_ARR) {
            state.memberProblemProfile = profileSYM_ARR
        },
        [types.SET_PROBLEM_CODEBOOK](state, problemCodebook) {
            state.problemCodebook = problemCodebook
        },
        [types.SET_PROBLEM_LOG_ARRAY](state, problemLogArray12) {
            state.problemLogArray = problemLogArray12
        },
        [types.SET_PROBLEM_CODE_QUERY_SPECS](state, problemCodeQuerySpecs) {
            state.problemCodeQuerySpecs = problemCodeQuerySpecs
        },
        [types.SET_COLOR_MAP](state, map) {
            state.colorMap = map
        },
        [types.SET_KEYS](state, keys) {
            state.keys = keys
        },
        [types.SET_SYMPTOMS](state, symptoms) {
            state.symptoms = symptoms
        },
        [types.SET_USER_MEDICATIONS](state, meds) {
            state.medications = meds
        },
        [types.SET_MEDICATION_CODES](state, codes) {
            state.medicationCodes = codes
        },
        [types.SET_SELF_CARE_CODES](state, codes) {
            state.selfCareCodes = codes
        },
        [types.SET_STRESS_FACTORS](state, codes) {
            state.stressFactors = codes
        },
        [types.RESET]() {
            // const s = initState();
            // Oct 30 2021 commented out 3 lines below because it was wiping out the data stored in the Vuex
            // Object.keys(s).forEach(key => {
            // state[key] = s[key];
            // });
        },
        [types.SET_CAL_DISPLAY_CONFIG](state, calDisplayConfig) {
            state.calDisplayConfig = calDisplayConfig
        },
        [types.SET_START_MONTH](state, date) {
            state.startDate = date
        },
        [types.SET_INIT_TIMEFRAME_START_MONTH_LUXON_OBJECT](state, ITFSMLO) {
            state.initTimeFrameStartMonthLuxonObject = ITFSMLO
        },
        [types.SET_TARGET_MEMBER_PROBLEM_PROFILE](state, targetProblemProfile_SYM_ARR) {
            state.targetMemberProblemProfile = targetProblemProfile_SYM_ARR
        },
        [types.SET_TARGET_PROBLEM_CODEBOOK](state, targetProblemCodebook) {
            state.targetProblemCodebook = targetProblemCodebook
        },
    },

    actions: {
        // 1. when the "ViewSymptom" component is "created" the ACTION "selectStream" is executed
        // 2. "selectStream" calls Vuex mutations for the default data type "symptom" as declared in "data.calendar.js" array[1] element one
        // 3. "selectStream" then commits mutations for the ColorMap, the keys, & the filter visibility, then filterExists, defaultFilter, and filterMap
        // 4. "selectStream" then dispatches ACTION "initStream" that fetches the symptom codes from TigerGraph mutates
        // 5.  after "initStream" then ACTION "updateCalendar" executes which sets the TimeFrame then fetches the TigerGraph problemLogVertices

        async selectStream({ commit, dispatch }, { stream, count }) {
            commit(types.SET_DATA_TYPE, stream.dataType)
            commit(types.SET_COLOR_MAP, stream.colorMap)
            commit(types.SET_KEYS, stream.keys || [])
            commit(types.SET_FILTER_VISIBILITY, false)

            if (stream.defaultFilter !== null) {
                commit(types.SET_FILTER_EXISTS, true)
                commit(types.SET_FILTER_DATA, stream.defaultFilter)
                commit(types.SET_FILTER_DATA_MAP, stream.filterMap)
            } else {
                commit(types.SET_FILTER_EXISTS, false)
                commit(types.SET_FILTER_DATA, {})
                commit(types.SET_FILTER_DATA_MAP, {})
            }
            await dispatch('actionGetProbLogCodebook')
            await dispatch('updateCalendar', { count })
            await dispatch('actionGetProblemLogArray')
        },

        async actionGetProbLogCodebook({ rootState, commit, dispatch }) {
            // if (state.dataType === 'symptom') {
            try {
                // console.log('STEP 11A from module "calendar" calendar.js inside TRY async initStream')
                // Joe Stanley original API call to getSymptomCodes() is below
                // const res = await api.getSymptomCodes();
                // params below member_vID: 828454196 is a placeholder for future

                const mem_vID = rootState.home.memberProfile.Member_vID
                const params = { member_vID: mem_vID }
                // console.log('STEP 11A VUEX CALENDAR initstream params is below')
                // console.log (params)
                const response12 = await api.getTGProbLogCodebook12(params)
                // console.log('STEP 11B Inside async initStream')
                if (response12.status === 200) {
                    // console.log('STEP 11C Inside async initStream after status code 200')

                    // Joe Stanley original commit types.SET_SYMPTOMS is below
                    // commit(types.SET_SYMPTOMS, res.data);

                    // console.log('nodeServerResponse12.data.TGstatus is below')
                    // console.log(nodeServerResponse12.data.TGstatus)

                    // console.log('nodeServerResponse12.data.TGerror is below')
                    // console.log(nodeServerResponse12.data.TGerror)

                    // console.log('nodeServerResponse12.data.TGmessage is below')
                    // console.log(nodeServerResponse12.data.TGmessage)

                    // console.log('nodeServerResponse12.data.problemLogCodes is below')
                    // console.log(nodeServerResponse12.data.problemLogCodes)
                    commit(
                        types.SET_PROBLEM_CODEBOOK,
                        response12.data.problemLogCodes
                    )
                    // commit(types.SET_SYMPTOMS, nodeServerResponse12.data.problemLogCodes);
                    //   } else {
                    // dispatch(
                    // 'home/addError',
                    // 'Error occurred while retrieving symptom data.',
                    // {
                    // root: true,
                    // }
                    // )
                }
            } catch (err) {
                dispatch('home/addError', 'Error retrieving symptom data.', {
                    root: true,
                })
            }
        },

        initMedications({ commit }, { codes, medications }) {
            commit(types.SET_MEDICATION_CODES, codes)
            commit(types.SET_USER_MEDICATIONS, medications)
            if (medications.length > 0) {
                commit(types.SET_FILTER_DATA, { code: medications[0] })
            }
        },

        updateFilter({ commit, dispatch }, { data, update }) {
            commit(types.UPDATE_FILTER_DATA, data)
            if (update) dispatch('updateCalendar')
        },

        async updateCalendar({ commit, dispatch, state, getters, rootState }) {
            if (state.dataType) {
                commit(`home/${home.types.SET_LOADING}`, true, { root: true })
                if (rootState.home.memberProfile.Member_vID) {
                    const member_vID = rootState.home.memberProfile.Member_vID
                    const problemBaseCode = state.filter.data.code
                    const startYearMonth = getters.getterTimeFrameStartMonth
                    const endYearMonth = getters.getterTimeFrameEndMonth

                    const params = {
                        member_vID: member_vID,
                        problemBaseCode: problemBaseCode,
                        startMonth: startYearMonth,
                        endMonth: endYearMonth,
                    }
                    try {
                        const res = await api.getTGProblemLogVertices15(params)
                        if (res.status === 200) {
                            commit(
                                types.SET_VALUES,
                                res.data.valuesArray1 || {}
                            )
                        } else {
                            dispatch(
                                'home/addError',
                                'Error occurred while retrieving calendar data.',
                                { root: true }
                            )
                        }
                    } catch (err) {
                        dispatch(
                            'home/addError',
                            'CATCH Error occurred while retrieving calendar data.',
                            { root: true }
                        )
                    }
                    commit(`home/${home.types.SET_LOADING}`, false, {
                        root: true,
                    })
                } else {
                    console.log('ERROR CALENDAR.JS updateCalendar')
                }
            } else {
                console.log('error in state variable')
            }
        },

        async actionGetProblemLogArray({
            commit,
            dispatch,
            state,
            getters,
            rootState,
        }) {
            // if (state.dataType) {
            commit(`home/${home.types.SET_LOADING}`, true, { root: true })
            if (rootState.home.memberProfile.Member_vID) {
                const member_vID = rootState.home.memberProfile.Member_vID
                const startYearMonth = getters.getterTimeFrameStartMonth
                const endYearMonth = getters.getterTimeFrameEndMonth
                const problemCodeQuerySpecs = {
                    problemCodeField:
                        state.problemCodeQuerySpecs.problemCodeField,
                    dateKeyField: state.problemCodeQuerySpecs.dateKeyField,
                    valueField: state.problemCodeQuerySpecs.valueField,
                    problemCodebookField:
                        state.problemCodeQuerySpecs.problemCodebookField,
                }

                const params = {
                    member_vID: member_vID,
                    startMonth: startYearMonth,
                    endMonth: endYearMonth,
                    problemCodeQuerySpecs: problemCodeQuerySpecs,
                }
                try {
                    const res = await api.getTGAllProblemLogVertices19(params)
                    if (res.status === 200) {
                        const problemLogArray12 = res.data.problemLogArray12
                                                                                    // console.log('VUEX status 200 actionGetProblemLogArray')
                                                                                    // console.log(problemLogArray12)
                        commit(
                            types.SET_PROBLEM_LOG_ARRAY,
                            problemLogArray12 || []
                        )
                    } else {
                        dispatch(
                            'home/addError',
                            'Error occurred while retrieving calendar data.',
                            { root: true }
                        )
                    }
                } catch (err) {
                    dispatch(
                        'home/addError',
                        'CATCH Error occurred retrieving calendar data',
                        { root: true }
                    )
                }
                commit(`home/${home.types.SET_LOADING}`, false, {
                    root: true,
                })
                // } else {
                // console.log('<<<< STORE CALENDAR actionGetProblemLogArray')
            }
            // } else {
            // console.log('error in state variable')
            // }
        },
        
        async actionGetExtraYearProblemLogArray({
            commit,
            dispatch,
            state,
            getters,
            rootState,
        }) {
            const member_vID = rootState.home.memberProfile.Member_vID;
            let startLuxonObject = getters.getterTimeFrameStartMonthLuxonObject; 
                                                                                    // console.log('startLuxonObject.c.month is ' + startLuxonObject.c.month)
                                                                                    // console.log('startLuxonObject.c.day is '    + startLuxonObject.c.day)
                                                                                    // console.log('startLuxonObject.c.year is '   + startLuxonObject.c.year)
                                                                                    // console.log('startLuxonObject.c.hour is '   + startLuxonObject.c.hour)
                                                                                    // console.log('startLuxonObject.c.minute is ' + startLuxonObject.c.minute)
            startLuxonObject = startLuxonObject.plus({ months: -12 });
                                                                                    // console.log('subtract 12 months')
                                                                                    // console.log('startLuxonObject.c.month is '  + startLuxonObject.c.month)
                                                                                    // console.log('startLuxonObject.c.day is '    + startLuxonObject.c.day)
                                                                                    // console.log('startLuxonObject.c.year is '   + startLuxonObject.c.year)
                                                                                    // console.log('startLuxonObject.c.hour is '   + startLuxonObject.c.hour)
                                                                                    // console.log('startLuxonObject.c.minute is ' + startLuxonObject.c.minute)
            let endYearMonth = getters.getterTimeFrameEndMonth
                                                                                    // console.log('getters.getterTimeFrameEndMonth is ' + endYearMonth)
                                                                                    // console.log('subtract 1 month')
            // let endLuxonObject = getters.getterTimeFrameStartMonthLuxonObject.plus({ months: -1 });  
                                                                                    // console.log('endLuxonObject.c.month is '    + endLuxonObject.c.month)
                                                                                    // console.log('endLuxonObject.c.day is '      + endLuxonObject.c.day)
                                                                                    // console.log('endLuxonObject.c.year is '     + endLuxonObject.c.year)
                                                                                    // console.log('endLuxonObject.c.hour is '     + endLuxonObject.c.hour)
                                                                                    // console.log('endLuxonObject.c.minute is '   + endLuxonObject.c.minute)

            let startYearMonth = startLuxonObject.year + '-' + startLuxonObject.month;
                                                                                    console.log('startYearMonth is '    + startYearMonth)
            // endYearMonth = endLuxonObject.year + '-' + endLuxonObject.month;
                                                                                    console.log('endYearMonth is '    + endYearMonth)
            const problemCodeQuerySpecs = {
                problemCodeField:       state.problemCodeQuerySpecs.problemCodeField,
                dateKeyField:           state.problemCodeQuerySpecs.dateKeyField,
                valueField:             state.problemCodeQuerySpecs.valueField,
                problemCodebookField:   state.problemCodeQuerySpecs.problemCodebookField,
            }
            
            const params = {
                member_vID: member_vID,
                startMonth: startYearMonth,
                endMonth: endYearMonth,
                problemCodeQuerySpecs: problemCodeQuerySpecs,
            }
            try {
                const res19B = await api.getTGAllProblemLogVertices19(params)

                                                                                // console.log(res19B.status) 
                if (res19B.status === 200) {
                    const problemLogArray19B = res19B.data.problemLogArray12
                                                                                // console.log('VUEX status 200 actionGetExtraYearProblemLogArray')
                                                                                // console.log(problemLogArray19B[0]['pLVArray'])
                    // let originalProblemLogArray = state.problemLogArray
                    // let concatProblemLogArray = [...problemLogArray19B, ...originalProblemLogArray]
                                                                                // console.log('VUEX concatProblemLogArray is below actionGetExtraYearProblemLogArray')
                                                                                // console.log(concatProblemLogArray)
                    commit(
                        types.SET_PROBLEM_LOG_ARRAY,
                        problemLogArray19B|| []
                    )
                } else {
                    dispatch(
                        'home/addError',
                        'Error occurred while retrieving calendar data.',
                        { root: true }
                    )
                }
            } catch (err) {
                dispatch(
                    'home/addError',
                    'CATCH Error occurred retrieving calendar data',
                    { root: true }
                )
            }
            commit(`home/${home.types.SET_LOADING}`, false, {
                root: true,
            })
            // } else {
            // console.log('<<<< STORE CALENDAR actionGetProblemLogArray')
        // }
        // } else {
        // console.log('error in state variable')
        // }

        },


        actionUpdateProblemCode({ commit, dispatch }, problemCodeObject) {
            commit(types.UPDATE_FILTER_DATA, problemCodeObject)
            dispatch('updateCalendar')
        },
        actionUpdateDateClicked({ commit }, clickdatestring) {
            // actionUpdateDateClicked({ state, commit }, clickdatestring) {
            commit(types.SET_DATE_CLICKED, clickdatestring)
        },

        actionUpdateCalDisplayConfig({ commit }, calDisplayConfig) {
            // actionUpdateCalDisplayConfig({ state, commit }, calDisplayConfig) {
            commit(types.SET_CAL_DISPLAY_CONFIG, calDisplayConfig)
        },

        actionSetInitTimeFrameStartMonthLuxonObject({ state, commit }) {
            const ITFSMLO = state.todaysDateLuxonObject
                .startOf('month')
                .plus({ months: state.calDisplayConfig.history })
            commit(types.SET_INIT_TIMEFRAME_START_MONTH_LUXON_OBJECT, ITFSMLO)
        },

        actionShiftStartMonthLuxonObject(
            // { state, commit, dispatch, getters },
            { commit, getters },
            delta
        ) {
            const newStartMonth =
                getters.getterTimeFrameStartMonthLuxonObject.plus({
                    months: delta,
                })
            commit(
                types.SET_INIT_TIMEFRAME_START_MONTH_LUXON_OBJECT,
                newStartMonth
            )
        },

        async actionGetProfile_SYM({ commit, rootState }) {
            //   TIGERGRAPH -  FETCH the member's problem profile to populate the component Today's Story symptom display
            // console.log('VUEX #1 actionGetProfile_SYM')
            let problemProfile_SYM_ARR = []
            const params = {
                member_vID: rootState.home.memberProfile.Member_vID,
            }
            try {
                // console.log('VUEX #2 actionGetProfile_SYM')
                const res39 = await api.getTGProfile_SYM39(params)
                // console.log('VUEX #3 actionGetProfile_SYM res39 is below')
                // console.log(res39)

                // console.log('VUEX #4 actionGetProfile_SYM res39.status is below')
                // console.log(res39.status)

                // console.log('VUEX #5 actionGetProfile_SYM res39.data')
                // console.log(res39.data)

                // console.log('VUEX #6 actionGetProfile_SYM res39.data.error')
                // console.log(res39.data.error)
                const res39ResultsOBJ = res39.data.results[0]
                // console.log('VUEX #7 actionGetProfile_SYM res39ResultsOBJ is below')
                // console.log(res39ResultsOBJ)
                /* eslint-disable */
                const { profile_SYM_Array } = res39ResultsOBJ               
                                                                                    // console.log('VUEX #8 actionGetProfile_SYM     profile_SYM_Array[0] is below')
                                                                                    // console.log(profile_SYM_Array[0])
                    
                                                                                    // console.log('destructured profile_SYM_Array is below')
                                                                                    // console.log(profile_SYM_Array)
                                                                                    // console.log('profile_SYM_Array[0] is below')
                                                                                    // console.log(profile_SYM_Array[0])
               
                problemProfile_SYM_ARR = profile_SYM_Array

                                                                                    /*  memberProblemArray = this.memberProblemProfile.map((item) => {
                                                                                    const obj = {}

                                                                                        obj.SYMcodebook_vID = item['vertexID']
                                                                                        obj.SYM_label_short = item['SYM_label_short']
                                                                                        obj.SEVcodebook_vID = item['vertexID']
                                                                                        obj.sevLevel = '0'
                                                                                        obj.sortInteger = item['sortInteger']

                                                                                    return obj
                                                                                    }) */
            } catch (err) {
                    const status = err.res39 ? err.res39.status : 400
                                                                                console.log('code execution returns to ViewTodayProblems after error')
                    return status
            }
                                                                                    // eslint-disable-next-line
                                                                                    // console.log('VUEX #9 actionGetProfile_SYM     profileSYM_ARR is below')
                                                                                    // console.log(profileSYM_ARR)
            
            commit(types.SET_MEMBER_PROBLEM_PROFILE, problemProfile_SYM_ARR)
        },

        async actionGetTargetProbLogCodebook({ rootState, commit, dispatch }) {
            // if (state.dataType === 'symptom') {
            try {
                // console.log('STEP 11A from module "calendar" calendar.js inside TRY async initStream')
                // Joe Stanley original API call to getSymptomCodes() is below
                // const res = await api.getSymptomCodes();
                // params below member_vID: 828454196 is a placeholder for future

                const mem_vID = rootState.home.targetMemberProfile.Member_vID
                const params = { member_vID: mem_vID }
                // console.log('STEP 11A VUEX CALENDAR initstream params is below')
                // console.log (params)
                const response12B = await api.getTGProbLogCodebook12(params)
                // console.log('STEP 11B Inside async initStream')
                if (response12B.status === 200) {
                    // console.log('STEP 11C Inside async initStream after status code 200')

                    // Joe Stanley original commit types.SET_SYMPTOMS is below
                    // commit(types.SET_SYMPTOMS, res.data);

                    // console.log('nodeServerResponse12.data.TGstatus is below')
                    // console.log(nodeServerResponse12.data.TGstatus)

                    // console.log('nodeServerResponse12.data.TGerror is below')
                    // console.log(nodeServerResponse12.data.TGerror)

                    // console.log('nodeServerResponse12.data.TGmessage is below')
                    // console.log(nodeServerResponse12.data.TGmessage)

                    // console.log('nodeServerResponse12.data.problemLogCodes is below')
                    // console.log(nodeServerResponse12.data.problemLogCodes)
                    commit(
                        types.SET_TARGET_PROBLEM_CODEBOOK,
                        response12B.data.problemLogCodes
                    )
                    // commit(types.SET_SYMPTOMS, nodeServerResponse12.data.problemLogCodes);
                    //   } else {
                    // dispatch(
                    // 'home/addError',
                    // 'Error occurred while retrieving symptom data.',
                    // {
                    // root: true,
                    // }
                    // )
                }
            } catch (err) {
                dispatch('home/addError', 'Error retrieving symptom data.', {
                    root: true,
                })
            }
        },
        async actionGetTargetProfile_SYM({ commit, rootState }) {
                                                                                    //   TIGERGRAPH -  FETCH the member's problem profile to populate the component Today's Story symptom display
                                                                                    // console.log('VUEX #1 actionGetTargetProfile_SYM')
            let targetProblemProfile_SYM_ARR = []
            let targetMember_vID = rootState.home.targetMemberProfile;
                                                                                    // console.log('targetMember_vID.Member_vID is below')
                                                                                    // console.log(targetMember_vID.Member_vID)
            const params = { member_vID: targetMember_vID.Member_vID };
                                                                                    // console.log('params is below')
                                                                                    // console.log(params)
            try {
                // console.log('VUEX #2 actionGetProfile_SYM')
                const res39B = await api.getTGProfile_SYM39(params)
                // console.log('VUEX #3 actionGetProfile_SYM res39 is below')
                // console.log(res39)

                                                                                    // console.log('VUEX #4 actionGetTargetProfile_SYM res39B.status is below')
                                                                                    // console.log(res39B.status)

                                                                                    // console.log('VUEX #5 actionGetTargetProfile_SYM res39B.data')
                                                                                    // console.log(res39B.data)

                                                                                    // console.log('VUEX #6 actionGetTargetProfile_SYM res39B.data.error')
                                                                                    // console.log(res39B.data.error)
                const res39BResultsOBJ = res39B.data.results[0]
                                                                                    // console.log('VUEX #7 actionGetProfile_SYM res39ResultsOBJ is below')
                                                                                    // console.log(res39ResultsOBJ)
                /* eslint-disable */
                const { profile_SYM_Array } = res39BResultsOBJ               
                                                                                    // console.log('VUEX #8 actionGetProfile_SYM     profile_SYM_Array[0] is below')
                                                                                    // console.log(profile_SYM_Array[0])
                    
                                                                                    // console.log('destructured profile_SYM_Array is below')
                                                                                    // console.log(profile_SYM_Array)
                                                                                    // console.log('profile_SYM_Array[0] is below')
                                                                                    // console.log(profile_SYM_Array[0])
               
                targetProblemProfile_SYM_ARR = profile_SYM_Array

                                                                                    /*  memberProblemArray = this.memberProblemProfile.map((item) => {
                                                                                    const obj = {}

                                                                                        obj.SYMcodebook_vID = item['vertexID']
                                                                                        obj.SYM_label_short = item['SYM_label_short']
                                                                                        obj.SEVcodebook_vID = item['vertexID']
                                                                                        obj.sevLevel = '0'
                                                                                        obj.sortInteger = item['sortInteger']

                                                                                    return obj
                                                                                    }) */
            } catch (err) {
                    const status = err.res39 ? err.res39.status : 400
                                                                                console.log('code execution returns to ViewTodayProblems after error')
                    return status
            }
                                                                                    // eslint-disable-next-line
                                                                                    // console.log('VUEX #9 actionGetProfile_SYM     profileSYM_ARR is below')
                                                                                    // console.log(profileSYM_ARR)
            
            commit(types.SET_TARGET_MEMBER_PROBLEM_PROFILE, targetProblemProfile_SYM_ARR)
        },
           
    
    },

    getters: {

        getterTodaysDay:    (state) => state.todaysDateLuxonObject.day,
        getterTodaysMonth:  (state) => state.todaysDateLuxonObject.month,
        getterTodaysYear:   (state) => state.todaysDateLuxonObject.year,

        getterTodaysDateDisplayHUGE: (state) =>
            state.todaysDateLuxonObject.toLocaleString(DateTime.DATE_HUGE),

        getterTodaysDateDisplayMED: (state) =>
            state.todaysDateLuxonObject.toLocaleString(DateTime.DATE_MED),
        
        getterTodaysDateDisplaySHORT: (state) =>
            state.todaysDateLuxonObject.toLocaleString(DateTime.DATE_SHORT),
        
        getterTodaysDateDisplayISO: (state) =>
            state.todaysDateLuxonObject.toISODate(),
        
        getterTodaysDateUTC: (state) =>
            state.todaysDateLuxonObject.toUTC().toISO(),
        
        getterTodaysDateTimeLocalISO: (state) =>
            state.todaysDateLuxonObject.toISO(),

        getterTimeFrameStartMonthLuxonObject: (state) => {
            return state.initTimeFrameStartMonthLuxonObject
        },

        getterTimeFrameEndMonthLuxonObject: (state, getters) =>
            getters.getterTimeFrameStartMonthLuxonObject.plus({
                months: state.calDisplayConfig.total - 1,
            }),

        getterTimeFrameStartMonth: (state, getters) => {
            return getters.getterTimeFrameStartMonthLuxonObject
                .toISODate()
                .substring(0, 7)
        },

        getterTimeFrameEndMonth: (state, getters) => {
            return getters.getterTimeFrameEndMonthLuxonObject
                .toISODate()
                .substring(0, 7)
        },

        getterDateClicked: (state) => state.dateClicked,

        getterProblemLogExtract: (state) => {
            let PLA1 = (state.problemLogArray).map(item => {
                let tempOBJ = {};
                tempOBJ.uploadDateLocal = item.pLVArray[item.pLVArray.length - 1]['uploadDateLocal'];
                tempOBJ.SYM_BaseCode = item.pLVArray[item.pLVArray.length - 1]['SYM_BaseCode'];
                tempOBJ.sevLevel = item.pLVArray[item.pLVArray.length - 1]['sevLevel'];
                return tempOBJ
            })
            return PLA1
        },

        getterNormalizedProbLogArrayCodes: (state) => {
            const problemLogArrayProblemCodebookField = 'SYM_Codebook_vID';
            const normProbCodes = [];
            (state.problemLogArray).map((item) => {
                normProbCodes.push(item[problemLogArrayProblemCodebookField])
            })    
            return normProbCodes
        },

        getterBadDaySevLevelArray: (state, getters) => {
            return (getters.getterNormalizedProbLogArrayCodes).map(elt => {
                const temporaryObject = {};
                state.memberProblemProfile.forEach(profileProb => {
                    if (elt === profileProb.SYM_Codebook_vID ) {
                        temporaryObject['code'] = profileProb.code;
                        temporaryObject['badDaySevLevel'] = profileProb.badDaySevLevel;
                        temporaryObject['label_short'] = profileProb.label_short;
                        temporaryObject['SYM_Codebook_vID'] = profileProb.SYM_Codebook_vID;
                    }
                    
                })            
                return temporaryObject
            })
        },

        getterProblemProfileSorted: (state) => {
            let profileArray=(state.memberProblemProfile).map((problemObject)=>{
                const temporaryArray = [];
                const temporaryObject = {};                                                                                                                     
                let sevLevelVALUE ="";   
                    for (let keyPO in problemObject){   
                                                                                // console.log('keyPO is below')
                                                                                // console.log(keyPO)
                        if (keyPO.substring(0, 6) == "level_") {
                            let sevLevelOBJECT ={};
                            sevLevelVALUE = parseInt(keyPO.substring(6));
                            sevLevelOBJECT['level']=sevLevelVALUE;
                                                                                    // console.log('sevLevelValue is below')
                                                                                    // console.log(sevLevelVALUE)
                                                                                    // console.log('temporaryArray before push is below')
                                                                                    // console.log(temporaryArray)
                            temporaryArray.push(sevLevelOBJECT)
                                                                                    // console.log('temporaryArray after push is below')
                                                                                    // console.log(temporaryArray)
                        }
                        temporaryObject[keyPO] = problemObject[keyPO]
                        temporaryObject['severityARR'] = temporaryArray
                        temporaryObject['severitySelected']="0" 
                    }                                       
                    return temporaryObject
            })
            profileArray = profileArray.sort( (a, b) =>
                a.sortInteger > b.sortInteger ? 1 : -1
            )
            return profileArray
                                                                                    // const clonedArray = this.memberProblemProfile
                                                                                    // const sortedArray = clonedArray.sort((a, b) =>
                                                                                        // a.sortInteger > b.sortInteger ? 1 : -1
                                                                                    // )
                                                                                    // return sortedArray
        },
        getterTargetProblemProfileSorted: (state) => {
            let profileArray=(state.targetMemberProblemProfile).map((problemObject)=>{
                const temporaryArray = [];
                const temporaryObject = {};                                                                                                                     
                let sevLevelVALUE ="";   
                    for (let keyPO in problemObject){   
                                                                                // console.log('keyPO is below')
                                                                                // console.log(keyPO)
                        if (keyPO.substring(0, 6) == "level_") {
                            let sevLevelOBJECT ={};
                            sevLevelVALUE = parseInt(keyPO.substring(6));
                            sevLevelOBJECT['level']=sevLevelVALUE;
                                                                                    // console.log('sevLevelValue is below')
                                                                                    // console.log(sevLevelVALUE)
                                                                                    // console.log('temporaryArray before push is below')
                                                                                    // console.log(temporaryArray)
                            temporaryArray.push(sevLevelOBJECT)
                                                                                    // console.log('temporaryArray after push is below')
                                                                                    // console.log(temporaryArray)
                        }
                        temporaryObject[keyPO] = problemObject[keyPO]
                        temporaryObject['severityARR'] = temporaryArray
                        temporaryObject['severitySelected']="0" 
                    }                                       
                    return temporaryObject
            })
            profileArray = profileArray.sort( (a, b) =>
                a.sortInteger > b.sortInteger ? 1 : -1
            )
            return profileArray
                                                                                    // const clonedArray = this.memberProblemProfile
                                                                                    // const sortedArray = clonedArray.sort((a, b) =>
                                                                                        // a.sortInteger > b.sortInteger ? 1 : -1
                                                                                    // )
                                                                                    // return sortedArray
        } 
    }  
}
