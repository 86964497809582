<template>
    <v-container fluid class="symptomfilter-container">
        <v-row>
            <v-col>
                <v-card>
                    <v-card-subtitle class="black--text font-weight-bold pa-1"
                        >PROBLEM SELECTOR</v-card-subtitle
                    >
                    <v-chip-group column max="1">
                        <v-chip
                            v-for="problem in problemCodebook"
                            :key="problem.code"
                            active-class="yellow"
                            color="deep-purple darken-4"
                            rounded
                            small
                            filter
                            class="font-weight-bold"
                            text-color="white"
                            @click="
                                onProblemSelected(
                                    ($event,
                                    {
                                        code: problem.SYM_Codebook_vID,
                                        problemShortName: problem.short,
                                    })
                                )
                            "
                        >
                            <!-- <span class="text-caption black--text"> -->
                            {{ problem.short }}
                            <!-- </span> -->
                        </v-chip>
                    </v-chip-group>
                    <p></p>
                    <v-card-subtitle
                        class="black--text font-weight-bold pa-0 ma-0"
                        >SEVERITY SELECTOR</v-card-subtitle
                    >
                    <v-chip-group
                        column
                        mandatory
                        active-class="blue darken-1"
                        class="pa-0 ma-0"
                    >
                        <v-chip
                            v-for="severityButton in severityButtons"
                            :key="severityButton.severityLevel"
                            rounded
                            small
                            filter
                            class="font-weight-bold"
                            :color="severityButton.severityColor"
                            @click="
                                onSeveritySelected(
                                    ($event, severityButton.severityLevel)
                                )
                            "
                        >
                            {{ severityButton.severityLabel }}
                        </v-chip>
                    </v-chip-group>
                    <v-card-subtitle
                        class="black--text font-weight-bold pa-0 ma-0"
                        >CONSECUTIVE DAYS SELECTOR</v-card-subtitle
                    >
                    <v-chip-group column mandatory active-class="blue darken-1">
                        <v-chip
                            v-for="consecutiveDaysButton in consecutiveDaysButtons"
                            :key="consecutiveDaysButton.consecutiveDays"
                            rounded
                            small
                            filter
                            class="font-weight-bold"
                            @click="
                                onStreakLengthSelected(
                                    ($event,
                                    consecutiveDaysButton.consecutiveDays)
                                )
                            "
                        >
                            {{ consecutiveDaysButton.consecutiveDaysLabel }}
                            DAYS
                        </v-chip>
                    </v-chip-group>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'SymptomFilter',
    props: {
        colorMap: { type: Object, required: true },
        keys: { type: Array, required: true },
        filter: {
            type: Object,
            required: false,
            default() {
                return {}
            },
        },
        problemCodebook: { type: Array, required: true },
    },

    data() {
        return {
            severityButtons: [
                {
                    severityLevel: 0,
                    severityLabel: '0+',
                    severityColor: '#37c871',
                },
                {
                    severityLevel: 1,
                    severityLabel: '1+',
                    severityColor: '#00f3fa',
                },
                {
                    severityLevel: 2,
                    severityLabel: '2+',
                    severityColor: '#2e8fff',
                },
                {
                    severityLevel: 3,
                    severityLabel: '3+',
                    severityColor: '#f2fa00',
                },
                {
                    severityLevel: 4,
                    severityLabel: '4+',
                    severityColor: '#FF6D00',
                },
                {
                    severityLevel: 5,
                    severityLabel: '5',
                    severityColor: '#D50000',
                },
            ],
            consecutiveDaysButtons: [
                {
                    consecutiveDays: 1,
                    consecutiveDaysLabel: '1+',
                    consecutiveDaysColor: '#311B92',
                },
                {
                    consecutiveDays: 2,
                    consecutiveDaysLabel: '2+',
                    consecutiveDaysColor: '#311B92',
                },
                {
                    consecutiveDays: 3,
                    consecutiveDaysLabel: '3+',
                    consecutiveDaysColor: '#311B92',
                },
                {
                    consecutiveDays: 4,
                    consecutiveDaysLabel: '4+',
                    consecutiveDaysColor: '#311B92',
                },
                {
                    consecutiveDays: 7,
                    consecutiveDaysLabel: '7+',
                    consecutiveDaysColor: '#311B92',
                },
                // { consecutiveDays: 10, consecutiveDaysLabel: '10+', consecutiveDaysColor: '#7E57C2' },
            ],
        }
    },
    computed: {
        // symptoms() { return this.$store.state.calendar.symptoms },
        // selected() { return this.$store.state.calendar.filter.data.code },

        hasMap() {
            return Object.keys(this.colorMap).length > 0
        },
        hasKeys() {
            return this.keys.length > 0
        },
        items() {
            if (this.hasKeys) {
                return Object.keys(this.colorMap).map((key, idx) => ({
                    label: this.keys[idx],
                    color: this.colorMap[key],
                }))
            }
            return Object.keys(this.colorMap).map((key) => ({
                label: key,
                color: this.colorMap[key],
            }))
        },

        /*     valuesArray2 = valuesArray.filter

                                    const arrayToObject = (array, keyField) =>
                                        array.reduce((obj, item) => {
                                            
                                            obj[(item[keyField])] = {"value": item.sevLevel, "hasAudio": "false"}
                                            return obj
                                        }, {})

                                    valuesObject = arrayToObject(valuesArray, "uploadDateLocal")  
                                        // console.log('valuesObject is below')
                                        // console.log(valuesObject)  */
    },
    methods: {
        onSeveritySelected(sevLevel) {
            this.$emit('severity-selected', sevLevel)
        },

        onProblemSelected(problemCodeObject) {
            this.$emit('problem-selected', problemCodeObject)
        },

        onStreakLengthSelected(streakDays) {
            this.$emit('streak-days-selected', streakDays)
        },

        onSeverityEnd(num) {
            this.$emit('filter-update', {
                data: { severity: num },
                update: true,
            })
        },

        /*       onConsecutiveEnd(num) {
              this.$emit('filter-update', {
                data: { consecutive: num },
                update: true,
              });
            }, */
    },
}
</script>
<style lang="scss">
.symptomfilter-container {
    background-color: '';
}
.symptomfilter-card {
    background-color: '';
}

.symptom-filter-flex {
    background-color: '';
}

.symptom-filter-div {
    background-color: '';
}

ul.filter-hints {
    margin: 0;
    padding: 0;
    list-style-type: none;

    &,
    li {
        display: inline-block;
    }
}
</style>
