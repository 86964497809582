<template>
    <v-footer app absolute padless color="deep-purple darken-4" dark>
        <v-row>
            <v-col class="text-center mx-4 white--text" cols="12">
                <v-btn x-small color="transparent" @click="pushToHiddenTool">
                    ©     
                </v-btn>
                <v-btn x-small color="transparent" @click="pushToHiddenStaffPortal">
                    {{ new Date().getFullYear() }} Zettakal.com
                </v-btn>
                <!-- <router-link :to="{ path: 'view-hidden-staff-portal' }"> -->
                <!-- <v-icon dark color='blue darken-1'> -->
                <!-- fa-z  -->
                <!-- </v-icon> -->
                <!-- © {{ new Date().getFullYear() }} Zettakal.com -->
                <!-- </router-link> -->
            </v-col>
        </v-row>
    </v-footer>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'FooterBar',
    data() {
        return {}
    },

    computed: {
        ...mapState('home', {
            memberProfile: (state) => state.memberProfile,
        }),
    },
    methods: {
        pushToHiddenStaffPortal() {
            if (this.memberProfile.LEAP_ID == 'qdn42@ms101.me') {
                this.$router.push({ name: 'ViewHiddenStaffPortal' })
            }
        },
        pushToHiddenTool() {
            if (this.memberProfile.LEAP_ID == 'qdn42@ms101.me') {
                this.$router.push({ name: 'ViewHiddenTool' })
            }
        },
    },
}
</script>

<style></style>
