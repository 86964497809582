<template>
    <v-container v-if="hasMap" class="calendar-legend" fluid column>
        <!-- <v-row align="center" justify="center"> -->

        <span class="calendar-legend-text">
            <slot name="legend-text"> </slot>
        </span>
        <!-- <v-tooltip -->
        <!-- v-for="(item, idx) of items" -->
        <!-- :key="`legend-${idx}`" -->
        <!-- bottom -->
        <!-- > -->
        <!-- // eslint-disable-next-line no-unused-vars -->
        <!-- <template #activator="{ on, attrs }"> -->

        <v-btn
            v-for="(item, idx) of items"
            :key="`legend-${idx}`"
            x-small
            fab
            depressed
            rounded
            :style="{ backgroundColor: item.color }"
        >
            {{ item.label }}
        </v-btn>
        <!-- </template> -->
        <!-- </v-tooltip> -->
        <v-spacer></v-spacer>
        <!-- <v-avatar size="42" color="deep-purple darken-4"> -->
        <!-- <v-btn small fab depressed rounded color="grey darken-2" @click="toggleHiddenCalendars">  -->
        <!-- <v-icon color="white">fa-calendar-plus</v-icon> -->
        <!-- </v-btn> -->
        <!-- </v-avatar> -->
        <!-- </v-row> -->
    </v-container>
</template>

<script>
export default {
    name: 'CalendarLegend',
    props: {
        colorMap: {
            type: Object,
            required: true,
        },
        keys: {
            type: Array,
            required: true,
        },
    },
    computed: {
        hasMap() {
            return Object.keys(this.colorMap).length > 0
        },
        hasKeys() {
            return this.keys.length > 0
        },
        items() {
            if (this.hasKeys) {
                return Object.keys(this.colorMap).map((key, idx) => ({
                    label: this.keys[idx],
                    color: this.colorMap[key],
                }))
            }
            return Object.keys(this.colorMap).map((key) => ({
                label: key,
                color: this.colorMap[key],
            }))
        },
    },
    methods: {},
}
</script>

<style lang="scss" scoped>
.v-tooltip:last-child .calendar-legend-item {
    margin-right: 0 !important;
}
.calendar-legend-item {
    width: 2em;
    height: 2em;
    border-radius: 50%;
}
.calendar-legend-text {
    margin-top: 2em;

    font-size: 1em;
    text-align: center;
    // text-decoration: underline;
    text-transform: capitalize;
    // font-weight: bold;
    color: #000000;
}
</style>
