import axios from 'axios'
// const BASE_URL_NODESERVER = 'http://localhost:3000'

function loginSBAuthentication(email, password) {
    //  console.log('~~~  Step #2 CLIENT api.js loginSBAuthentication')
    return axios({
        method: 'post',
        url: '/api/route_Authenticate/loginSBAuthentication',
        // BASE_URL_NODESERVER +
        // '/api/route_Authenticate/loginSBAuthentication',
        data: { email, password },
    })
}

function logout() {
    const accessToken = localStorage.getItem('accessToken')
    const userAuthHeaderOBJ = 'Bearer ' + accessToken

    return axios({
        method: 'post',
        url: '/api/route_Authenticate/logout',
        // BASE_URL_NODESERVER +
        // '/api/route_Authenticate/logout',
        headers: { Authorization: userAuthHeaderOBJ },
    })
}

function validateAccessToken() {
    const accessToken = localStorage.getItem('accessToken')
    const userAuthHeaderOBJ = 'Bearer ' + accessToken
    // console.log('======  STEP# 8888A CLIENT api.js validateAccessToken')
    // console.log(
    //     '======  STEP# 8888B CLIENT api.js validateAccessToken   accessToken is below'
    // )
    // console.log(accessToken)
    // console.log(
    //     '======  STEP# 8888C CLIENT api.js validateAccessToken   userAuthHeaderOBJ is below'
    // )
    // console.log(userAuthHeaderOBJ)
    return axios({
        method: 'get',
        url: '/api/route_Authenticate/validAccessToken',
        // BASE_URL_NODESERVER +
        // '/api/route_Authenticate/validAccessToken',
        headers: { Authorization: userAuthHeaderOBJ },
    })
}

function getHCP_TGQueryList(params) {
    // console.log('STEP #3 from CLIENTSIDE api.js getHCP_TGQueryList function params object is below')
    // console.log(params)
    const accessToken = localStorage.getItem('accessToken')
    const userAuthHeaderOBJ = 'Bearer ' + accessToken

    return axios({
        method: 'get',
        url: '/api/route_HCPQueryList/list',
        headers: { Authorization: userAuthHeaderOBJ },
        params,
    })
}

function getTGMemberProfile(params) {
    // console.log(
    // '?????????????  STEP #10 from CLIENTSIDE api.js getTGMemberProfile param is below'
    // )
    // console.log(params)
    const accessToken = localStorage.getItem('accessToken')
    const userAuthHeaderOBJ = 'Bearer ' + accessToken

    return axios({
        method: 'get',
        url: '/api/route_TGMemberProfile/getTGProfile',
        headers: { Authorization: userAuthHeaderOBJ },
        params,
    })
}

function getTGMemberProfileItems15(params) {
    // console.log( '--- STEP #50 from CLIENTSIDE api.js getTGMemberProfileItems15 params is below')
    // console.log(params)
    const accessToken = localStorage.getItem('accessToken')
    const userAuthHeaderOBJ = 'Bearer ' + accessToken
    return axios({
        method: 'get',
        url: '/api/route_TGMemberProfile/getTGProfItems15',
        headers: { Authorization: userAuthHeaderOBJ },
        params,
    })
}

function getTGProblemLogVertices(params) {
    // console.log(*STEP #833 from CLIENTSIDE api.js getTGProblemVertices function params is below')
    // console.log(params)
    const accessToken = localStorage.getItem('accessToken')
    const userAuthHeaderOBJ = 'Bearer ' + accessToken

    return axios({
        method: 'get',
        url: '/api/route_TGPC/getProblemLogVertices',
        headers: { Authorization: userAuthHeaderOBJ },
        params,
    })
}

function getTGProfileDrugDisplay(params) {
    // console.log( '**** STEP #133 from CLIENTSIDE api.js getTGProfileDrugDisplay params is below')
    // console.log(params)
    const accessToken = localStorage.getItem('accessToken')
    const userAuthHeaderOBJ = 'Bearer ' + accessToken

    return axios({
        method: 'get',
        url: '/api/route_TGDrugLog/getMemberProfileDrugDisplay',
        headers: { Authorization: userAuthHeaderOBJ },
        params,
    })
}

/* function getDM_MemberInboxAndSent22(params) {
                                                                                    // console.log('STEP #3 from CLIENTSIDE api.js getDM_MemberInboxAndSent params is below')
                                                                                    // console.log(params)
    const accessToken = localStorage.getItem('accessToken')
    const userAuthHeaderOBJ = 'Bearer ' + accessToken

    return axios({
        method: 'get',
        url: '/api/route_TGMessages/getMessage_MemberInboxAndSent22',
        headers: { Authorization: userAuthHeaderOBJ },
        params,
    })
} */

function postDM_MemberToStaff24(params) {
    // console.log('STEP #3333 from CLIENTSIDE api.js postMessageMemberToStaff   params is below')
    // console.log(params)
    const accessToken = localStorage.getItem('accessToken')
    const userAuthHeaderOBJ = 'Bearer ' + accessToken

    return axios({
        method: 'post',
        url: '/api/route_TGMessages/postMessage_MemberToStaff24',
        headers: { Authorization: userAuthHeaderOBJ },
        params,
    })
}

/* function getDM_HDInboxAndSent28(params) {
                                                                                    // console.log('STEP #33 from CLIENTSIDE api.js getAllMessages function called')
    const accessToken = localStorage.getItem('accessToken')
    const userAuthHeaderOBJ = 'Bearer ' + accessToken

    return axios({
        method: 'get',
        url: '/api/route_TGMessages/getMessage_HDInboxAndSent28',
        headers: { Authorization: userAuthHeaderOBJ },
        params,
    })
} */

/* function postDM_StafftoMember29(params) {
                                                                                    // console.log('STEP #33 from CLIENTSIDE api.js postDMStafftoMember function params object is below')
                                                                                    // console.log(params)
    const accessToken = localStorage.getItem('accessToken')
    const userAuthHeaderOBJ = 'Bearer ' + accessToken

    return axios({
        method: 'post',
        url: '/api/route_TGMessages/postMessage_StafftoMember29',
        headers: { Authorization: userAuthHeaderOBJ },
        params,
    })
} */

// function getDM_Unread_Badge_Value13(params) {
//                                                                                     // console.log('STEP #3 from CLIENTSIDE api.js getDM_Unread_Badge_Values13 params is below')
//                                                                                     // console.log(params)
//     const accessToken = localStorage.getItem('accessToken')
//     const userAuthHeaderOBJ = 'Bearer ' + accessToken

//     return axios({
//         method: 'get',
//         url: '/api/route_TGMessages/getMessage_UnreadBadgeValue13',
//         headers: { Authorization: userAuthHeaderOBJ },
//         params,
//     })
// }

function getTGProbLogCodebook12(params) {
    // console.log('**** STEP #933 from CLIENTSIDE api.js getTGProbLogCodes params is below')                                                                 // )
    // console.log(params)
    const accessToken = localStorage.getItem('accessToken')
    const userAuthHeaderOBJ = 'Bearer ' + accessToken
    return axios({
        method: 'get',
        url: '/api/route_TGPC/getProblemCodebook12',
        headers: { Authorization: userAuthHeaderOBJ },
        params,
    })
}

function getTGProfile_SYM39(params) {
    // console.log('STEP #3333333 api.js getMemberSymptomArray params is below')
    // console.log(params)
    const accessToken = localStorage.getItem('accessToken')
    const userAuthHeaderOBJ = 'Bearer ' + accessToken

    return axios({
        method: 'get',
        url: '/api/route_TGCodebook_SYM/getProfile_SYM39',
        headers: { Authorization: userAuthHeaderOBJ },
        params,
    })
}

function postTGProbLog17(params) {
    // console.log('STEP #33 from CLIENTSIDE api.js postTGprobLog function called')
    const accessToken = localStorage.getItem('accessToken')
    const userAuthHeaderOBJ = 'Bearer ' + accessToken

    return axios({
        method: 'post',
        url: '/api/route_TGProblemLog/postTGProblemLog17',
        headers: { Authorization: userAuthHeaderOBJ },
        params,
    })
}

function getTGAllProblemLogVertices19(params) {
    // console.log('*** STEP #733 CLIENTSIDE api.js getTGAllProblemVertices19 params is below')
    // console.log(params)
    const accessToken = localStorage.getItem('accessToken')
    const userAuthHeaderOBJ = 'Bearer ' + accessToken

    return axios({
        method: 'get',
        url: '/api/route_TGPC/getAllProblemLogVertices19',
        headers: { Authorization: userAuthHeaderOBJ },
        params,
    })
}

function getTGMedAppt77(params) {
    //console.log('STEP #33 from CLIENTSIDE api.js getTGMedAppt')
    const accessToken = localStorage.getItem('accessToken')
    const userAuthHeaderOBJ = 'Bearer ' + accessToken

    return axios({
        method: 'get',
        url: '/api/route_TGMedAppt/getTGMedicalAppt77',
        headers: { Authorization: userAuthHeaderOBJ },
        params,
    })
}

function postTGMedAppt78(params) {
    // console.log('STEP #33 from CLIENTSIDE api.js postMedAppt function called')
    const accessToken = localStorage.getItem('accessToken')
    const userAuthHeaderOBJ = 'Bearer ' + accessToken

    return axios({
        method: 'post',
        url: '/api/route_TGMedAppt/postTGMedicalAppt78',
        headers: { Authorization: userAuthHeaderOBJ },
        params,
    })
}

function deleteTGMedAppt79(params) {
    // console.log('STEP #73 from CLIENTSIDE api.js deleteTGMedAppt79')
    // console.log(params)
    const accessToken = localStorage.getItem('accessToken')
    const userAuthHeaderOBJ = 'Bearer ' + accessToken

    return axios({
        method: 'post',
        url: '/api/route_TGMedAppt/deleteTGMedicalAppt79',
        headers: { Authorization: userAuthHeaderOBJ },
        params,
    })
}

function updateTGProfileBDSL39(params) {
                                                                                    // console.log('STEP #3 from CLIENTSIDE api.js updateDM_StatusReadToTrue37 params is below')
                                                                                    // console.log(params)
    const accessToken = localStorage.getItem('accessToken')
    const userAuthHeaderOBJ = 'Bearer ' + accessToken

    return axios({
        method: 'post',
        url: '/api/route_TGMemberProfile/updateProfileBDSL39',
        headers: { Authorization: userAuthHeaderOBJ },
        params,
    })
}

function getDM_Recent99(params) {
    // console.log('STEP #33 from CLIENTSIDE api.js getAllMessages function called')
    const accessToken = localStorage.getItem('accessToken')
    const userAuthHeaderOBJ = 'Bearer ' + accessToken

    return axios({
        method: 'get',
        url: '/api/route_TGMessages/getTGDM_Recent99',
        headers: { Authorization: userAuthHeaderOBJ },
        params,
    })
}

function postExerciseSessionARR81(params) {
    // console.log('STEP #33 from CLIENTSIDE api.js postExerciseSessionArray81')
    const accessToken = localStorage.getItem('accessToken')
    const userAuthHeaderOBJ = 'Bearer ' + accessToken

    return axios({
        method: 'post',
        url: '/api/route_TGExercisePlan/postExerciseSessionArray81',
        headers: { Authorization: userAuthHeaderOBJ },
        params,
    })
}

function getTGExercisePlan75(params) {
    // console.log('STEP #33 from CLIENTSIDE api.js getTGExercisePlan75')
    const accessToken = localStorage.getItem('accessToken')
    const userAuthHeaderOBJ = 'Bearer ' + accessToken

    return axios({
        method: 'get',
        url: '/api/route_TGExercisePlan/getTGExercisePlan75',
        headers: { Authorization: userAuthHeaderOBJ },
        params,
    })
}

function postTGHCP_obj62(params) {
    // console.log('STEP #33 from CLIENTSIDE api.js postTGHCP_obj62')
    const accessToken = localStorage.getItem('accessToken')
    const userAuthHeaderOBJ = 'Bearer ' + accessToken

    return axios({
        method: 'post',
        url: '/api/route_HCPQueryList/postTG_HCP_obj62',
        headers: { Authorization: userAuthHeaderOBJ },
        params,
    })
}

function getDM_MemberSent92(params) {
    // console.log('STEP #3 from CLIENTSIDE api.js getDM_MemberInboxAndSent92 params is below')
    // console.log(params)
    const accessToken = localStorage.getItem('accessToken')
    const userAuthHeaderOBJ = 'Bearer ' + accessToken

    return axios({
        method: 'get',
        url: '/api/route_TGMessages/getMessage_MemberSent92',
        headers: { Authorization: userAuthHeaderOBJ },
        params,
    })
}

function postDM_MemberToStaff94(params) {
    // console.log('STEP #3333 from CLIENTSIDE api.js postDM_MemberToStaff94 params is below')
    // console.log(params)
    const accessToken = localStorage.getItem('accessToken')
    const userAuthHeaderOBJ = 'Bearer ' + accessToken

    return axios({
        method: 'post',
        url: '/api/route_TGMessages/postMessage_MemberToStaff94',
        headers: { Authorization: userAuthHeaderOBJ },
        params,
    })
}

function getHDDM_MemberInbox91(params) {
    // console.log('STEP #3 from CLIENTSIDE api.js getHDDM_MemberInbox91 params is below')
    // console.log(params)
    const accessToken = localStorage.getItem('accessToken')
    const userAuthHeaderOBJ = 'Bearer ' + accessToken

    return axios({
        method: 'get',
        url: '/api/route_TGMessages/getMessage_MemberInbox91',
        headers: { Authorization: userAuthHeaderOBJ },
        params,
    })
}

function updateHDDM_StatusReadToTrue37(params) {
    // console.log('STEP #333A from CLIENTSIDE api.js updateHDDM_StatusReadToTrue37 params is below')
    // console.log(params)
    const { hddm_vid, hddm_statusbool, leapid } = params
    // console.log('STEP #333B from CLIENTSIDE api.js updateHDDM_StatusReadToTrue37 hddm_vid is below')
    // console.log(hddm_vid)
    // console.log('STEP #333C from CLIENTSIDE api.js updateHDDM_StatusReadToTrue37 hddm_statusbool is below')
    // console.log(hddm_statusbool)
    // console.log('STEP #333D from CLIENTSIDE api.js updateHDDM_StatusReadToTrue37 leapid is below')
    // console.log(leapid)
    const accessToken = localStorage.getItem('accessToken')
    const userAuthHeaderOBJ = 'Bearer ' + accessToken

    return axios({
        method: 'post',
        url: '/api/route_TGMessages/updateHDDM_ReadStatusToTrue37',
        headers: { Authorization: userAuthHeaderOBJ },
        data: { hddm_vid, hddm_statusbool, leapid },
    })
}
/* function postTG_EDGE_treatedBy63(params) {
                                                                                    console.log('STEP #3333 from CLIENTSIDE api.js postTG_EDGE_treatedBy63')
    const accessToken = localStorage.getItem('accessToken')
    const userAuthHeaderOBJ = 'Bearer ' + accessToken

    return axios({
        method: 'post',
        url: '/api/route_HCPQueryList/postTG_EDGEtreatedBy63',
        headers: { Authorization: userAuthHeaderOBJ },
        params,
    })
} */

/* function postTGMedicalApptEDGES80(params) {
                                                                                    console.log('STEP #73 from CLIENTSIDE api.js postTGMedicalApptEDGES80')
    const accessToken = localStorage.getItem('accessToken')
    const userAuthHeaderOBJ = 'Bearer ' + accessToken

    return axios({
        method: 'post',
        url: '/api/route_TGMedAppt/postTGMedicalApptEDGES80',
        headers: { Authorization: userAuthHeaderOBJ },
        params,
    })
} */

function getHDDM_UnreadBadge23(params) {
    // console.log('STEP #3 from CLIENTSIDE api.js getDM_Unread_Badge_Values13 params is below')
    // console.log(params)
    const accessToken = localStorage.getItem('accessToken')
    const userAuthHeaderOBJ = 'Bearer ' + accessToken

    return axios({
        method: 'get',
        url: '/api/route_TGMessages/getHDDM_UnreadBadge23',
        headers: { Authorization: userAuthHeaderOBJ },
        params,
    })
}

function getHelpDesk_Inbox95() {
    // console.log('STEP #3 from CLIENTSIDE api.js getHelpDesk_Inbox95')

    const accessToken = localStorage.getItem('accessToken')
    const userAuthHeaderOBJ = 'Bearer ' + accessToken

    return axios({
        method: 'get',
        url: '/api/route_TGMessages/getHelpDeskInbox95',
        headers: { Authorization: userAuthHeaderOBJ },
        // params,
    })
}
function getHelpDesk_Sent96() {
    // console.log('STEP #3 from CLIENTSIDE api.js getHelpDesk_Sent96')
    const accessToken = localStorage.getItem('accessToken')
    const userAuthHeaderOBJ = 'Bearer ' + accessToken

    return axios({
        method: 'get',
        url: '/api/route_TGMessages/getHelpDeskSent96',
        headers: { Authorization: userAuthHeaderOBJ },
        // params,
    })
}
function getHelpDesk_UnreadBadge21() {
    // console.log('STEP #3 from CLIENTSIDE api.js getHelpDesk_UnreadBadge21')
    const accessToken = localStorage.getItem('accessToken')
    const userAuthHeaderOBJ = 'Bearer ' + accessToken

    return axios({
        method: 'get',
        url: '/api/route_TGMessages/getHelpDesk_UnreadBadge21',
        headers: { Authorization: userAuthHeaderOBJ },
    })
}
function postSBHDDM_StaffToMember27(params) {
    // console.log('STEP #3333 from CLIENTSIDE api.js postDM_MemberToStaff94 params is below')
    // console.log(params)
    const accessToken = localStorage.getItem('accessToken')
    const userAuthHeaderOBJ = 'Bearer ' + accessToken

    return axios({
        method: 'post',
        url: '/api/route_TGMessages/postHDDMStaffToMember27',
        headers: { Authorization: userAuthHeaderOBJ },
        params,
    })
}
function postLIDM_MemberToStaff98(params) {
    // console.log('STEP #3333 from CLIENTSIDE api.js postDM_MemberToStaff94 params is below')
    // console.log(params)
    const accessToken = localStorage.getItem('accessToken')
    const userAuthHeaderOBJ = 'Bearer ' + accessToken

    return axios({
        method: 'post',
        url: '/api/route_TGMessages/postLIMessage_MemberToStaff98',
        headers: { Authorization: userAuthHeaderOBJ },
        params,
    })
}

function getLogin_Inbox97(params) {
    // console.log('STEP #3 from CLIENTSIDE api.js getLogin_Inbox97 params is below')
    // console.log(params)
    const accessToken = localStorage.getItem('accessToken')
    const userAuthHeaderOBJ = 'Bearer ' + accessToken

    return axios({
        method: 'get',
        url: '/api/route_TGMessages/getLI_Messages97',
        headers: { Authorization: userAuthHeaderOBJ },
        params,
    })
}

function postTGSupLog30(params) {
    // console.log('STEP #33 from CLIENTSIDE api.js postTGSupLog33')
    const accessToken = localStorage.getItem('accessToken')
    const userAuthHeaderOBJ = 'Bearer ' + accessToken

    return axios({
        method: 'post',
        url: '/api/route_TGMedAppt/postTGSupLog30',
        headers: { Authorization: userAuthHeaderOBJ },
        params,
    })
}

function postTGFakeBirthday18(params) {
                                                                                    // console.log('STEP #33 from CLIENTSIDE api.js postTGFakeBirthday18')
    const accessToken = localStorage.getItem('accessToken')
    const userAuthHeaderOBJ = 'Bearer ' + accessToken

    return axios({
        method: 'post',
        url: '/api/route_TGMemberProfile/postTGProfileFakeBirthday18', 
        headers: { Authorization: userAuthHeaderOBJ },
        params,
    })
}

// function getTGProfileNonSYM5(params) {
//                                                                                     console.log('STEP#10 client api.js getTGProfileNonSYM5 params is below')
//                                                                                     console.log(params)
//     const accessToken = localStorage.getItem('accessToken')
//     const userAuthHeaderOBJ = 'Bearer ' + accessToken

//     return axios({
//         method: 'get',
//         url: '/api/route_TGMemberProfile/getTGProfNonSYM5',
//         headers: { Authorization: userAuthHeaderOBJ },
//         params,
//     })
// }



export default {
    loginSBAuthentication,
    logout,
    validateAccessToken,
    getHCP_TGQueryList,
    getTGMemberProfile,
    getTGMemberProfileItems15,
    getTGProblemLogVertices,
    getTGProfileDrugDisplay,
    // getDM_MemberInboxAndSent22,
    postDM_MemberToStaff24,
    // getDM_HDInboxAndSent28,
    // postDM_StafftoMember29,
    // getDM_Unread_Badge_Value13,
    getTGProbLogCodebook12,
    getTGProfile_SYM39,
    postTGProbLog17,
    getTGAllProblemLogVertices19,
    getTGMedAppt77,
    postTGMedAppt78,
    deleteTGMedAppt79,
    updateTGProfileBDSL39,
    getDM_Recent99,
    postExerciseSessionARR81,
    getTGExercisePlan75,
    postTGHCP_obj62,
    getDM_MemberSent92,
    postDM_MemberToStaff94,
    getHDDM_MemberInbox91,
    updateHDDM_StatusReadToTrue37,
    getHDDM_UnreadBadge23,
    getHelpDesk_Inbox95,
    getHelpDesk_Sent96,
    getHelpDesk_UnreadBadge21,
    postSBHDDM_StaffToMember27,
    postLIDM_MemberToStaff98,
    getLogin_Inbox97,
    postTGSupLog30,
    // postTG_EDGE_treatedBy63,
    // postTGMedicalApptEDGES80
    postTGFakeBirthday18
}
