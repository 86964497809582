import Vue from 'vue'
import Vuex from 'vuex'

import * as home from './modules/home'
import * as calendar from './modules/calendar'
import * as appoint from './modules/appoint'

import api from '../api'

Vue.use(Vuex)

// Vue.config.devtools = true;
Vue.config.devtools = false;

export default new Vuex.Store({
    modules: {
        home: home.module,
        calendar: calendar.module,
        appoint: appoint.module,
    },

    actions: {
        reset({ commit }) {
            commit(`home/${home.types.RESET}`)
            commit(`calendar/${calendar.types.RESET}`)
        },

        async actionValidateAccessToken({ commit }) {
                                                                                    // console.log('STORE INDEX.JS BEFORE await api.validateAccessToken()')
            try {
                const response = await api.validateAccessToken()
                                                                                    // console.log('STORE INDEX.JS AFTER api.validateAccessToken() response.data is below')
                                                                                    // console.log(response.data.memberObject )
                                                                                    // console.log('STORE INDEX.JS AFTER  response.data.memberObject.memberRole  is below')
                                                                                    // console.log(response.data.role )
                if (response.status === 200) {
                    commit(`home/${home.types.SET_VALID_ACCESS_TOKEN}`, true)
                                                                                    // await dispatch('home/actionGetTGMemberProfile', null, {root:true} )
                                                                                 
                                                                                    // console.log('STORE INDEX.JS actionValidateAccessToken returns TRUE')
                                                                                    // console.log(response.data.memberObject.role )
                    return true
                } else {
                    return false
                }
            } catch (err) {
                return false
            }
        },

        async logout({ commit, dispatch }, { router }) {
            commit(`home/${home.types.SET_LOADING}`, true)
            try {
                const res = await api.logout()
                if (res.status === 200) {
                    dispatch('reset')
                    router.push({ path: '/login' })
                } else {
                    dispatch(
                        'home/addError',
                        'Unable to logout at the moment. Please try again later.'
                    )
                }
                commit(`home/${home.types.SET_LOADING}`, false)
            } catch (err) {
                dispatch(
                    'home/addError',
                    'Unable to logout at the moment. Please try again later.'
                )
                commit(`home/${home.types.SET_LOADING}`, false)
            }
        },
    },
})
