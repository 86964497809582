<template>
    <v-app>
        <v-container fluid class="v-content">
            <router-view :key="$route.fullPath"> </router-view>
        </v-container>
    </v-app>
</template>

<script>
export default {
    name: 'App',
}
</script>

<style lang="scss">
/*  @import "./styles/variables.scss"; */
#app {
    // font-family: "Avenir", Helvetica, Arial, sans-serif;
    // font-family: 'Montserrat', sans-serif;
    font-family: 'Maax+Regular', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    //this is where we use the variable
    // color: $primary;
    // color: $dk-purple;
    /* color: $dk-blue;  */
}
.v-toolbar__logo {
    display: flex;
    align-items: center;
    height: 100%;
    img {
        height: 35%;
        width: auto;
    }
}
.v-content {
    background-color: #d1c4e9;
    min-height: 100%;
}
</style>
