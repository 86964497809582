<template>
    <v-app-bar
      app  
      clipped-left
      dark
      nav
      flat  
      :dense="mobileDisplayBool"
      color='deep-purple darken-4'
      class="included" @click="() => null"
    > 
        <template v-if="mobileDisplayBool">
            <v-app-bar-nav-icon  @click.stop="expandTempDrawer" class="d-flex">
            </v-app-bar-nav-icon>
        
            <app-bar-alerts v-if="apptArrayBool"
                :countDownBarA_Specs            = "countDownBarA_Specs"
                :countDownBarA_Zone1ItemCount   = "countDownBarA_Zone1ItemCount"
                :countDownBarA_Zone2ItemCount   = "countDownBarA_Zone2ItemCount"
                :countDownBarA_Zone3ItemCount   = "countDownBarA_Zone3ItemCount"
            > 
            </app-bar-alerts> 

            <v-spacer></v-spacer>

            <v-badge 
                overlap
                :content="DM_Unread_Badge_Value"
                :value="showAlertHD"
                color="red accent-4"
                offset-x="8"
                offset-y="8"
            >
                <v-icon left color="white"  @click="toggleShowAlertHD">
                    fa-question
                </v-icon>
            </v-badge>
        </template>   

        <template v-else>        
            <img src="/img/zt9.png" alt="Zetta Logo" height="27">

            <v-spacer></v-spacer>
            
            <span class="text-button">
                {{todaysDateDisplayHUGE}}
            </span>

            <v-spacer></v-spacer>
            
            <app-bar-alerts v-if="apptArrayBool"
                :countDownBarA_Specs           = "countDownBarA_Specs"
                :countDownBarA_Zone1ItemCount  = "countDownBarA_Zone1ItemCount"
                :countDownBarA_Zone2ItemCount  = "countDownBarA_Zone2ItemCount"
                :countDownBarA_Zone3ItemCount  = "countDownBarA_Zone3ItemCount"
            > 
            </app-bar-alerts> 
    
            <v-spacer></v-spacer>

            <v-badge 
                overlap
                dark
                :content="DM_Unread_Badge_Value"
                :value="showAlertHD"
                :color="alertHelpDeskBadgeColor"
                offset-x="8"
                offset-y="8"
            >
                <v-icon left color="white"  @click="toggleShowAlertHD">
                    fa-question
                </v-icon>
            </v-badge>
            <template v-if = "superuser">
                <v-chip
                    small
                    class="ma-2"
                    color="green"
                    text-color="white"
                    @click="pushToHiddenStaffPortal"
                >
                    {{ HelpDesk_Unread_Badge_Value }}
                </v-chip>
            </template>

        </template>
    </v-app-bar>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import api from '@/api'
import * as home                        from '@/store/modules/home';
import AppBarAlerts                     from '@/components/AppBarAlerts.vue';
import DialogMedApptList                from '@/components/DialogMedApptList.vue';
import { DateTime}                      from 'luxon';

const unreadHelpDeskDMSound = require("../assets/bell4.mp3")
// eslint-disable-next-line no-unused-vars
const unreadHelpDeskDMSoundOBJ = new Audio(unreadHelpDeskDMSound)

export default {
    name: 'AppBar',
    components: {
        AppBarAlerts,
       
    },

    props: {
        mobileDisplayBool: { type: Boolean, required: false }
    },

    data() {
        return {
            alertHelpDeskBadgeColor: "red accent-4",

            countDownBarA_Specs: {
                ID: 'A1',
                icon: "fa-user-md",
                navLinkTo: "/home/view-medical-appointments",
                zone1DaysInWindow: 2,
                zone2DaysInWindow: 7,
                zone3DaysInWindow: 30,
                zone1BadgeColor: "red accent-4",
                zone2BadgeColor: "red accent-4",
                zone3BadgeColor: "red accent-4",
                zone1ChipColor: "pink lighten-3",
                zone2ChipColor: "yellow lighten-1",
                zone3ChipColor: "white"
            },

            todayStuffArray: [
                { item: 'order vitamins', completedBOOL: false },
                { item: 'play BrainHQ', completedBOOL: false },
                { item: 'Brain Age book day 9 foods', completedBOOL: false },
                { item: "Dr. Z's hand stretch", completedBOOL: false },
            ],
            dialogTodayStuffDISPLAY: false
        }
    },

    computed: {
        ...mapState('calendar', {
            todaysDateLuxonObject: state => state.todaysDateLuxonObject
        }),
        ...mapState('home', {
            memberProfile: (state) => state.memberProfile,
        }),

        ...mapState('home', {
            user: state => state.user,
            mobileDrawerOpen: state => state.tempDrawer,
            member_vID: state => state.memberProfile.Member_vID,
            DM_Unread_Badge_Value: state => state.DM_Unread_Badge_Value,
            sessionFlagHelloBOOL: state => state.sessionFlagHelloBOOL,
            HelpDesk_Unread_Badge_Value: state => state.HelpDesk_Unread_Badge_Value
        }),

        ...mapState('appoint', {
            apptArray: state => state.apptArray,

        }),

        ...mapGetters('calendar', [
            'getterTodaysDateDisplayHUGE',
            'getterTodaysDateDisplayMED',
            'getterTodaysDateDisplaySHORT'
        ]),

        superuser() {
            if (this.member_vID == "828454196") {
                return true
            } else {
                return false
            }
        },
        showAlertHD() {
            const showDM_UnreadBadge = this.DM_Unread_Badge_Value > 0 ? true : false
            return showDM_UnreadBadge
        },

        playAlertHD() {
            const playAlertSoundBOOL = this.DM_Unread_Badge_Value > 0 ? true : false
            if (playAlertSoundBOOL) {
                this.playAlertUnreadHelpDeskDM
            }
            return playAlertSoundBOOL
        },

        todaysDateDisplayHUGE() { return this.getterTodaysDateDisplayHUGE },
        todaysDateDisplayMED() { return this.getterTodaysDateDisplayMED },
        todaysDateDisplaySHORT() { return this.getterTodaysDateDisplaySHORT },

        apptArrayBool() {
            if ((this.apptArray).length >= 0) {
                return true
            } else return false
        },

        apptArrayWithDiff() {
            const appointmentArray2 = this.apptArray.map(item => {
                let tempOBJ = {};

                let daysDifference =
                    this.calculateDifferenceDays((DateTime.fromISO(item.ApptDateISOLocal)),
                        (this.todaysDateLuxonObject))

                tempOBJ.ApptDateISOLocal = item.ApptDateISOLocal;
                tempOBJ.ApptDateLocal = item.ApptDateLocal;
                tempOBJ.ApptDateUTC = item.ApptDateUTC;
                tempOBJ.ApptMilliseconds = item.ApptMilliseconds;
                tempOBJ.ApptStatus = item.ApptStatus;
                tempOBJ.ApptType = item.ApptType;
                tempOBJ.HCPAddress1 = item.HCPAddress1;
                tempOBJ.HCPCity = item.HCPCity;
                tempOBJ.HCPFirstName = item.HCPFirstName;
                tempOBJ.HCPLastName = item.HCPLastName;
                tempOBJ.HCPState = item.HCPState;
                tempOBJ.differenceInDaysToday = daysDifference

                return tempOBJ
            })
            return appointmentArray2
        },

        countDownBarA_Zone1Array() {
            return this.filterByZone(
                this.apptArrayWithDiff,
                this.countDownBarA_Specs.zone1DaysInWindow
            )
        },

        countDownBarA_Zone2Array() {
            return this.filterByZone(
                this.apptArrayWithDiff,
                this.countDownBarA_Specs.zone2DaysInWindow
            )
        },

        countDownBarA_Zone3Array() {
            return this.filterByZone(
                this.apptArrayWithDiff,
                this.countDownBarA_Specs.zone3DaysInWindow
            )
        },

        countDownBarA_Zone1ItemCount() {
            return this.countDownBarA_Zone1Array.length
        },

        countDownBarA_Zone2ItemCount() {
            return this.countDownBarA_Zone2Array.length -
                this.countDownBarA_Zone1Array.length
        },

        countDownBarA_Zone3ItemCount() {
            return this.countDownBarA_Zone3Array.length -
                this.countDownBarA_Zone2Array.length
        },
    },

    methods: {
        ...mapActions('home', [
            'openTempDrawer',
            'actionGetDMbyMember',
            'actionGetHDDM_UnreadBadgeValue23',
            'actionSet_SessionFlagHelloBOOL',
            'actionGetHelpDesk_UnreadBadgeValue21'
        ]),

        ...mapActions('appoint', [
            'actionGetMedAppt77'
        ]),

        expandTempDrawer() {
            if (this.mobileDrawerOpen = true) {
                this.openTempDrawer()
            } else {
                alert('expandTempDrawer cancelled')
            }
        },

        include() {
            return [document.querySelector('.included')]
        },

        calculateDifferenceDays(luxonObject1, luxonObject2) {
            let dayDiff = (
                ((luxonObject1.diff(luxonObject2, 'days'))
                    .toObject())
                    .days)
                .toFixed(0);
            return parseInt(dayDiff)
        },

        toggleShowAlertHD() {
            this.showAlertHD = false;
            this.$router.push({ name: 'ViewHelpDesk' })
        },

        filterByZone(injectedArray, daysInWindow) {
            const filteredArray =
                injectedArray.filter(item => (item.differenceInDaysToday >= 0) &&
                    (item.differenceInDaysToday <= daysInWindow))
            return filteredArray
        },

        logout() {
            this.$store.dispatch('logout', { router: this.$router })
        },


        playAlertUnreadHelpDeskDM() {
            if (this.sessionFlagHelloBOOL === false &&
                this.DM_Unread_Badge_Value > 0
            ) {
                unreadHelpDeskDMSoundOBJ.play()
                this.actionSet_SessionFlagHelloBOOL(true)
            }
        },

        dialogTodayStuffEVALUATE() {
            if ((this.countDownBarA_Zone1Array).length > 0) {
                return this.dialogTodayStuffDISPLAY = true
            } else {
                return this.dialogTodayStuffDISPLAY = false
            }
        },
        pushToHiddenStaffPortal() {
            if (this.memberProfile.LEAP_ID == 'qdn42@ms101.me') {
                this.$router.push({ name: 'ViewHiddenStaffPortal' })
            }
        }
    },

    mounted() {
        this.actionGetMedAppt77()
        this.actionGetHDDM_UnreadBadgeValue23()
        this.actionGetHelpDesk_UnreadBadgeValue21()
        this.$nextTick(function () {
            const waitForUnreadBadgeValue13 =
                setTimeout(() => {
                    this.playAlertUnreadHelpDeskDM()
                }, 1000);
        })

        this.$nextTick(function () {
            const waitForUnreadBadgeValue14 =
                setTimeout(() => {
                    this.dialogTodayStuffEVALUATE()
                }, 500);
        })
    }
}

</script>

<style lang="scss">

.buttonText {
    font-weight: bold;
    color: #000000;
    font-size: 1.6em;
}
.appBarYellowFlag {
    color: #f7eb0c;
    font-weight: bold;
}
.appBarRedFlag {
    color: #f70c0c;;
    font-weight: bold;
}
.v-toolbarLogoBig {
    display: flex;
    align-items: center;
    height: 100%;
  
  img {
    height: 35%;
    width: auto;
  }
}

.v-toolbarLogoSmall {
    display: flex;
    align-items: center;
    height: 100%;
  
  img {
    height: 20%;
    width: auto;
  }
}
</style>