/* ViewLogin accepts a LEAPID that includes the @ extension formatted like an
email and a password @click will call the "loginSB" function which takes the
LEAPID with password and calls a Vuex HOME module function "setUserFromSB" that
authenticates the LEAPID and password against the member table in Supabase If
authentication is successful Supabase returns to the client an access token
which is placed in local storage The access token is checked on every new page
load by VueRouter "navigation guards" After SB authentication, the Vuex HOME
module "SetMemberProfileFromTG" is called to retrieve the member profile from
TigerGraph If there is a successful TigerGraph authentication, then a TigerGraph
object called memberProfile is committed to Vuex Thus the member has a Supabase
object called "user" and also a TigerGraph object called "member profile" stored
in Vuex HOME */
<template>
    <v-app>
        <v-main>
            <v-container fluid fill-height class="login-container">
                <v-row justify="center">
                    <v-col xs="10" sm="8" md="6" lg="3" xl="3">
                        <v-card color="white" class="ma-1">
                            <v-toolbar color="deep-purple darken-4" flat>
                                <v-toolbar-title class="v-toolbar__logo">
                                    <img src="/img/zt9.png" alt="Zettakal" />
                                </v-toolbar-title>
                            </v-toolbar>
                            <v-card-text class="py-3 px-4">
                                <v-alert
                                    v-model="invalid"
                                    class="mb-3"
                                    type="error"
                                    dismissible
                                >
                                    Sorry, the username / password is incorrect
                                </v-alert>
                                <v-alert
                                    v-model="serverError"
                                    class="mb-3"
                                    type="error"
                                    dismissible
                                >
                                    Sorry, there is a server error. Please send
                                    a message to the Help Desk
                                </v-alert>
                                <v-form ref="form">
                                    <v-text-field
                                        v-model="email"
                                        :rules="[rules.required, rules.email]"
                                        :validate-on-blur="true"
                                        prepend-icon="person"
                                        name="email"
                                        label="Username"
                                        type="text"
                                        autofocus
                                    ></v-text-field>
                                    <v-text-field
                                        v-model="password"
                                        :type="show ? 'text' : 'password'"
                                        :append-icon="
                                            show
                                                ? 'visibility'
                                                : 'visibility_off'
                                        "
                                        :rules="[rules.required]"
                                        :validate-on-blur="true"
                                        prepend-icon="lock"
                                        name="password"
                                        label="Password"
                                        @click:append="show = !show"
                                        @keyup.native.enter="login"
                                    ></v-text-field>
                                </v-form>
                            </v-card-text>
                            <v-card-actions class="justify-center pb-3">
                                <!-- <v-btn 
                                    text
                                    color="deep-purple darken-4"
                                    @click="toggleShowLoginDM_create"
                                >
                                    <span class="text-center black--text">
                                        ID, Password or Login Help
                                    </span>
                                </v-btn> -->

                                <v-btn
                                    small
                                    class="ma-1"
                                    outlined
                                    color="deep-purple darken-4"
                                    @click="openShowLoginDM_create"
                                >
                                    <span class="helpbutton"> Login Help </span>
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn
                                    small
                                    class="ma-1"
                                    outlined
                                    color="deep-purple darken-4"
                                    @click="openShowID_Input"
                                >
                                    <span class="helpbutton">
                                        Get Messages
                                    </span>
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn
                                    dark
                                    small
                                    depressed
                                    color="deep-purple darken-4"
                                    @click="loginSB"
                                >
                                    <span class="text-center white--text">
                                        Login
                                    </span>
                                </v-btn>
                            </v-card-actions>
                        </v-card>

                        <v-dialog v-model="showLoginDM_create" width="520">
                            <v-row
                                wrap
                                no-gutters
                                align="center"
                                justify="center"
                            >
                                <v-col xs="12" sm="12" md="12" lg="12" xl="12">
                                    <v-card
                                        color="white"
                                        class="pa-0 ma-2"
                                        style="max-width: 500px"
                                    >
                                        <v-system-bar
                                            color="blue darken-1"
                                            window
                                            dark
                                        >
                                            <span>
                                                Send a message to the Help Desk
                                            </span>
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                x-small
                                                depressed
                                                color="transparent"
                                                @click="hideShowLoginDM_create"
                                            >
                                                <v-icon color="white">
                                                    mdi-close-circle
                                                </v-icon>
                                            </v-btn>
                                        </v-system-bar>

                                        <v-text-field
                                            v-model="memberInputZettalID"
                                            filled
                                            dense
                                            :rules="[
                                                rules.required,
                                                rules.email,
                                            ]"
                                            prepend-icon="person"
                                            name="Help Desk Message"
                                            label="Enter Zettakal ID  (like: qdn37@ms101.me)"
                                            type="Message:"
                                        >
                                        </v-text-field>
                                        <v-btn
                                            dark
                                            small
                                            depressed
                                            color="deep-purple darken-4"
                                            @click="toggleShowLoginForgotID"
                                        >
                                            Lost Zettakal ID or password
                                        </v-btn>
                                        <v-textarea
                                            v-model="loginDM_content"
                                            counter
                                            label="Text"
                                            :value="value"
                                            auto-grow
                                        >
                                        </v-textarea>

                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                small
                                                dark
                                                color="deep-purple darken-4"
                                                @click="sendLoginDMtoHelpDesk"
                                            >
                                                SEND MESSAGE TO HELP DESK
                                            </v-btn>
                                        </v-card-actions>
                                        <v-system-bar
                                            color="blue darken-1"
                                            window
                                            dark
                                        >
                                        </v-system-bar>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-dialog>

                        <v-dialog v-model="showLoginForgotID" width="300">
                            <v-card color="white" pa-2 ma-2>
                                <v-system-bar color="blue darken-1" window>
                                    <!-- <small>Your temporary Zettakal ID</small> -->
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        x-small
                                        depressed
                                        color="transparent"
                                        @click="toggleShowLoginForgotID"
                                    >
                                        <v-icon color="white">
                                            mdi-close-circle
                                        </v-icon>
                                    </v-btn>
                                </v-system-bar>
                                <p>
                                    <span class="text-h6">
                                        {{ tempZettakalID }}
                                    </span>
                                </p>
                                <p>
                                    <v-btn
                                        class="ma-2"
                                        color="deep-purple darken-4"
                                        dark
                                        @click="openShowTempZettakalID_confirm"
                                    >
                                        <v-icon dark right>
                                            mdi-arrow-up-bold-box-outline
                                        </v-icon>
                                        &nbsp; Use this temporary Zettakal ID
                                    </v-btn>
                                </p>
                            </v-card>
                        </v-dialog>

                        <v-dialog
                            v-model="showTempZettakalID_confirm"
                            width="300"
                        >
                            <v-card color="white" class="ma-2 pa-0">
                                <v-system-bar color="blue darken-1" window>
                                </v-system-bar>
                                <v-btn
                                    large
                                    fab
                                    depressed
                                    color="blue darken-1"
                                    dark
                                >
                                    <v-icon> fa-pencil </v-icon>
                                </v-btn>

                                <p>
                                    Please write down or screenshot your
                                    temporary Zettakal ID below so you can get
                                    your messages later
                                </p>
                                <p>
                                    <span class="text-h5 black--text">
                                        {{ tempZettakalID }}
                                    </span>
                                </p>

                                <v-btn
                                    small
                                    color="deep-purple darken-4"
                                    dark
                                    @click="hideTempZettakalID_confirm"
                                >
                                    OK I SAVED IT
                                </v-btn>
                                <v-system-bar color="blue darken-1" window>
                                </v-system-bar>
                            </v-card>
                        </v-dialog>

                        <v-dialog v-model="showLoginDM_confirm" width="300">
                            <v-card color="white" class="ma-auto pa">
                                <v-system-bar color="blue darken-1" window>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        x-small
                                        depressed
                                        color="transparent"
                                        @click="toggleShowLoginDM_confirm"
                                    >
                                        <v-icon color="white">
                                            mdi-close-circle
                                        </v-icon>
                                    </v-btn>
                                </v-system-bar>
                                <p>
                                    Your message was sent to the Help Desk on:
                                </p>
                                <p class="font-weight-bold">
                                    {{ confirmDateTimeDisplay }}
                                </p>
                                <p>The Help Desk will reply within 24 hours.</p>
                                <p>
                                    To get your messages use the Zettakal ID
                                    below:
                                </p>
                                <p>
                                    <span class="text-h4">
                                        {{
                                            paramsPOST_LoginLIDM_MemberToStaff.sender_member_vid
                                        }}
                                    </span>
                                </p>
                            </v-card>
                        </v-dialog>

                        <v-dialog v-model="showID_Input" width="420">
                            <v-row
                                wrap
                                no-gutters
                                align="center"
                                justify="center"
                            >
                                <v-col xs="12" sm="12" md="12" lg="12" xl="12">
                                    <v-card
                                        color="white"
                                        flat
                                        pa-1
                                        ma-1
                                        class="mx-auto"
                                        style="max-width: 500px"
                                    >
                                        <v-system-bar
                                            color="blue darken-1"
                                            window
                                            dark
                                        >
                                            ENTER YOUR ZETTAKAL ID
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                x-small
                                                depressed
                                                color="transparent"
                                                @click="hideShowID_Input"
                                            >
                                                <v-icon color="white">
                                                    mdi-close-circle
                                                </v-icon>
                                            </v-btn>
                                        </v-system-bar>
                                        <span class="vSelect-text">
                                            <!-- <span class="vSelect-text">
                                                ENTER YOUR ZETTAKAL ID
                                            </span> -->
                                            <v-text-field
                                                v-model="loginDM_ID"
                                                :rules="[
                                                    rules.required,
                                                    rules.email,
                                                ]"
                                                prepend-icon="person"
                                                name="Zettakal ID"
                                                label="Zettakal ID"
                                                type="text"
                                            >
                                            </v-text-field>
                                        </span>
                                        <v-card-actions>
                                            <!-- <v-btn 
                                                dark 
                                                color="red accent-4"
                                                @click = "hideShowID_Input"
                                            >
                                                CANCEL
                                            </v-btn> -->
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                dark
                                                color="deep-purple darken-4"
                                                depressed
                                                @click="getLoginMessages"
                                            >
                                                GET MESSAGES
                                            </v-btn>
                                        </v-card-actions>
                                        <v-system-bar
                                            color="blue darken-1"
                                            window
                                        >
                                        </v-system-bar>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-dialog>

                        <v-dialog v-model="showLoginInbox" width="330">
                            <v-card pa-1 ma-1>
                                <v-system-bar color="blue darken-1">
                                    <v-icon color="white"> fa-inbox </v-icon>
                                    <span class="card-subtitle">
                                        INBOX MESSAGES FROM HELP DESK
                                    </span>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        x-small
                                        depressed
                                        color="transparent"
                                        @click="hideShowLoginInbox"
                                    >
                                        <v-icon color="white">
                                            mdi-close-circle
                                        </v-icon>
                                    </v-btn>
                                </v-system-bar>

                                <v-data-table
                                    :headers="inboxMessagesHeaders"
                                    :items="inboxMessagesArrayDisplay"
                                    item-key="lidm_vid"
                                    items-per-page="5"
                                    show-expand
                                    no-data-text="No messages, please check Zettakal ID spelling"
                                    single-expand
                                    expanded.sync
                                    single-select
                                    :item-class="row_classes"
                                    mobile-breakpoint="200"
                                    :footer-props="{
                                        prevIcon: 'arrow_left',
                                        nextIcon: 'arrow_right',
                                        itemsPerPageText: 'Rows',
                                    }"
                                    @item-expanded="updateStatusToRead"
                                    @click:row="expandedInbox"
                                >
                                    <template #item.date="{ item }">
                                        <span>
                                            <!-- <span  :class="item.read_status ? 'message-text-read' : 'message-text-unread' "> -->
                                            {{ item.date }}
                                        </span>
                                    </template>
                                    <template #item.staffName="{ item }">
                                        <span>
                                            <!-- <span  :class="item.read_status ? 'message-text-read' : 'message-text-unread' "> -->
                                            {{ item.staffName }}
                                        </span>
                                    </template>

                                    <template
                                        #item.data-table-expand="{
                                            expand,
                                            isExpanded,
                                        }"
                                    >
                                        <!-- <v-icon color="red" @click="expand(!isExpanded)">check</v-icon> -->
                                        <!-- <v-icon color="red" @click="expand(!isExpanded)">expand_circle_down</v-icon> -->
                                        <v-icon
                                            color="red"
                                            @click="expand(!isExpanded)"
                                            >add_box</v-icon
                                        >
                                    </template>

                                    <template
                                        #expanded-item="{ headers, item }"
                                    >
                                        <td
                                            :colspan="headers.length"
                                            class="inbox-message-container"
                                        >
                                            {{ item.content }}
                                            &nbsp;
                                            <v-chip
                                                color="deep-purple darken-4"
                                                dark
                                                x-small
                                                @click="invokeReplyButton"
                                            >
                                                REPLY
                                            </v-chip>
                                        </td>
                                    </template>
                                </v-data-table>
                                <v-system-bar color="blue darken-1">
                                    <v-icon color="white"> fa-inbox </v-icon>
                                    <span class="card-subtitle">
                                        MESSAGES SENT TO HELP DESK
                                    </span>
                                </v-system-bar>
                                <v-data-table
                                    :headers="sentMessagesHeaders"
                                    :items="sentMessagesArrayDisplay"
                                    item-key="lidm_vid"
                                    items-per-page="5"
                                    show-expand
                                    no-data-text="No messages, please check Zettakal ID spelling"
                                    single-expand
                                    expanded.sync
                                    single-select
                                    :item-class="row_classes"
                                    mobile-breakpoint="200"
                                    :footer-props="{
                                        prevIcon: 'arrow_left',
                                        nextIcon: 'arrow_right',
                                        itemsPerPageText: 'Rows',
                                    }"
                                    @item-expanded="updateStatusToRead"
                                    @click:row="expandedInbox"
                                >
                                    <template #item.date="{ item }">
                                        <span>
                                            <!-- <span  :class="item.read_status ? 'message-text-read' : 'message-text-unread' "> -->
                                            {{ item.date }}
                                        </span>
                                    </template>
                                    <template #item.staffName="{ item }">
                                        <span>
                                            <!-- <span  :class="item.read_status ? 'message-text-read' : 'message-text-unread' "> -->
                                            {{ item.staffName }}
                                        </span>
                                    </template>

                                    <template
                                        #item.data-table-expand="{
                                            expand,
                                            isExpanded,
                                        }"
                                    >
                                        <!-- <v-icon color="red" @click="expand(!isExpanded)">check</v-icon> -->
                                        <!-- <v-icon color="red" @click="expand(!isExpanded)">expand_circle_down</v-icon> -->
                                        <v-icon
                                            color="red"
                                            @click="expand(!isExpanded)"
                                            >add_box</v-icon
                                        >
                                    </template>

                                    <template
                                        #expanded-item="{ headers, item }"
                                    >
                                        <td
                                            :colspan="headers.length"
                                            class="inbox-message-container"
                                        >
                                            {{ item.content }}
                                            &nbsp;
                                            <v-chip
                                                color="deep-purple darken-4"
                                                dark
                                                x-small
                                                @click="invokeReplyButton"
                                            >
                                                REPLY
                                            </v-chip>
                                        </td>
                                    </template>
                                </v-data-table>
                                <v-system-bar color="blue darken-1">
                                </v-system-bar>
                            </v-card>
                        </v-dialog>

                        <v-dialog v-model="showDMconfirm" width="300">
                            <v-card
                                justify-space-between
                                align-center
                                pa-2
                                ma-2
                            >
                                <v-toolbar
                                    dark
                                    color="blue darken-1"
                                    justify-end
                                    align-center
                                    pa-2
                                    ma-2
                                >
                                    <v-spacer></v-spacer>
                                    CONFIRMATION
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        x-small
                                        depressed
                                        color="transparent"
                                        @click="closeDMconfirm"
                                    >
                                        <v-icon color="white">
                                            mdi-close-circle
                                        </v-icon>
                                    </v-btn>
                                </v-toolbar>
                                <v-card-subtitle class="vStepper-card-subtitle">
                                    <br />
                                    Your Message To The Help Desk
                                    <br />
                                    Has Been Sent On
                                    <br />

                                    <span class="vDialog-text">
                                        {{ DMconfirmation }}
                                    </span>

                                    <br />
                                    A Staff Member Will Reply
                                    <br />
                                    Within 24 Hours
                                </v-card-subtitle>
                            </v-card>
                        </v-dialog>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
import api from '@/api'
import { mapState, mapActions, mapGetters } from 'vuex'
import { DateTime } from 'luxon'

export default {
    name: 'ViewLogin',
    data() {
        return {
            email: '',
            password: '',

            show: false,
            loading: false,
            disabled: false,

            invalid: false,

            inboxMessagesArray: [],
            sentMessagesArray: [],

            tempEmail: 'i7744@ms101.me',
            tempPassword: 'Lain5246!',
            fakeLoginSuccess: false,

            showLoginDM_create: false,
            showLoginDM_confirm: false,
            showLoginForgotID: false,
            showTempZettakalID_confirm: false,
            showLoginInbox: false,
            showID_Input: false,

            serverError: false,

            selectedStaff: '101',
            memberInputZettalID: '',

            loginDM_content: '',
            loginDM_topic: 'login problem',
            loginDM_confirmation: {},
            loginDM_ID: '',
            getLIDMInbox97Success: false,

            tempZettakal_ID_1ARR: [
                'a',
                'c',
                'd',
                'e',
                'f',
                'g',
                'j',
                'k',
                'p',
                'q',
                'r',
                's',
                't',
                'v',
                'x',
                'y',
            ],
            tempZettakal_ID_2ARR: [
                'b',
                'd',
                'f',
                'g',
                'h',
                'j',
                'k',
                'm',
                'p',
                's',
                't',
                'u',
                'v',
                'w',
                'x',
                'y',
            ],
            tempZettakal_ID_MonthARR: [
                'j',
                'f',
                'r',
                'a',
                'm',
                'n',
                'y',
                't',
                's',
                'c',
                'v',
                'd',
            ],

            rules: {
                email: (value) =>
                    // /^([A-Za-z0-9]+([_.-][A-Za-z0-9]+)?)+@ms101\.me$/.test(
                    /^([A-Za-z0-9]+([_.-][A-Za-z0-9]+)?)+@zettakal\.com$|@ms101\.me$/.test(
                        value
                    ) || 'Invalid email format, all lower case required',
                required: (value) => !!value || 'Required',
            },
            inboxMessagesHeaders: [
                /*    {   text: 'Status',   
                                                                                    align: 'left', 
                                                                                    sortable: false,  
                                                                                    value: 'DM_Status',
                                                                                    width: '5%',
                //                                                                 }, */
                // {   text: 'Open',
                //     align: 'left',
                //     value: 'data-table-expand',
                //     width: '15%',
                // },
                {
                    text: 'Open',
                    value: 'data-table-expand',
                    width: '20%',
                },

                {
                    text: 'Date',
                    align: 'left',
                    sortable: false,
                    value: 'date',
                    width: '30%',
                },
                /*     {   text: '',
                                                                                            align: 'left', 
                                                                                            value: 'data-table-expand',
                                                                                            width: '5%',
                                                                                        }, */
                // {
                //     text: 'From',
                //     align: 'left',
                //     sortable: true,
                //     value: 'staffName',
                //     width: '25%',
                // },
                {
                    text: 'Topic',
                    align: 'left',
                    sortable: false,
                    value: 'topic',
                    width: '50%',
                },
                // {
                //     text: 'Message',
                //     align: 'left',
                //     sortable: false,
                //     value: 'content',
                //     width: '70%',
                // },
                // {   text: 'Open',
                //     align: 'left',
                //     value: 'data-table-expand',
                //     width: '15%',
                // },
                // { text: 'SuperCat', align: 'left', sortable: true,  value: 'superCat'},
                // { text: 'Cat',      align: 'left', sortable: true,  value: 'cat'},
                //  {
                // text: 'Message',
                // align: 'left',
                // sortable: true,
                // value: 'DM_Content',
                // width: '45%',
                // },
                // { text: 'Action',   align: 'left', sortable: true,  value: 'action'},
            ],

            sentMessagesHeaders: [
                {
                    text: 'Open',
                    value: 'data-table-expand',
                    width: '20%',
                },

                {
                    text: 'Date',
                    align: 'left',
                    sortable: false,
                    value: 'date',
                    width: '30%',
                },

                {
                    text: 'Topic',
                    align: 'left',
                    sortable: false,
                    value: 'topic',
                    width: '50%',
                },
            ],
        }
    },

    computed: {
        ...mapState('home', {
            validAccessToken: (state) => state.validAccessToken,
        }),

        ...mapGetters('calendar', [
            'getterTodaysDateUTC',
            'getterTodaysDateTimeLocalISO',
        ]),

        tempZettakalIDMonth() {
            let month =
                parseInt(this.getterTodaysDateTimeLocalISO.substring(5, 7)) - 1
            return this.tempZettakal_ID_MonthARR[month]
        },

        tempZettakalID() {
            let tempZettakal_ID_1ARRLength = this.tempZettakal_ID_1ARR.length
            // let tempZettakal_ID_2ARRLength = (this.tempZettakal_ID_2ARR).length;
            // let tz_IDM1Random = (Math.floor(Math.random()) * ((tempZettakal_ID_1ARRLength - 1) - 1)) + 1;
            let tz_IDM1Random = Math.floor(
                Math.random() * tempZettakal_ID_1ARRLength
            )
            // let tz_IDM1Letter = (this.tempZettakal_ID_1ARR[tz_IDM1Random]).toUpperCase();
            let tz_IDM1Letter = this.tempZettakal_ID_1ARR[tz_IDM1Random]
            // let tz_IDM2 = (Math.floor(Math.random()) * ((tempZettakal_ID_2ARRLength -1) -1) ) + 1;
            // let tz_IDM3 = this.tempZettakalIDMonth;
            // let tz_IDM4 = (this.getterTodaysDateTimeLocalISO).substring(17, 18);
            // let tz_IDM5 = (this.getterTodaysDateTimeLocalISO).substring(18, 19);
            let tz_IDM6 = this.getterTodaysDateTimeLocalISO.substring(20, 23)
            // return tz_IDM1 + tz_IDM2 + tz_IDM3 + tz_IDM4 + tz_IDM5 + "@ms101.me"
            return tz_IDM1Letter + tz_IDM6 + '@ms101.me'
        },

        confirmDateTimeDisplay() {
            let dt = DateTime.fromISO(
                this.loginDM_confirmation.transaction_date_local
            )
            dt = dt.toLocaleString(DateTime.DATETIME_FULL)
            return dt
        },

        paramsGetLIDM_byMember() {
            return {
                member_vID: this.loginDM_ID.toLowerCase(),
            }
        },

        paramsPOST_LoginLIDM_MemberToStaff() {
            return {
                lidm_vid:
                    this.getterTodaysDateTimeLocalISO +
                    '-' +
                    this.memberInputZettalID +
                    '-' +
                    this.selectedStaff,
                sender_member_vid: this.memberInputZettalID.toLowerCase(),
                recipient_staff_vid: this.selectedStaff,
                lidmtopic: this.loginDM_topic,
                lidmcontent: this.loginDM_content,
                transaction_date_local: this.getterTodaysDateTimeLocalISO,
                transaction_date_utc: this.getterTodaysDateUTC,
                read_status: 'false',
                lidmflag: 'TBD',
            }
        },
        inboxMessagesArrayDisplay() {
            return this.inboxMessagesArray.map((item) => {
                const shortDate1 = DateTime.fromISO(
                    item.transaction_date_local
                ).toFormat('MMM-dd-yy')
                const color =
                    item.read_status == 'true'
                        ? 'message-row-read'
                        : 'message-row-unread'
                // let color = JSON.parse(item.read_status)

                const container = {}
                container.lidm_vid = item.lidm_vid
                container.read_status = item.read_status
                container.topic = item.topic
                container.content = item.content
                container.leap_id = item.leap_id
                container.flag = item.flag
                container.staffName = item.staff_fname + ' ' + item.staff_lname
                container.date = shortDate1
                container.color = color
                return container
            })
        },

        sentMessagesArrayDisplay() {
            return this.sentMessagesArray.map((item) => {
                const shortDate1 = DateTime.fromISO(
                    item.transaction_date_local
                ).toFormat('MMM-dd-yy')
                const color =
                    item.read_status == 'true'
                        ? 'message-row-read'
                        : 'message-row-unread'
                // let color = JSON.parse(item.read_status)

                const container = {}
                container.lidm_vid = item.lidm_vid
                container.read_status = item.read_status
                container.topic = item.topic
                container.content = item.content
                container.leap_id = item.leap_id
                container.flag = item.flag
                container.staffName = item.staff_fname + ' ' + item.staff_lname
                container.date = shortDate1
                container.color = color
                return container
            })
        },
    },

    methods: {
        ...mapActions('home', [
            'actionSetUserFromSB',
            'actionSetTGMemberProfile',
            'actionSetTGMemberProfileItems15',
            // 'actionSetMemberDrugDisplayFromTG',
        ]),
        validate() {
            return this.$refs.form.validate()
        },

        openShowLoginDM_create() {
            this.fakeIDLogin()
            this.showLoginDM_create = true
        },

        hideShowLoginDM_create() {
            this.showLoginDM_create = false
        },

        toggleShowLoginForgotID() {
            this.showLoginForgotID = !this.showLoginForgotID
        },

        toggleShowLoginDM_confirm() {
            this.showLoginDM_confirm = !this.showLoginDM_confirm
        },
        openShowTempZettakalID_confirm() {
            this.showTempZettakalID_confirm = true
        },

        hideTempZettakalID_confirm() {
            this.showTempZettakalID_confirm = false
            this.setTempZettakalID()
        },

        openShowID_Input() {
            this.fakeIDLogin()
            this.showID_Input = true
        },

        hideShowID_Input() {
            this.showID_Input = false
        },

        openShowLoginInbox() {
            this.showLoginInbox = true
        },

        hideShowLoginInbox() {
            this.showLoginInbox = false
        },

        invokeReplyButton() {
            this.hideShowLoginInbox()
            this.openShowLoginDM_create()
        },

        sendLoginDMtoHelpDesk() {
            this.fakeIDLogin()
            this.postLoginDM_MemberToStaff98()
        },

        getLoginMessages() {
            // try {
            //     this.loginTempID()
            // }catch {
            //     alert('server error')
            // };
            if (this.fakeLoginSuccess == true) {
                try {
                    this.getLIDMInbox97()
                } catch {
                    alert('alert there is an error')
                }
                // if (this.getLIDMInbox97Success == true) {
                //     this.hideShowID_Input();
                //     this.openShowLoginInbox();
                // }
            } else {
                alert(' ERROR')
            }
        },

        setTempZettakalID() {
            this.memberInputZettalID = this.tempZettakalID
            this.toggleShowLoginForgotID()
        },

        async loginSB() {
                                                                                    // console.log('$$$$$$$$$  Step #1 CLIENT LOGIN.vue loginSB')
            this.invalid = false
            if (this.validate()) {
                this.loading = true
                try {
                    const res = await api.loginSBAuthentication(
                        this.email,
                        this.password
                    )
                                                                                    // console.log('$$$$$$$$$$ STEP# 6A BACK IN CLIENT LOGIN.vue loginSB res.status from supabase and NODE SERVER is below')
                                                                                    // console.log(res.status)
                    if (res.status === 200) {
                        const {
                            data: {
                                // sbStatus,
                                sbUserObject,
                                sbAccessToken,
                                // sbRefreshToken,
                            },
                        } = res
                                                                                    // console.log('$$$$ STEP# 6B BACK IN CLIENT LOGIN.vue loginSB res.sbStatus is below')
                                                                                    // console.log(sbStatus)

                                                                                    // console.log('$$$$ STEP# 6C BACK IN CLIENT LOGIN.vue loginSB sbAccessToken is below')
                                                                                    // console.log(sbAccessToken)

                                                                                    // console.log('$$$$ STEP# 6D BACK IN CLIENT LOGIN.vue loginSB sbUserObject is below')
                                                                                    // console.log(sbUserObject)

                                                                                    // console.log('$$$$ STEP# 6E BACK IN CLIENT LOGIN.vue loginSB sbRefreshToken is below')
                                                                                    // console.log(sbRefreshToken)
                        localStorage.setItem('accessToken', sbAccessToken)
                                                                                    // console.log('$$$$ STEP# 6F BACK IN CLIENT LOGIN.vue loginSB before actionSetUserFromSB')
                        await this.actionSetUserFromSB(sbUserObject)
                                                                                    // console.log('$$$$ STEP# 8 BACK IN CLIENT LOGIN.vue loginSB after actionSetUserFromSB')
                        await this.actionSetTGMemberProfile()
                                                                                    // console.log('$$$$ STEP# 15 BACK IN CLIENT LOGIN.vue loginSB after actionSetMemberProfileFromTG before redirect to ViewSymptoms')
                        await this.actionSetTGMemberProfileItems15()
                                                                                    // console.log('$$$$ STEP# 16 BACK IN CLIENT LOGIN.vue loginSB after  before redirect to ViewSymptoms')
                                                                                    // if (this.$route.meta && this.$route.meta.redirect) {
                                                                                    // await this.actionSetMemberDrugDisplayFromTG()
                                                                                    // console.log('$$$$ STEP# 17 BACK IN CLIENT LOGIN.vue loginSB after actionSetMemberProfileDrugDisplayFromTG before redirect to ViewSymptoms')
                                                                                    // if (this.$route.meta && this.$route.meta.redirect) {
                        if (this.validAccessToken) {
                            this.$router.push(this.$route.meta.redirectAuth)
                        } else {
                            this.$router.push({ name: 'Login' })
                        }
                    } else {
                        this.loading = false
                        this.serverError = true
                    }
                } catch (err) {
                    // this.invalid = true
                    this.loading = false
                    this.serverError = true
                }
            } else {
                this.serverError = true
            }
        },

        async postLoginDM_MemberToStaff98() {
            if (!this.memberInputZettalID) {
                alert(
                    'Please enter your Zettakal ID or a temporary Zettakal ID'
                )
            } else {
                try {
                    this.loginTempID()
                } catch (err) {
                    // this.invalid = true
                    this.loading = false
                    // this.serverError = true
                }

                const params = this.paramsPOST_LoginLIDM_MemberToStaff
                // console.log('STEP #1 OUTSIDE TRY from ViewLogin postLoginDM_MemberToStaff98   params is below')
                // console.log(params)
                try {
                    // console.log('STEP #2 INSIDE TRY ViewLogin postLoginDM_MemberToStaff98')
                    const response98 = await api.postLIDM_MemberToStaff98(
                        params
                    )

                    if (response98.status == 200) {
                        // console.log('postLoginDM_MemberToStaff98 response98.data is below')
                        // console.log(response98.data)
                        this.loginDM_confirmation = response98.data[0]
                        // this.postDM_MemberToStaff_DateTime = response24['data']['results'][5]
                        // console.log ('this.postDMconfirmation["data"]["results"][5] is below')
                        // console.log (Object.values(this.postDM_MemberToStaff_ConfirmationDateTime))

                        // console.log('postDMdateTime should be transactionDateUTC is below')
                        // console.log(this.postDMdateTime.transactionDateUTC)
                        // else {alert('Your message could not be sent please try again later')}
                        //  if (response24['data']['error'] == false) {
                        this.showLoginDM_create = false
                        this.showLoginDM_confirm = true
                    }
                } catch (err) {
                    console.log('ERROR returns to ViewHelpDesk after error')
                    console.log(err)
                }
            }
        },

        async getLIDMInbox97() {
            if (!this.loginDM_ID) {
                alert(
                    'Please enter your Zettakal ID or a temporary Zettakal ID'
                )
            } else {
                // try {
                //     this.loginTempID()

                // } catch (err) {
                //     // this.invalid = true
                //     this.loading = false
                //     this.serverError = true
                // }

                try {
                    const params = this.paramsGetLIDM_byMember
                    // console.log('STEP #1A OUTSIDE TRY from CLIENTSIDE ViewHelpDesk  getLIDMInbox97  params is below')
                    // console.log(params)
                    // console.log('STEP #1B INSIDE TRY from CLIENTSIDE ViewHelpDesk   getLIDMInbox97')
                    const RESP97 = await api.getLogin_Inbox97(params)
                    // console.log('STEP #1C INSIDE TRY from CLIENTSIDE ViewHelpDesk   getLIDMInbox97  RESP97 below')
                    // console.log(RESP97.status)
                    // console.log('RESP97.data is below')
                    // console.log(RESP97.data)
                    let LIDMsARR = RESP97.data
                    // console.log('LIDMsARR is below')
                    // console.log(LIDMsARR)
                    // if (response22.data) {
                    // this.showDialog_Messages = true
                    // }

                    this.sentMessagesArray = LIDMsARR.filter(
                        (item) => item.flag != 'REPLY'
                    )

                    this.sentMessagesArray = this.sentMessagesArray.sort(
                        (a, b) =>
                            a.transaction_date_utc < b.transaction_date_utc
                                ? 1
                                : -1
                    )

                    this.inboxMessagesArray = LIDMsARR.filter(
                        (item) => item.flag == 'REPLY'
                    )

                    this.inboxMessagesArray = this.inboxMessagesArray.sort(
                        (a, b) =>
                            a.transaction_date_utc < b.transaction_date_utc
                                ? 1
                                : -1
                    )

                    this.getLIDMInbox97Success = true
                    if (this.getLIDMInbox97Success == true) {
                        this.hideShowID_Input()
                        this.openShowLoginInbox()
                    }
                } catch (err) {
                    const status = err.RESP97 ? err.RESP97.status : 999
                    console.log('ERROR back to ViewHelpDesk')
                    return status
                }
            }
        },

        async fakeIDLogin() {
            // console.log('STEP #6A')
            // console.log(res.status)
            try {
                const RES98 = await api.loginSBAuthentication(
                    this.tempEmail,
                    this.tempPassword
                )
                // console.log('STEP #6B')
                // console.log(res.status)
                if (RES98.status === 200) {
                    const {
                        data: {
                            // sbStatus,
                            sbUserObject,
                            sbAccessToken,
                            // sbRefreshToken,
                        },
                    } = RES98
                    // console.log('STEP #6C')
                    // console.log(sbStatus)

                    // console.log('STEP #6D sbAccessToken is below')
                    // console.log(sbAccessToken)

                    // console.log('STEP #6E sbUserObject is below')
                    // console.log(sbUserObject)

                    // console.log('STEP# 6F sbRefreshToken is below')
                    // console.log(sbAccessToken)
                    localStorage.setItem('accessToken', sbAccessToken)
                    // console.log('$$$$ STEP# 6F BACK IN CLIENT LOGIN.vue loginSB before actionSetUserFromSB')
                    await this.actionSetUserFromSB(sbUserObject)

                    this.fakeLoginSuccess = true
                } else {
                    alert('There was a server error')
                }
                // console.log('STEP #1 OUTSIDE TRY from ViewLogin postLoginDM_MemberToStaff98   params is below')
                // console.log(params)
            } catch (err) {
                console.log('ERROR returns to ViewHelpDesk after error')
                console.log(err)
            }
        },
    },
}
</script>

<style lang="scss">
.login-card {
    background-color: #ffa726;
}
.login-container {
    // background-color: #311b92;
    // background-color: #1E88E5;
    // background-color: #212121;
    // background-color: #757575;
    background-color: #d1c4e9;
}
.helpbutton {
    color: #757575;
    text-decoration: '';
    font-style: '';
    font-weight: 'bolder';
    font-size: 1em;
}

.card-subtitle {
    color: #ffffff;
    text-decoration: '';
    font-style: '';
    font-weight: '';
    font-size: 1em;
}

.inbox-message-container {
    color: #d50000;
    text-decoration: '';
    font-style: '';
    font-weight: 'bolder';
    font-size: 1em;
}
</style>
