<template>
    <v-container class="calendar-group" fluid column ma-2 pa-2>
        <v-row
            cols="12"
            sm="10"
            offset-sm="1"
            md="8"
            offset-md="2"
            justify="space-around"
            :class="{ calendars: true, 'mx-2': isLargeDisplay }"
        >
            <calendar-component-health-problems
                v-for="(date, idx) of dates"
                v-show="displayCalendarsHideOrShowArray[idx]"
                :key="idx"
                :month="date.getMonth()"
                :year="date.getFullYear()"
                :values="valuesObject1"
                :appt-dots-object="apptDotsObject"
                :color-map="colorMap"
                @dialogappt="showDialog"
            >
            </calendar-component-health-problems>
        </v-row>
        <v-btn v-if="showAppointment" fab x-small color="pink">
            {{ QQQ }}
        </v-btn>
    </v-container>
</template>

<script>
import CalendarComponentHealthProblems from './CalendarComponentHealthProblems.vue'

export default {
    name: 'CalendarGrpHealthProblemsHealthProblems',
    components: {
        CalendarComponentHealthProblems,
    },
    props: {
        getterTodaysMonth: { type: Number, required: true },
        getterTodaysYear: { type: Number, required: true },
        count: { type: Number, required: true },
        apptDotsObject: { type: Object, required: true },
        valuesObject1: { type: Object, required: true },
        colorMap: { type: Object, required: true },
        isLargeDisplay: { type: Boolean, required: true },
        dates: { type: Object, required: true },
        displayCalendarsHideOrShowArray: { type: Object, required: true },
        timeFrameStartMonthLuxonObject: { type: Object, required: true },
    },

    data() {
        return {
            showAppointment: false,
        }
    },

    computed: {
        // the "dates" function below controls the display of the months for the calendars
        // the function returns an array of javascript dates eg 6 for the example below
        // each array element contains just 1 JS date object set for the 1st day of the month
        // the number of array elements is equal to this.count which is passed from the parent component
        // the [0] element is the oldest month in the array and is set to the 1st day of the month eg DEC 01 2020
        // the last array element is the newest month in the array and is set to the 1st day of the CURRENT month eg MAY 01 2021
        // e.g. [DEC 01 2020] [JAN 01 2021] [FEB 02021] [MAR 01 2021] [APR 01 2021] [MAY 01 2021]
    },

    methods: {
        showDialog(MA) {
            this.QQQ = MA
            this.showAppointment = true
        },
    },
}
</script>

<style lang="scss">
.calendar-group {
    margin: 0px;
    padding: 8px;
    background-color: #d1c4e9;
}
</style>
