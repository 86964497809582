<template>
    <div class="text-center">
        <v-dialog v-model="dialogMedApptListShow" width="340">
            <v-card color="white">
                <v-system-bar color="blue darken-1">
                    <!-- <v-toolbar-title class= "text--white"> -->
                    <v-spacer></v-spacer>
                    <span class="white--text"> TODAY'S REMINDERS </span>
                    <v-spacer></v-spacer>

                    <v-btn
                        depressed
                        x-small
                        color="transparent"
                        @click="dialogMedApptListHIDE"
                    >
                        <v-icon color="white"> mdi-close-circle </v-icon>
                    </v-btn>
                    <!-- </v-toolbar-title> -->
                </v-system-bar>
                <div v-if="apptArrayToday.length > 0">
                    <v-card-title class="reminderHeader"> Today </v-card-title>
                    <v-card-subtitle class="reminderSubheader">
                        Appointments - &nbsp;{{ todaysDateDisplay }}
                    </v-card-subtitle>
                    <v-list dense>
                        <v-list-item-group
                            v-model="selectedAppointment"
                        >
                            <v-list-item
                                v-for="appointment in apptArrayToday"
                                :key="appointment.Med_Appt_vID"
                               
                            >
                                <v-chip
                                    class="ma-1"
                                    color="deep-purple darken-4"
                                    text-color="white"
                                    @click="pushToViewMedicalAppointments"
                                >
                                    <v-icon left>
                                        fa-calendar-day
                                    </v-icon>
                                    <v-card-subtitle>
                                    <!-- <v-card-subtitle class="reminder"> -->
                                        &nbsp;
                                        {{ appointment.ApptTIME_SIMPLE }}
                                        {{ appointment.HCPFirstName }}
                                        {{ appointment.HCPLastName }}
                                        <v-spacer></v-spacer>
                                    </v-card-subtitle>
                                </v-chip>
                                                                                    <!-- <v-list-item-icon> 
                                                                                         </v-list-item-icon>
                                                                                     <v-spacer> </v-spacer>
                                                                                     <v-list-item-icon> 
                                                                                     <v-btn
                                                                                        x-small
                                                                                        icon
                                                                                        depressed
                                                                                        width="14px"
                                                                                        min-width="14px"
                                                                                    >
                                                                                        <v-icon dense color="blue">
                                                                                            fa-file
                                                                                        </v-icon>
                                                                                    </v-btn>
                                                                                    <v-icon 
                                                                                        x-small 
                                                                                        color="transparent"
                                                                                        @click="dialogMedApptListHIDE"
                                                                                    >
                                                                                        fa-circle
                                                                                    </v-icon> -->
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </div>
                <div v-if="apptArrayTomorrow.length > 0">
                    <v-card-title class="reminderHeader">
                        Tomorrow
                    </v-card-title>
                    <v-card-subtitle class="reminderSubheader">
                        Appointments - &nbsp;{{ tomorrowsDateDisplay }}
                    </v-card-subtitle>
                    <v-list dense>
                        <v-list-item-group
                            v-model="selectedAppointment"
                        >
                            <v-list-item
                                v-for="appt in apptArrayTomorrow"
                                :key="appt.Med_Appt_vID"
                            >
                            <v-chip
                                    class="ma-1"
                                    color="blue darken-1"
                                    text-color="white"
                                    @click="pushToViewMedicalAppointments"
                                >
                                    <v-icon left>
                                        fa-calendar-day
                                    </v-icon>
                                <v-card-subtitle>
                                    &nbsp;
                                    {{ appt.ApptTIME_SIMPLE }}
                                    {{ appt.HCPFirstName }}
                                    {{ appt.HCPLastName }}
                                    <v-spacer></v-spacer>
                                </v-card-subtitle>
                            </v-chip>
                                                                                    <!-- <v-list-item-icon> -->
                                                                                    <!-- </v-list-item-icon> -->
                                                                                    <!-- <v-spacer> </v-spacer> -->
                                                                                    <!-- <v-list-item-icon>  -->
                                                                                    <!--         <v-btn
                                                                                        x-small
                                                                                        icon
                                                                                        depressed
                                                                                        width="14px"
                                                                                        min-width="14px"
                                                                                    >
                                                                                        <v-icon dense color="blue">
                                                                                            fa-file
                                                                                        </v-icon>
                                                                                    </v-btn> -->
                                                                                    <!--  <v-icon 
                                                                                        x-small 
                                                                                        color="transparent"
                                                                                        @click="dialogMedApptListHIDE"
                                                                                    >
                                                                                        fa-circle
                                                                                    </v-icon> -->
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </div>
                <v-system-bar color="blue darken-1">
                    <v-spacer></v-spacer>
                    <v-chip
                        x-small
                        depressed
                        dark
                        color="deep-purple darken-4"
                        @click="dialogMedApptListHIDE"
                    >
                        CLOSE TODAY'S REMINDER
                    </v-chip>
                </v-system-bar>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { DateTime } from 'luxon'
const tone7 = require('../assets/tone7.mp3')
const tone7OBJ = new Audio(tone7)

export default {
    name: 'DialogMedApptList',

    components: {},

    props: {},

    data() {
        return {
            dialogMedApptListShow: false,
            selectedAppointment: {},
        }
    },

    computed: {
        ...mapState('calendar', {
            todaysDateLuxonObject: (state) => state.todaysDateLuxonObject,
        }),
        ...mapState('home', {
            dialogMedApptListSessionTriggered: (state) =>
                state.dialogMedApptListSessionTriggered,
        }),
        ...mapState('appoint', {
            apptArray: (state) => state.apptArray,
            apptDotsObject: (state) => state.apptDotsObject,
        }),
        ...mapGetters('calendar', [
            'getterTodaysDateDisplayISO',
            'getterTodaysDateDisplayHUGE',
        ]),

        dateString() {
            return this.date.toLocaleDateString('en-US', {
                month: 'long',
                year: 'numeric',
            })
        },

        todaysDateISODateOnly() {
            return this.todaysDateLuxonObject.toISODate()
        },
        todaysDateDisplay() {
            let d67 = this.todaysDateLuxonObject
            d67 = d67.toFormat('EEEE, LLLL d')
            // console.log('d67 is below')
            // console.log(d67)
            return d67
        },

        tomorrowsDateDisplay() {
            let d55Luxon = this.todaysDateLuxonObject
            // console.log('d55Luxon is below')
            // console.log (d55Luxon)
            let d56Luxon = d55Luxon.plus({ days: 1 })
            // console.log('d56Luxon is below')
            // console.log(d56Luxon)
            let d57 = d56Luxon.toFormat('EEEE, LLLL d')
            // console.log('d57 is below')
            // console.log(d57)
            return d57
        },

        appointmentArray() {
            const appointmentArray2 = this.apptArray.map((item) => {
                let temporaryOBJ = {}

                let ApptDateLuxonObject = DateTime.fromISO(
                    item.ApptDateISOLocal
                )
                let AppDateISODateOnly = ApptDateLuxonObject.toISODate()

                let DaysDiff = this.calculateDifferenceDays(
                    AppDateISODateOnly,
                    this.todaysDateISODateOnly
                )

                temporaryOBJ.AppDateLuxonObject = ApptDateLuxonObject
                temporaryOBJ.AppDateISODateOnly = AppDateISODateOnly
                temporaryOBJ.Med_Appt_vID = item.Med_Appt_vID
                temporaryOBJ.ApptDateISOLocal = item.ApptDateISOLocal
                temporaryOBJ.ApptDateLocal = item.ApptDateLocal
                temporaryOBJ.ApptDateUTC = item.ApptDateUTC
                temporaryOBJ.ApptMilliseconds = item.ApptMilliseconds
                temporaryOBJ.ApptTIME_SIMPLE = this.convertISOLocalToTimeOnly(
                    item.ApptDateISOLocal
                )
                temporaryOBJ.ApptStatus = item.ApptStatus
                temporaryOBJ.ApptType = item.ApptType
                temporaryOBJ.HCPAddress1 = item.HCPAddress1
                temporaryOBJ.HCPCity = item.HCPCity
                temporaryOBJ.HCPFirstName = item.HCPFirstName
                temporaryOBJ.HCPLastName = item.HCPLastName
                temporaryOBJ.HCPState = item.HCPState
                temporaryOBJ.DaysDiff = DaysDiff

                return temporaryOBJ
            })

            return appointmentArray2
        },

        apptArrayToday() {
            let appointmentArray3 = this.appointmentArray.filter(
                (element) => element.DaysDiff == 0
            )
            return appointmentArray3
        },

        apptArrayTomorrow() {
            let appointmentArray4 = this.appointmentArray.filter(
                (element) => element.DaysDiff == 1
            )
            return appointmentArray4
        },
    },

    mounted() {
        this.reminderTrigger()
    },

    methods: {
        ...mapActions('home', ['actionSetDialogMedApptListSessionTriggered']),

        calculateDifferenceDays(ISODateOnly1, ISODateOnly2) {
            let luxonObject1 = DateTime.fromISO(ISODateOnly1)
            let luxonObject2 = DateTime.fromISO(ISODateOnly2)
            let deltaDayObject = luxonObject1
                .diff(luxonObject2, 'days')
                .toObject()
            return deltaDayObject.days
        },

        convertISOLocalToTimeOnly(ApptDateISOLocal) {
            const t99 = DateTime.fromISO(ApptDateISOLocal)
            return t99.toLocaleString(DateTime.TIME_SIMPLE)
        },

        pushToViewMedicalAppointments() {
            this.$router.push({ name: 'ViewMedicalAppointments' })
        },

        dialogMedApptListHIDE() {
            this.actionSetDialogMedApptListSessionTriggered()
            this.dialogMedApptListShow = false
        },

        reminderTrigger() {
            if (this.dialogMedApptListSessionTriggered === false) {
                // let timeoutID = setTimeout(this.evaluateLengthApptArrayTodayTomorrow, 2000)
                setTimeout(this.evaluateLengthApptArrayTodayTomorrow, 1500)
            }
            {
                // console.log (this.dialogMedApptListSessionTriggered )
            }
            // console.log ('timeoutID = ' + timeoutID )
        },

        evaluateLengthApptArrayTodayTomorrow() {
            // console.log('apptArrayToday length = ' + this.apptArrayToday.length)
            // console.log('apptArrayTomorrow length = ' + this.apptArrayTomorrow.length)
            if (
                this.apptArrayToday.length > 0 ||
                this.apptArrayTomorrow.length > 0
            ) {
                this.dialogMedApptListShow = true
                this.playTone()
            }
        },
        playTone() {
            tone7OBJ.play()
        },
    },
}
</script>

<style lang="scss" scoped>
.reminderHeader {
    color: rgb(0, 0, 0);
    text-decoration: '';
    font-style: '';
    font-weight: bold;
    font-size: 0.7 em;
    text-transform: uppercase;
}
.reminderSubheader {
    color: rgb(0, 0, 0);
    text-decoration: '';
    font-style: '';
    font-weight: '';
    font-size: 0.7 em;
    text-transform: uppercase;
}
.reminder {
    // color: rgb(0, 0, 0);
    color: #1e88e5;
    text-decoration: '';
    font-style: '';
    font-weight: '';
    font-size: 0.7 em;
    text-transform: uppercase;
}
</style>
